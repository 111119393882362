<template>
  <v-app class="radio-with-dropdown">
    <v-select
      class="dropdown box--shadow--none"
      :items="dropdownItems"
      :label="dropdownPlaceholder"
      :disabled="disabled"
      v-model="dropdownValue"
      solo
      dense
      outlined
      @change="getDropdown"
    ></v-select>
    <div class="d-flex flex-nowrap">
      <v-checkbox
        class="checkbox"
        v-model="checkbox"
        :label="items[0]"
        value="No"
        @click="getCheckboxData"
      ></v-checkbox>
    </div>
  </v-app>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    defaultValue: {
      type: String,
      default: "",
    },
    dropdownItems: {
      type: Array,
      required: true,
    },
    dropdownPlaceholder: {
      type: String,
      default: "",
    },
    dropdownItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dropdownValue: "",
      checkbox: null,
      disabled: false,
    };
  },
  methods: {
    getDropdown() {
      this.$emit("on-change", { data: this.dropdownValue, flag: "dropdown" });
    },
    getCheckboxData() {
      if (this.checkbox === "No") {
        this.dropdownValue = "";
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.$emit("on-change", { data: this.checkbox, flag: "checkbox" });
    },
  },
  created() {
    this.checkbox = this.defaultValue === "Yes" ? "" : this.defaultValue;
    if (this.checkbox === "No") {
      this.disabled = true;
      this.dropdownValue = "";
    }
    this.dropdownValue = this.dropdownItem;
    //console.log(this.dropdownItems);
    this.dropdownItems.splice(0, 1);
    //console.log(this.dropdownItems);
  },
};
</script>

<style lang="scss" scoped>
.radio-with-dropdown {
  width: 65%;
}
.dropdown {
  width: 50%;
  margin-right: 12px;
  max-width: 200px;
}
.checkbox {
  margin-top: 2px !important;
  padding-top: 4px !important;
}
</style>
