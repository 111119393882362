<template>
  <div>
    <Loader v-if="isLoading" />
    <div
      class="header-container d-flex justify-space-between align-center"
      :class="$store.state.sideNavBar ? 'pl--20r' : 'pl--0'"
    >
      <div class="header-left">
        <span class="nav-control cursor--pointer">
          <img
            class="header-sidenavbar-icon"
            src="../assets/images/sideNavControl_icon.svg"
            alt=""
            @click="toggleSideNav()"
          />
          <v-icon
            class="header-dots-vertical"
            aria-hidden="false"
            @click="openBelowNav"
          >
            mdi-dots-vertical
          </v-icon>
        </span>
      </div>

      <!-- right side of header -->
      <div class="header-right d-flex align-center">
        <v-icon @click="refresh()" class="header-refresh" aria-hidden="false">
          mdi-refresh-circle
        </v-icon>
        <v-icon class="header-notification ml--6" aria-hidden="false">
          mdi-bell-outline
        </v-icon>
        <a class="header-user" href="#">
          <span class="d-flex">
            <div class="user-name ml--6">{{ $store.state.currentUser.firstName + " " + $store.state.currentUser.lastName }}</div>
            <div class="ml--6">
              <v-icon class="user-profile-image" aria-hidden="false">
                mdi-account
              </v-icon>
            </div>
            <div class="user-image ml--6" @click="openAlert = true">
              <v-icon class="user-profile-image" aria-hidden="false">
                mdi-logout-variant
              </v-icon>
            </div>
          </span>
        </a>
      </div>

      <!-- right side of header that appears below in smaller screen -->
      <div v-if="belowNav" class="header-below d-flex align-center">
        <v-icon @click="refresh()" class="header-refresh" aria-hidden="false">
          mdi-refresh-circle
        </v-icon>
        <v-icon class="header-notification ml--6" aria-hidden="false">
          mdi-bell-outline
        </v-icon>
        <a class="header-user" href="#">
          <span class="d-flex">
            <div class="user-name ml--6">{{ $store.state.currentUser.firstName + " " + $store.state.currentUser.lastName }}</div>
            <div class="user-image ml--6">
              <v-icon class="user-profile-image" aria-hidden="false">
                mdi-account
              </v-icon>
            </div>
          </span>
        </a>
      </div>
    </div>
    <custom-alert
      v-show="openAlert"
      headerText="Alert !!"
      status="warning"
      statusText="Are you sure you want to logout?"
      :showCancelButton="true"
      @confirm="logout()"
      @close="openAlert = false"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import CustomAlert from "@/components/CustomAlert.vue";
export default {
  components: {
    Loader,
    CustomAlert,
  },
  data() {
    return {
      sideNav: true,
      belowNav: false,
      isLoading: false,
      openAlert: false,
    };
  },

  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },

  methods: {
    openBelowNav() {
      this.sideNav = false;
      this.belowNav = !this.belowNav;
    },
    //resize event handler method
    resizeHandler() {
      if (window.innerWidth <= 1024) this.sideNav = false;
      else {
        if (!this.$route.fullPath.includes("pap-form-fax")) this.sideNav = true;
        this.belowNav = false;
      }
    },
    refresh() {
      //no data to refresh
    },
    logout() {
      this.$store.commit("updateLogoutFlag");
      let data = {
        workEmail: JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase(),
        isLoggedIn: false,
      };
      this.isLoading = true;
      this.axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/User/setIsCurrentlyLoggedIn`,
          data
        )
        .then(() => {
          sessionStorage.clear();
          window.location.reload();
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
      this.openAlert = false;
    },
    toggleSideNav() {
      if (!this.$route.fullPath.includes("pap-form-fax")) {
        if (this.sideNav !== this.$store.state.sideNavBar) {
          this.sideNav = !this.$store.state.sideNavBar;
          this.$store.commit("sideNavToggle", this.sideNav);
          if (this.sideNav) this.belowNav = false;
        } else {
          this.sideNav = !this.sideNav;
        }
      }
    },
  },

  watch: {
    sideNav() {
      this.$store.commit("sideNavToggle", this.sideNav);
      if (this.sideNav) this.belowNav = false;
    },
  },
};
</script>

<style scoped>
.header-left {
  z-index: 5;
}
.header-container {
  background-color: #0057ed;
  padding: 12px;
  z-index: 10;
  position: fixed;
  width: 100%;
}
.header-user {
  color: #fff;
  display: inline-block;
  text-decoration: none;
}
.header-logo {
  margin: 0 35px;
}
.header-sidenavbar-icon {
  margin-left: 10px;
}
.header-refresh {
  color: #fff;
}
.header-notification {
  color: #fff;
}
.user-image {
  margin-right: 20px;
}
.user-profile-image {
  color: #fff;
}
.header-dots-vertical {
  display: none;
}
.header-below {
  display: none;
  margin-left: auto;
  margin-right: 0;
  margin-top: 12px;
}

.pl--20r {
  padding-left: 15.5rem;
}
.pl--0 {
  padding-left: 0.7rem !important;
}

@media screen and (max-width: 1024px) {
  .header-container {
    flex-direction: column;
  }
  .header-dots-vertical {
    display: block;
    color: #fff;
  }
  .header-left {
    width: 100%;
  }
  .nav-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-right {
    display: none !important;
  }
  .header-below {
    display: block;
  }
}
</style>
