import Vue from "vue";
import VueRouter from "vue-router";
import PapForm from "../views/papForm.vue";
import PatientList from "../views/enrollments.vue";
import FollowUp from "../views/followUp.vue";
import LoginPage from "../views/loginPage.vue";
import PreEnrollmentList from "../views/preEnrollmentList.vue";
import unextractedPreEnrollmentList from "../views/unextractedPreEnrollmentList.vue";
import Logs from "../views/logs.vue";
import PapFormFax from "../views/papFormFax.vue";
import store from "../store";
import dashBoard from "../views/dashBoard";
import EnrollmentLetter from "../views/enrollmentLetter";
import Users from "../views/Users.vue";
import PageNotFound from "../views/PageNotFound ";

Vue.use(VueRouter);

function guardMyroute(to, from, next) {
  if (sessionStorage.getItem("accessToken")) {
    if(to.path == "/404") store.dispatch("loggedIn", false);
    else store.dispatch("loggedIn", true);
    next();
  } else {
    store.dispatch("loggedIn", false);
    next("/");
  }
}
function isLoggedIn(to, from, next) {
  if (sessionStorage.getItem("accessToken")) {
    store.dispatch("loggedIn", true);
    next("/dashboard");
  } else {
    store.dispatch("loggedIn", false);
    next();
  }
}
const routes = [
  {
    path: "/",
    component: LoginPage,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/dashboard",
    component: dashBoard,
    meta: { sideNavIndex: 0 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pap-form",
    component: PapForm,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pap-form/:stepperId",
    component: PapForm,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pap-form/:stepperId/:id",
    component: PapForm,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pap-form-fax",
    component: PapFormFax,
    meta: { sideNavIndex: 1 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pap-form-fax/:stepperId",
    component: PapFormFax,
    meta: { sideNavIndex: 1 },
    props: (route) => ({ faxId: route.query.fax_id }),
    beforeEnter: guardMyroute,
  },
  {
    path: "/pap-form-fax/:stepperId/:id",
    component: PapFormFax,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/enrollments",
    component: PatientList,
    name: "patientList",
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/follow-up",
    component: FollowUp,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/follow-up/:id",
    component: FollowUp,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pre-enrollment-list",
    component: PreEnrollmentList,
    meta: { sideNavIndex: 1, subSideNavIndex: 0 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/pre-enrollment-list/unextracted",
    component: unextractedPreEnrollmentList,
    meta: { sideNavIndex: 1, subSideNavIndex: 1 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/enrollment-letter",
    component: EnrollmentLetter,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/enrollment-letter/:id",
    component: EnrollmentLetter,
    meta: { sideNavIndex: 2 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/logs",
    component: Logs,
    meta: { sideNavIndex: 3, subSideNavIndex: 0 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/users",
    component: Users,
    meta: { sideNavIndex: 3, subSideNavIndex: 1 },
    beforeEnter: guardMyroute,
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
    beforeEnter: guardMyroute,
  },
  { path: "*", redirect: "/404", beforeEnter: guardMyroute },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
