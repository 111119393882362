<template>
  <v-row justify="center">
    <loader v-if="isLoading" />
    <v-dialog v-model="dialogBox" persistent max-width="600">
      <v-card>
        <v-toolbar flat class="elevation-0">
          <v-toolbar-title>
            <h3>{{ title }} User</h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items flat>
            <v-icon @click="closePopup">mdi-close</v-icon>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="field--container">
          <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-row>
            <v-col>
              <div class="mb--1">
                <h4>User <span class="red--bold">*</span></h4>
              </div>
              <v-autocomplete
                v-model="selectedUser"
                :items="allUsers"
                item-text="name"
                return-object
                dense
                outlined
                :disabled = "title == 'Edit'"
                :menu-props="{ bottom: true, offsetY: true }"
                :hide-details="auto"
                :rules="rules"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div>
                <h4>Email Address</h4>
              </div>
              <v-text-field
                v-model="selectedUser.email"
                outlined
                disabled
                dense
                :hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <div class="mb--1">
                <h4>Role <span class="red--bold">*</span></h4>
                <v-select
                  multiple
                  v-model="selectedUser.userRoles"
                  :items="roles"
                  item-text="name"
                  return-object
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :hide-details="auto"
                  :rules="requiredRules"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          </v-form>
        </div>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center pa--4">
          <v-btn
            class="ma-2 btn--css btn--color--white"
            outlined
            x-large
            shaped
            depressed
            @click="closePopup"
            >Cancel</v-btn
          >
          <v-btn
            class="ma-2 btn--css btn--color--blue"
            outlined
            x-large
            shaped
            depressed
            @click="addEditPopup"
            >Save</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      isLoading: false,
      dialogBox: true,
      isFormValid: false,
      auto: "",
      allUsers: [],
      selectedUser: {},
      roles: [],
      rules: [ (v) => !!Object.keys(v).length || "This field is required"],
      requiredRules: [
      (v) => !!v.length || "This field is required",
      ],
      form: {
        users: {},
        email: "",
        userRoles: [],
      },
      allUserData: {},
    };
  },
  props: {
    title: { type: String, default: "" },
    userData: { type: Object, default: {} },
  },
  methods: {
    getData() {
      this.isLoading = true;
      let res1;
      if(this.title == "Add") {
        res1 = this.axios.get(
          `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/manage-users`
        );
      }

      let res2 = this.axios.get(
        `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-roles`
      );

      Promise.all([res1, res2]).then((responses) => {
        if(this.title == "Add"){
          for (const key in responses[0].data.data) {
            if (responses[0].data.data.hasOwnProperty(key)) {
              this.allUsers.push(responses[0].data.data[key]);
              this.allUsers[this.allUsers.length - 1].email = key;
            }
          }
        }
        this.roles = responses[1].data.data;
        if(this.title == "Edit"){
          this.selectedUser.userRoles = [];
          this.userData.roles.forEach((item) => {
            this.roles.forEach((data) => {
              if(data._id.$oid == item._id.$oid){
                this.selectedUser.userRoles.push(data);
                return;
              }
            })
          }); 
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        console.log(err);
      });
    },
    closePopup() {
      this.$emit("close", false);
    },
    addEditPopup() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let payload = {};
        payload.role = this.selectedUser.userRoles.map((i) => {
          return i._id.$oid;
        });
        let response;
        if(this.title == "Edit"){
          let params = { workEmail: this.selectedUser.email };
          response = this.axios
          .put(
            `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/manage-users`,
            payload, { params }
          )
        } else if (this.title == "Add") {
          payload.name = this.selectedUser.name;
          payload.workEmail = this.selectedUser.email;
          response = this.axios
            .post(
              `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/manage-users`,
              payload
            ) 
        }
        Promise.all([response]).then((response) => {
              this.$emit("close", false);
              this.isLoading = false;
              this.$emit("userAddedEdit");
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });        
      }
    },
  },
  created() {
    this.getData();
    if(this.title == "Edit"){
      let data = {};
      data.name = this.userData.firstName + " " + this.userData.lastName;
      data.email = this.userData.workEmail;
      this.allUsers.push(data);
      this.selectedUser = data;
    }
  },
};
</script>

<style scoped>
.red--bold {
  color: red;
}

.btn--css {
  text-transform: capitalize;
  width: 8rem !important;
  height: 2.5rem !important;
}
.field--container{
  padding-right: 4px;
  padding-left: 4px;
}

/* .v-dialog__content {
  justify-content: flex-end;
  align-items: flex-end;
} */
</style>
