<template>
  <div class="papform">
    <loader v-if="isLoading" />
    <stepper
      v-if="loadingCount === 0"
      :stepperArr="stepperArr"
      :stepperActiveIndex="stepperActiveIndex"
      :statusCode="statusCode"
      :missingFlag="missingFlag"
      v-on:changeActiveIndex="updateStepperActiveIndex($event)"
      v-on:clickMissingInfo="missingPopupDisplay = true"
    />

    <v-form v-if="loadingCount === 0" v-model="isFormValid" ref="form">
      <v-card class="card--spacing form--card">
        <div
          class="d-flex justify-space-between align-center mb--3"
          v-if="
            stepperArr[stepperActiveIndex] &&
            stepperArr[stepperActiveIndex].name
          "
        >
          <span class="papFormStepName barlow-bold">
            {{ stepperArr[stepperActiveIndex].name }}
          </span>
          <span class="asterix-required-field">*Required fields</span>
        </div>
        <div v-for="(item, i) in papForm" :key="i">
          <v-row v-show="stepperActiveIndex === i">
            <!-- Left hand side of papForm -->
            <v-col
              :sm="breakpoint"
              :class="{ 'pb--0': breakpoint === 12 }"
              v-if="stepperArr[i] && stepperArr[i].leftColFields"
              class="left-col"
            >
              <v-row
                class="pb--3 flex-nowrap align-center"
                v-for="index in range(0, stepperArr[i].leftColFields)"
                :key="index"
              >
                <h3 class="mb--3" v-if="item[index].type === 'heading'">
                  {{ item[index].label }}
                </h3>
                <div v-else class="field-label pr--3">
                  <span>
                    {{ item[index].label }}
                    <span
                      class="asterix-required-field"
                      v-if="item[index].required"
                      >*</span
                    >
                  </span>
                </div>
                <InputField
                  v-if="
                    item[index].type === 'text' ||
                    item[index].type === 'phone' ||
                    item[index].type === 'date' ||
                    item[index].type === 'partialPassword'
                  "
                  class="input-field"
                  :placeholder="item[index].placeholder"
                  :reGex="item[index].reGex"
                  :errorMsg="item[index].error"
                  :type="item[index].type"
                  :defaultValue="item[index].value"
                  :disabled="item[index].disabled ? true : false || !item[index].isAccess"
                  :maxlength="item[index].maxlength"
                  :hide-details="'auto'"
                  @on-input="onInput($event, i, item[index].fieldIndex)"
                />
                <DropDown
                  v-if="item[index].type === 'dropdown'"
                  class="input-field box--shadow--none"
                  :items="
                    item[index].key === 'state' ? states : item[index].items
                  "
                  :item="item[index].value"
                  :disabled="item[index].disabled ? true : false || !item[index].isAccess"
                  :placeholder="item[index].placeholder"
                  :hide-details="'auto'"
                  @on-change="onInput($event, i, item[index].fieldIndex)"
                />
                <Checkbox
                  v-if="item[index].type === 'radio'"
                  :items="item[index].items"
                  :defaultValue="item[index].value"
                  :disabled="item[index].disabled ? true : false || !item[index].isAccess"
                  :hide-details="'auto'"
                  @on-change="onInput($event, i, item[index].fieldIndex)"
                />
                <CheckboxWithDropdown
                  v-if="item[index].type === 'radioWithDropdown'"
                  :items="item[index].items"
                  :defaultValue="item[index].value"
                  :dropdownItem="item[index].state"
                  :dropdownPlaceholder="item[index].dropdownPlaceholder"
                  @on-change="onInput($event, i, item[index].fieldIndex)"
                />
              </v-row>
            </v-col>

            <!-- Right hand side of papForm-->
            <v-col
              :sm="breakpoint"
              :class="{ 'pt--0': breakpoint === 12 }"
              v-if="stepperArr[i] && stepperArr[i].rightColFields"
              class="right-col"
            >
              <v-row
                class="pb--3 flex-nowrap align-center"
                v-for="index in range(
                  stepperArr[i].leftColFields,
                  stepperArr[i].leftColFields + stepperArr[i].rightColFields
                )"
                :key="index"
              >
                <h3 class="mb--3" v-if="item[index].type === 'heading'">
                  {{ item[index].label }}
                </h3>
                <div
                  class="field-label pr--3"
                  v-if="item[index].type !== 'heading'"
                >
                  <span>
                    {{ item[index].label }}
                    <span
                      class="asterix-required-field"
                      v-if="item[index].required"
                      >*</span
                    >
                  </span>
                </div>
                <InputField
                  v-if="
                    item[index].type === 'text' ||
                    item[index].type === 'phone' ||
                    item[index].type === 'date' ||
                    item[index].type === 'partialPassword'
                  "
                  class="input-field"
                  :placeholder="item[index].placeholder"
                  :reGex="item[index].reGex"
                  :errorMsg="item[index].error"
                  :type="item[index].type"
                  :defaultValue="item[index].value"
                  :disabled="item[index].disabled ? true : false || !item[index].isAccess"
                  :maxlength="item[index].maxlength"
                  :hide-details="'auto'"
                  @on-input="onInput($event, i, item[index].fieldIndex)"
                />
                <DropDown
                  v-if="item[index].type === 'dropdown'"
                  class="input-field box--shadow--none"
                  :items="
                    item[index].key === 'state' ? states : item[index].items
                  "
                  :item="item[index].value"
                  :placeholder="item[index].placeholder"
                  :disabled="item[index].disabled ? true : false || !item[index].isAccess"
                  :hide-details="'auto'"
                  @on-change="onInput($event, i, item[index].fieldIndex)"
                />
                <Checkbox
                  v-if="item[index].type === 'radio'"
                  :items="item[index].items"
                  :defaultValue="item[index].value"
                  :disabled="item[index].disabled ? true : false || !item[index].isAccess"
                  :hide-details="'auto'"
                  @on-change="onInput($event, i, item[index].fieldIndex)"
                />
                <CheckboxWithDropdown
                  v-if="item[index].type === 'radioWithDropdown'"
                  :items="states"
                  :defaultValue="item[index].value"
                  :dropdownItems="item[index].dropdownItems"
                  :dropdownItem="item[index].state"
                  :dropdownPlaceholder="item[index].dropdownPlaceholder"
                  @on-change="onInput($event, i, item[index].fieldIndex)"
                />
              </v-row>
            </v-col>
            <v-col
              v-if="
                stepperArr[stepperActiveIndex].id === 'prescriptionAndDiagnosis'
              "
              :sm="12"
              class="pl--0"
            >
              <div class="asterix-required-field">
                Note: "Enter Allergies and Concurrent medications in your
                pharmacy system".
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- Additional card for prescriptionAndDiagnosis -->
      <v-card
        v-show="
          stepperArr[stepperActiveIndex] &&
          stepperArr[stepperActiveIndex].subLeftColFields > 0
        "
        class="card--spacing form--card"
      >
        <v-row>
          <!-- Left hand side of papForm -->
          <v-col
            :sm="breakpoint"
            :class="{ 'pb--0': breakpoint === 12 }"
            v-if="
              stepperArr[stepperActiveIndex] &&
              stepperArr[stepperActiveIndex].subLeftColFields >= 0
            "
            class="left-col"
          >
            <v-row
              class="pb--3 flex-nowrap align-center"
              v-for="index in range(
                stepperArr[stepperActiveIndex].leftColFields +
                  stepperArr[stepperActiveIndex].rightColFields,
                stepperArr[stepperActiveIndex].leftColFields +
                  stepperArr[stepperActiveIndex].rightColFields +
                  stepperArr[stepperActiveIndex].subLeftColFields
              )"
              :key="index - 1"
            >
              <h3
                class="mb--3"
                v-if="papForm[stepperActiveIndex][index].type === 'heading'"
              >
                {{ papForm[stepperActiveIndex][index].label }}
              </h3>
              <div v-else class="field-label pr--3">
                <span>
                  {{ papForm[stepperActiveIndex][index].label }}
                  <span
                    class="asterix-required-field"
                    v-if="papForm[stepperActiveIndex][index].required"
                    >*</span
                  >
                </span>
              </div>
              <InputField
                v-if="
                  papForm[stepperActiveIndex][index].type === 'text' ||
                  papForm[stepperActiveIndex][index].type === 'phone' ||
                  papForm[stepperActiveIndex][index].type === 'date' ||
                  papForm[stepperActiveIndex][index].type === 'partialPassword'
                "
                class="input-field"
                :placeholder="papForm[stepperActiveIndex][index].placeholder"
                :reGex="papForm[stepperActiveIndex][index].reGex"
                :errorMsg="papForm[stepperActiveIndex][index].error"
                :type="papForm[stepperActiveIndex][index].type"
                :defaultValue="papForm[stepperActiveIndex][index].value"
                :disabled="
                  papForm[stepperActiveIndex][index].disabled ? true : false || !papForm[stepperActiveIndex][index].isAccess
                "
                :maxlength="papForm[stepperActiveIndex][index].maxlength"
                :hide-details="'auto'"
                @on-input="
                  onInput(
                    $event,
                    stepperActiveIndex,
                    papForm[stepperActiveIndex][index].fieldIndex
                  )
                "
              />
              <DropDown
                v-if="papForm[stepperActiveIndex][index].type === 'dropdown'"
                class="input-field box--shadow--none"
                :items="
                  papForm[stepperActiveIndex][index].key === 'state'
                    ? states
                    : papForm[stepperActiveIndex][index].items
                "
                :item="papForm[stepperActiveIndex][index].value"
                :disabled="
                  papForm[stepperActiveIndex][index].disabled ? true : false || !papForm[stepperActiveIndex][index].isAccess
                "
                :placeholder="papForm[stepperActiveIndex][index].placeholder"
                :hide-details="'auto'"
                @on-change="
                  onInput(
                    $event,
                    stepperActiveIndex,
                    papForm[stepperActiveIndex][index].fieldIndex
                  )
                "
              />
            </v-row>
          </v-col>

          <!-- Right hand side of papForm-->
          <v-col
            :sm="breakpoint"
            :class="{ 'pt--0': breakpoint === 12 }"
            v-if="
              stepperArr[stepperActiveIndex] &&
              stepperArr[stepperActiveIndex].rightColFields >= 0
            "
            class="right-col"
          >
            <v-row
              class="pb--3 flex-nowrap align-center"
              v-for="index in range(
                stepperArr[stepperActiveIndex].leftColFields +
                  stepperArr[stepperActiveIndex].rightColFields +
                  stepperArr[stepperActiveIndex].subLeftColFields,
                stepperArr[stepperActiveIndex].leftColFields +
                  stepperArr[stepperActiveIndex].rightColFields +
                  stepperArr[stepperActiveIndex].subLeftColFields +
                  stepperArr[stepperActiveIndex].subRightColFields
              )"
              :key="index - 1"
            >
              <h3
                class="mb--3"
                v-if="papForm[stepperActiveIndex][index].type === 'heading'"
              >
                {{ papForm[stepperActiveIndex][index].label }}
              </h3>
              <div v-else class="field-label pr--3">
                <span>
                  {{ papForm[stepperActiveIndex][index].label }}
                  <span
                    class="asterix-required-field"
                    v-if="papForm[stepperActiveIndex][index].required"
                    >*</span
                  >
                </span>
              </div>
              <InputField
                v-if="
                  papForm[stepperActiveIndex][index].type === 'text' ||
                  papForm[stepperActiveIndex][index].type === 'phone' ||
                  papForm[stepperActiveIndex][index].type === 'date' ||
                  papForm[stepperActiveIndex][index].type === 'partialPassword'
                "
                class="input-field"
                :placeholder="papForm[stepperActiveIndex][index].placeholder"
                :reGex="papForm[stepperActiveIndex][index].reGex"
                :errorMsg="papForm[stepperActiveIndex][index].error"
                :type="papForm[stepperActiveIndex][index].type"
                :defaultValue="papForm[stepperActiveIndex][index].value"
                :disabled="
                  papForm[stepperActiveIndex][index].disabled ? true : false || !papForm[stepperActiveIndex][index].isAccess
                "
                :maxlength="papForm[stepperActiveIndex][index].maxlength"
                :hide-details="'auto'"
                @on-input="
                  onInput(
                    $event,
                    stepperActiveIndex,
                    papForm[stepperActiveIndex][index].fieldIndex
                  )
                "
              />
              <Checkbox
                v-if="papForm[stepperActiveIndex][index].type === 'radio'"
                :items="papForm[stepperActiveIndex][index].items"
                :defaultValue="papForm[stepperActiveIndex][index].value"
                :disabled="
                  papForm[stepperActiveIndex][index].disabled ? true : false || !papForm[stepperActiveIndex][index].isAccess
                "
                :hide-details="'auto'"
                @on-change="
                  onInput(
                    $event,
                    stepperActiveIndex,
                    papForm[stepperActiveIndex][index].fieldIndex
                  )
                "
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl--0">
            <v-btn
              depressed
              class="btn--color--blue cursor--pointer"
              @click="onFileChange"
              @close="closeUploadPopup"
              >Upload Documents <v-icon right dark> mdi-upload </v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" xl="6" sm="6" class="pl--0">
            <data-table
              class="data-table"
              :headers="headers"
              :tableData="tableData"
              :key="render"
            >
              <template slot="columns" slot-scope="props">
                <td>
                  {{ props.index + 1 }}
                </td>
                <td>
                  {{ props.item.fileName }}
                </td>
                <td>
                  {{ props.item.fileType }}
                </td>
                <td>
                  <v-icon
                    color="#0057ED"
                    style="font-size: 18px; cursor: pointer !important"
                    @click="getPreview(props.item.fileName)"
                    >mdi-eye</v-icon
                  >
                  <v-icon
                    v-if="$store.state.isOrderEntry"
                    color="#0057ED"
                    style="font-size: 18px; cursor: pointer !important; margin-left: 12px;"
                    @click="deleteDocuments(props)"
                    >mdi-delete</v-icon
                  >
                </td>
              </template>
            </data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <v-card
      v-if="loadingCount === 0"
      class="card--spacing d-flex justify-space-between button-v-card"
    >
      <div>
        <img
          src="../assets/images/previousIcon.svg"
          alt=""
          v-show="stepperActiveIndex > 0"
          class="cursor--pointer"
          @click="priviousStepper()"
        />
      </div>
      <div>
        <v-btn depressed class="btn--color--white ml--3" @click="closeAction">
          Cancel
        </v-btn>
        <v-btn
          v-if="
            stepperArr[stepperActiveIndex] &&
            !stepperArr[stepperActiveIndex].last
          "
          depressed
          class="btn--color--white ml--3"
          @click="saveClose()"
        >
          Save & Close
        </v-btn>
        <v-btn
          v-if="
            stepperArr[stepperActiveIndex] &&
            stepperArr[stepperActiveIndex].last
          "
          depressed
          class="btn--color--blue ml--3"
          @click="saveFormData"
        >
          Submit
        </v-btn>
        <v-btn
          v-else
          depressed
          class="btn--color--blue ml--3"
          @click="nextStepper()"
        >
          Continue
        </v-btn>
      </div>
    </v-card>
    <v-dialog :key="rerender" v-model="dialog" persistent width="auto" @click:outside="closePreview">
      <v-card>
        <div v-if="fileType == 'pdf'" id="pdf-viewer"></div>
        <img v-else :src="imageSrc" alt="">
      </v-card>
    </v-dialog>
    <MissingPopup
      v-if="missingPopupDisplay"
      :missingFieldArr="missingFieldArr"
      :stepperArr="stepperArr"
      v-on:closeAction="missingPopupDisplay = false"
    />
    <upload-popup
      v-if="openUploadPopup"
      :templateData="templateData"
      @close="closeUploadPopup"
      @uploadedDocument="getDocuments"
    />

    <custom-alert
      v-if="openAlert"
      :headerText="alert.headerText"
      :status="alert.status"
      :statusText="alert.statusText"
      :showCancelButton="alert.showCancelButton"
      @confirm="onConfirm(alert.key)"
      @close="onClose(alert.key)"
    />
    <custom-alert
      v-if="openAlertList"
      :headerText="alertList.headerText"
      :alertType="alertList.type"
      :status="alertList.status"
      :statusText="alertList.statusText"
      :listArr="alertList.listArr"
      @confirm="onConfirmList(alertList.key)"
      @close="onCloseList(alertList.key)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Stepper from "../components/Stepper.vue";
import headerNav from "../components/headerNav.vue";
import InputField from "../components/InputField.vue";
import Checkbox from "../components/Checkbox.vue";
import DropDown from "../components/DropDown.vue";
import CheckboxWithDropdown from "../components/CheckboxWithDropdown.vue";
import Loader from "../components/Loader.vue";
import MissingPopup from "@/components/MissingPopup.vue";
import CustomAlert from "../components/CustomAlert.vue";
import DataTable from "../components/DataTable.vue";
import UploadPopup from "@/components/UploadPopup.vue";
export default {
  components: {
    Stepper,
    headerNav,
    InputField,
    Checkbox,
    DropDown,
    CheckboxWithDropdown,
    MissingPopup,
    Loader,
    UploadPopup,
    CustomAlert,
    DataTable,
  },

  data() {
    return {
      dialog: false,
      openUploadPopup: false,
      isLoading: false,
      isFormValid: false,
      rerender: 0,
      programName: "Medvantica",
      programId: "",
      openAlert: false,
      openAlertList: false,
      alert: {},
      alertList: {},
      stepperArr: [],
      stepperActiveIndex: 0,
      papForm: [],
      disabled: false,
      breakpoint: 6,
      filesToUpload: [],
      states: [],
      fileType: "",
      allStates: [],
      enrollmentData: {},
      stepperForm: {},
      saveCloseFlag: false,
      hcpEdit: false,
      papEnrollmentId: "",
      userId: null,
      statusCode: null,
      dirtFormFlag: true,
      missingFlag: false,
      missingPopupDisplay: false,
      missingFieldArr: [],
      isPageReloaded: false,
      loadingCount: 0,
      templateData: [],
      prescriptgrxCall: false,
      imageSrc: "",
      headers: [
        {
          text: "S.No",
          value: "id",
        },
        {
          text: "Form Name",
          value: "formName",
        },
        {
          text: "Form Type",
          value: "formType",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      tableData: [],
      routePath: "",
      keyFlag: false,
      close: false,
      checkChange: false,
      deleteProps: {},
      papLogoutFlag: false,
      render: 0,
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.checkForChanges() && this.$route.params.id && !this.close && !this.$store.state.isRoleUpdated) {
      this.alert = {
        status: "warning",
        statusText: this.getDirtyformText(),
        key: "beforeRouteLeave",
        headerText: "Data will not be saved!",
        showCancelButton: true,
      };
      this.openAlert = true;
      this.routePath = to.path;
      if (this.keyFlag) {
        next();
      }
    } else if (this.$route.params.id && !this.close) {
      this.isLoading = true;
      let data = {
        enrollmentId: this.papEnrollmentId,
        isLockedIn: false,
      };
      this.axios
        .post(`Enrollment/getEnrollmentLockedInStatus`, data)
        .then((res) => {
          this.isLoading = false;
          if (!res.data.error) {
            next();
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    } else if (!this.checkForChanges() && !this.close && !this.$store.state.isRoleUpdated) {
      this.checkChange = true; // this is used for checking the dirty form when params are missing
      this.alert = {
        status: "warning",
        statusText: this.getDirtyformText(),
        key: "beforeRouteLeave",
        headerText: "Data will not be saved!",
        showCancelButton: true,
      };
      this.openAlert = true;
      this.routePath = to.path;
      if (this.keyFlag) {
        next();
      }
    } else {
      next();
    }
  },
  watch: {
    loadingCount(data) {
      if (data != 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },
    configState: {
      deep: true,
      handler() {
        this.getStates();
      },
    },
    currentUser: {
      immediate: true,
      handler() {
        this.userId = this.$store.state.currentUser._id;
      },
    },
    logoutFlag(newValue, oldValue) {
      if (newValue) {
        this.preventNav();
      }
    },
    isAdmin: {
      immediate: true,
      handler() {
        this.gotoPage();
      },
    },
    isAgent: {
      immediate: true,
      handler() {
        this.gotoPage();
      },
    },
    isOrderEntry: {
      immediate: true,
      handler() {
        this.gotoPage();
      },
    },
  },

  methods: {
    closePreview(){
      this.dialog = false;
    },
    getPreview(file){
      var currPage = 1;
      var thePDF = null;
      this.isLoading = true;
      this.fileType = file.split('.').pop();
      this.axios.get(`Enrollment/getDocumentUrl?filename=${file}`).then((res)=> {
        if(this.fileType == "pdf"){
        pdfjsLib.getDocument(res.data.data).then((pdf) => {
        thePDF = pdf;
        pdf.getPage(1).then( handlePages );
        });
        function handlePages(page){
        var viewport = page.getViewport(1.82);
        var canvas = document.createElement("canvas");
        canvas.style.display = "block";
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        let pdfViewer = document.getElementById('pdf-viewer');
        pdfViewer.appendChild( canvas );
        page.render({canvasContext: context, viewport: viewport});
        currPage++;
        if ( thePDF !== null && currPage <= 2 ){
          thePDF.getPage( currPage ).then( handlePages );
        }
      }
        }else this.imageSrc = res.data.data;
      this.rerender++;
      this.dialog = true;    
      setTimeout(()=> this.isLoading = false, 3000);
      });
    },
    getDocuments() {
      let documentFlag = true;
      this.getEnrollmentData(documentFlag);
      this.closeUploadPopup(false);
    },
    deleteDocuments(props) {
      this.deleteProps = props;
      this.alert = {
        status: "warning",
        statusText: "Are you sure you want to delete?",
        showCancelButton: true,
        key: "delete",
      };
      this.openAlert = true;
    },
    getDirtyformText() {
      let text = "";
      if (this.stepperActiveIndex === this.stepperArr.length - 1) {
        text =
          "You have unsaved entries. To save, click Cancel, and then Submit from the form. Click OK to close without saving.";
      } else {
        text =
          "You have unsaved entries. To save, click Cancel, and then Save & Close from the form. Click OK to close without saving.";
      }
      return text;
    },
    onConfirm(key) {
      if (key == "enrollmentSubmitted") this.changeLockInStatus();
      if (key == "saveClose" || key == "papFormLockIn")
        this.$router.push("/enrollments");
      if (key == "eligibilityInfo") {
        if (this.saveCloseFlag) this.changeLockInStatus();
        else this.incrementStepper();
      }
      if (key == "closeAction") {
        this.isPageReloaded = false;
        this.$route.params.id
          ? this.changeLockInStatus()
          : this.$router.push("/enrollments");
        delete sessionStorage.papEnrollmentId;
      }
      if (key == "beforeRouteLeave") {
        if (!this.checkChange) {
          this.isLoading = true;
          let data = {
            enrollmentId: this.papEnrollmentId,
            isLockedIn: false,
          };
          this.axios
            .post(`Enrollment/getEnrollmentLockedInStatus`, data)
            .then((res) => {
              this.isLoading = false;
              if (!res.data.error) {
                this.keyFlag = true;
                this.$router.push(this.routePath);
                delete sessionStorage.papEnrollmentId;
              }
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        } else {
          this.keyFlag = true;
          this.$router.push(this.routePath);
        }
      }
      if (key == "delete") {
        let data = {
          enrollmentId: this.deleteProps.item.enrollmentId,
          fileName: this.deleteProps.item.fileName,
        };
        this.axios
          .delete(`Enrollment/deleteFromS3`, { data: { data } })
          .then((res) => {
            this.tableData.splice(this.deleteProps.index, 1);
            this.render++;
          });
      }
      this.openAlert = false;
    },
    onClose(key) {
      if (key == "papFormLockIn") {
        this.$router.push("/enrollments");
        this.openAlert = false;
      }
      if (key == "enrollmentSubmitted") {
        this.changeLockInStatus();
        this.openAlert = false;
      }
      if (key == "eligibilityInfo") {
        if (this.saveCloseFlag) this.changeLockInStatus();
        else this.incrementStepper();
      }
      if (
        key == "saveClose" ||
        key == "closeAction" ||
        key == "beforeRouteLeave"
      ) {
        this.close = false;
      }
      if (key == "saveClose") this.saveCloseFlag = false;

      this.openAlert = false;
    },
    onConfirmList(key) {
      if (key == "eligibilityInfo") {
        if (this.saveCloseFlag) this.changeLockInStatus();
        else this.incrementStepper();
      }
      this.openAlertList = false;
    },
    onCloseList(key) {
      if (key == "eligibilityInfo") {
        if (this.saveCloseFlag) this.changeLockInStatus();
        else this.incrementStepper();
      }
      this.openAlertList = false;
    },
    preventNav(event) {
      if (!this.close && !this.papLogoutFlag && this.$route.params.id) {
        let data = {
          enrollmentId: this.papEnrollmentId,
          isLockedIn: false,
        };
        this.axios
          .post(`Enrollment/getEnrollmentLockedInStatus`, data)
          .then((response) => {
            //console.log("response:", response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (this.$store.state.logoutFlag) this.papLogoutFlag = true;

      // if (
      //   !this.checkForChanges() &&
      //   !sessionStorage.getItem("sessionExpired")
      // ) {
      //   event.preventDefault();
      //   event.returnValue = "";
      //   return null;
      // }
    },
    closeUploadPopup(val) {
      this.openUploadPopup = val;
    },
    range(startIndex, endIndex) {
      let arr = [];
      for (let i = startIndex; i < endIndex; i++) {
        arr.push(i);
      }
      return arr;
    },

    onInput(val, stepperIndex, fieldIndex) {
      let index = this.papForm[stepperIndex].findIndex(
        (i) => i.fieldIndex == fieldIndex
      );
      if (index > -1) {
        if (val === null) {
          this.papForm[stepperIndex][index].value = "";
        } else {
          this.papForm[stepperIndex][index].value = val;
        }
      }

      if (
        this.stepperArr[stepperIndex].id === "prescriptionAndDiagnosis" &&
        this.papForm[stepperIndex][index].key === "prescription"
      ) {
        this.disableFields(stepperIndex, val);
      }
    },

    // Prefilling is USResident checkbox on basis of patient state
    setUSResident(val) {
      let stepperIndex = this.stepperArr.findIndex(
        (i) => i.id === "eligibilityInformation"
      );
      let index = this.papForm[stepperIndex].findIndex(
        (i) => i.key === "usResident"
      );
      if (this.papForm[stepperIndex][index].value) {
        return;
      }
      if (val === "Others") {
        this.papForm[stepperIndex][index].value = "No";
      } else {
        this.papForm[stepperIndex][index].value = "Yes";
      }
    },

    // Disabling/Enabling fields onInput prescription on file checkbox
    disableFields(stepperIndex, flag) {
      if (!this.prescriptgrxCall) {
        if (flag === "Yes") {
          this.papForm[stepperIndex].forEach((item) => {
            if (
              item.key != "prescription" &&
              item.key != "icdCode" &&
              item.key != "diagnosis"
            ) {
              if (item.key != "productRequested") item.value = "";
              item.disabled = true;
            }
          });
        } else {
          this.papForm[stepperIndex].forEach((item) => {
            if (
              item.key != "prescription" &&
              item.key != "icdCode" &&
              item.key != "diagnosis"
            ) {
              item.disabled = false;
            }
          });
        }
      }
    },

    // OnClick Submit button
    saveFormData() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        this.isPageReloaded = false;
        this.close = true;
        let data = {};
        let endPoint = "";
        [data, endPoint] = this.getPayload();

        // Building payload
        this.createFormData(data.formData, this.stepperActiveIndex);
        this.isLoading = true;
        this.axios
          .post(`Enrollment/${endPoint}`, data)
          .then((res) => {
            this.isLoading = false;
            if (!res.data.error) {
              this.alert = {
                status: "success",
                statusText: "Enrollment is submitted!",
                key: "enrollmentSubmitted",
                headerText: "Success",
              };
              this.openAlert = true;
            } else {
              this.close = false;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.close = false;
            console.log(err);
          });
      }
    },

    // On click next button
    nextStepper() {
      this.$refs.form.validate();
      let patientConditionalReqFlag = false;
      if (
        this.stepperArr[this.stepperActiveIndex].id === "patientInformation" &&
        !this.saveCloseFlag
      ) {
        let flag = this.checkConditionalReq(this.stepperActiveIndex);
        patientConditionalReqFlag = this.requiredPopup(
          flag,
          this.stepperActiveIndex
        );
      }
      if (this.isFormValid && !patientConditionalReqFlag) {
        if (!this.$route.params.id) this.close = true;
        this.isPageReloaded = false;
        this.dirtFormFlag = false;
        let endPoint = "";
        let data = {};
        [data, endPoint] = this.getPayload();

        // Building payload
        this.createFormData(data.formData, this.stepperActiveIndex);
        // Taking state form patient info for eligibility info payload

        if (
          this.stepperArr[this.stepperActiveIndex].id ===
            "patientInformation" &&
          data.formData?.state
        ) {
          this.setUSResident(data.formData.state);
        }
        if (
          this.prescriptgrxCall &&
          (this.stepperArr[this.stepperActiveIndex].id ===
            "prescriptionAndDiagnosis" ||
            this.stepperArr[this.stepperActiveIndex].id ===
              "physicianInformation")
        ) {
          if (this.saveCloseFlag) {
            this.changeLockInStatus();
          } else {
            this.incrementStepper();
          }
        } else {
          this.isLoading = true;
          this.axios
            .post(`Enrollment/${endPoint}`, data)
            .then((res) => {
              this.isLoading = false;
              if (res.data.error) {
                this.dirtFormFlag = true;
                this.close = false;
              } else {
                this.continueStepper(res, data);
              }
            })
            .catch((err) => {
              this.isLoading = false;
              this.close = false;
              console.log(err);
              this.dirtFormFlag = true;
            });
        }
      } else {
        this.saveCloseFlag = false;
        this.close = false;
      }
    },

    continueStepper(res, data) {
      this.statusCode = res.data.data.enrollmentStatus;

      // if (
      //   this.stepperArr[this.stepperActiveIndex].id === "patientInformation"
      // ) {
      //   this.disablePatientDetail();
      // }

      // Checking for prescription grx flag
      if (
        this.stepperArr[this.stepperActiveIndex].id ===
          "prescriptionAndDiagnosis" &&
        res.data.data.enrollment.prescriptgrxCall
      ) {
        this.disablePrescriptionHCP();
        this.prescriptgrxCall = true;
      }
      // Checking and updating missing fields
      if (res.data.data.missingStatus?.missingFields) {
        this.buildMissingArr(
          res.data.data.missingStatus.missingFields,
          this.stepperActiveIndex
        );
      } else {
        this.buildMissingArr({}, this.stepperActiveIndex);
      }
      this.updateMissingFlag();

      if (this.stepperActiveIndex == 0 && !this.$route.params.id) {
        this.papEnrollmentId = res.data.data.enrollment.id;
        this.$router.push(
          `/pap-form/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
        );
        sessionStorage.setItem("papEnrollmentId", this.papEnrollmentId);
      }
      // Check if hcp created
      if (
        this.stepperArr[this.stepperActiveIndex].id ===
          "physicianInformation" &&
        res.data.data.enrollment.physicianInformation.id
      ) {
        this.hcpEdit = true;
      }
      // Check if patient eligibility in eligibilityInformation
      if (
        this.stepperArr[this.stepperActiveIndex].id === "eligibilityInformation"
      ) {
        let text = "";
        let list = "";
        let eligibilityStatus;
        if (res.data.data.eligibilityStatus) {
          [list, text, eligibilityStatus] = this.getPopupInfo(
            res.data.data.eligibilityStatus.eligibilityFields,
            res.data.data.eligibilityStatus.isEligible,
            data.formData.state,
            data.formData.usResident
          );
        }
        if (eligibilityStatus == "notEligible") {
          this.alertList = {
            type: "pre-defined-list",
            listArr: list,
            headerText: "Eligibility Status",
            statusText: text,
            key: "eligibilityInfo",
          };
          this.openAlertList = true;
        } else {
          this.alert = {
            type: "pre-defined-list",
            headerText: "Eligibility Status",
            statusText: text,
            status: eligibilityStatus,
            key: "eligibilityInfo",
          };
          this.openAlert = true;
        }
      } else {
        if (this.saveCloseFlag) {
          this.changeLockInStatus();
        } else {
          this.incrementStepper();
        }
      }
    },

    // To get payload structure
    getPayload() {
      let data = {};
      let endPoint = "";
      if (this.stepperActiveIndex == 0) {
        data = {
          programId: this.programId,
          userId: this.userId,
          userEmail: JSON.parse(sessionStorage.getItem("account")).username,
          source: "Manual",
          formData: {},
        };
        if (this.$route.params.id) data.enrollmentId = this.$route.params.id;
        endPoint = "patientInformation";
      } else if (this.stepperActiveIndex == 1) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        if (this.hcpEdit) data.edit = true;
        endPoint = "physicianInformation";
      } else if (this.stepperActiveIndex == 2) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        endPoint = "prescriptionAndDiagnosis";
      } else if (this.stepperActiveIndex == 3) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        endPoint = "eligibilityInformation";
      } else if (this.stepperActiveIndex == 4) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        endPoint = "insuranceAndSignatures";
      }
      return [data, endPoint];
    },

    createFormData(data, index) {
      if (this.stepperArr[index].id === "insuranceAndSignatures") {
        let heading = "";
        this.papForm[index].forEach((i) => {
          if (i.type === "heading") {
            heading = i.key;
            data[heading] = {};
          } else {
            data[heading][i.key] = i.value;
          }
        });
      } else {
        this.papForm[index].forEach((i) => {
          if (i.type != "heading") {
            data[i.key] = i.value;
          }
        });
      }
      if (
        this.stepperArr[this.stepperActiveIndex].id === "eligibilityInformation"
      ) {
        let stepperIndex = this.stepperArr.findIndex(
          (i) => i.id === "patientInformation"
        );
        let index = this.papForm[stepperIndex].findIndex(
          (i) => i.key === "state"
        );
        let val = this.papForm[stepperIndex][index].value;
        if (val === "Others") {
          data["state"] = "Others";
        } else {
          data["state"] = val;
        }
      }
    },

    checkConditionalReq(index) {
      let hPhone = this.papForm[index].findIndex((i) => i.key === "homePhone");
      let mPhone = this.papForm[index].findIndex(
        (i) => i.key === "mobilePhone"
      );
      let aPhone = this.papForm[index].findIndex(
        (i) => i.key === "advocateContactPhone"
      );
      return (
        !this.papForm[index][hPhone].value &&
        !this.papForm[index][mPhone].value &&
        !this.papForm[index][aPhone].value
      );
    },

    // checkMandatoryInputs(index) {
    //   let fName = this.papForm[index].findIndex((i) => i.key === "firstName");
    //   let lName = this.papForm[index].findIndex((i) => i.key === "lastName");
    //   let dob = this.papForm[index].findIndex((i) => i.key === "dateOfBirth");
    //   let data = this.enrollmentData.patientInformation;
    //   return (
    //     data.firstName === this.papForm[index][fName].value &&
    //     data.lastName === this.papForm[index][lName].value &&
    //     data.dateOfBirth === this.papForm[index][dob].value
    //   );
    // },

    requiredPopup(flag, index) {
      let reqField = [];
      this.papForm[index].forEach((i) => {
        if (i.required && !i.value) {
          reqField.push(i.label);
        }
      });
      if (reqField.length > 0 || flag) {
        var str = "<ul>";
        reqField.forEach((label) => {
          str += "<li>" + label + "</li>";
        });
        if (flag) {
          str += "<li>" + "Home Phone/Mobile Phone/Advocate Phone" + "</li>";
        }
        str += "</ul>";
        this.alertList = {
          type: "list",
          listArr: str,
          headerText: "Missing Fields",
          statusText:
            "You must fill in all the required fields (*) to proceed with the enrollment.",
          status: "warning",
          key: "requiredPopup",
        };
        this.openAlertList = true;
      } else {
        return false;
      }
      return true;
    },

    getPopupInfo(data, status, state, usResident) {
      var list = "<ul>";
      let eligibilityStatus;
      if (this.enrollmentDetails && this.enrollmentDetails.missingSteppers) {
        this.enrollmentDetails.missingSteppers.forEach((item) => {
          list += "<li>" + item + "</li>";
        });
      }
      let text = "";
      if (status === "No") {
        if (data) {
          let patientInsuredIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("patientInsured")) return true;
          });
          let patientUninsuredIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("patientUninsured")) return true;
          });
          let incomeValidationIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("incomeValidation")) return true;
          });
          let ageIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("age")) return true;
          });
          if (
            !data[patientInsuredIndex].patientInsured ||
            !data[patientUninsuredIndex].patientUninsured
          ) {
            list +=
              "<li>" +
              "Can not determine patient insurance information." +
              "</li>";
          } else if (
            data[patientInsuredIndex].status === "No" ||
            data[patientUninsuredIndex].status === "No"
          ) {
            list += "<li>" + "Patient is insured." + "</li>";
          }

          if (!data[incomeValidationIndex].incomeValidation) {
            list += "<li>" + "Income validation failed." + "</li>";
          }
          if (data[ageIndex].status === "No") {
            list += "<li>" + "Patient is under 18 yrs of age." + "</li>";
          }
        } else {
          if (state === "Others" && usResident === "Yes") {
            list +=
              "<li>" +
              "Patient is not a US resident as State for the patient is entered as Others." +
              "</li>";
          } else {
            list += "<li>" + "Patient is not a US resident." + "</li>";
          }
        }
        text = "The patient is not eligible due to the following reasons.";
        eligibilityStatus = "notEligible";
        // list += '<li>'+ "The patient is not eligible due to the following reasons." + '</li>';
      } else if (status === "Yes") {
        text = "Patient is eligible for Enrollment!";
        eligibilityStatus = "success";
        // list += '<li>'+ "Patient is eligible for Enrollment!" + '</li>';
      } else if (status === "Unknown") {
        eligibilityStatus = "warning";
        if (state === "") {
          text =
            "Eligibility could not be verified due to the non-availability of data (State for the patient is not entered)!";
          // list += '<li>'+ "Eligibility could not be verified due to the non-availability of data (State for the patient is not entered)!" + '</li>';
        } else {
          text =
            "Eligibility could not be verified due to the non-availability of data!";
          // list += '<li>'+ "Eligibility could not be verified due to the non-availability of data!" + '</li>';
        }
        list += "</ul>";
      }
      return [list, text, eligibilityStatus];
    },

    // Jumping to next stepper after onClick continue button
    incrementStepper() {
      this.close = false;
      this.stepperActiveIndex += 1;
      this.stepperArr[this.stepperActiveIndex].active = true;
      this.$router.push(
        `/pap-form/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
      );
      this.dirtFormFlag = true;
      // Set stepperForm which will be used to check for unsaved data
      if (this.stepperActiveIndex > this.stepperForm.stepperId) {
        this.setStepperForm();
      }
    },

    // OnClick Save & Close Button
    saveClose() {
      this.isPageReloaded = false;
      this.saveCloseFlag = true;
      this.close = true;
      let fName, lName, dob;
      if (
        this.stepperArr[this.stepperActiveIndex].id === "patientInformation"
      ) {
        fName = this.papForm[this.stepperActiveIndex].findIndex(
          (i) => i.key === "firstName"
        );
        lName = this.papForm[this.stepperActiveIndex].findIndex(
          (i) => i.key === "lastName"
        );
        dob = this.papForm[this.stepperActiveIndex].findIndex(
          (i) => i.key === "dateOfBirth"
        );
      }
      if (
        this.stepperArr[this.stepperActiveIndex].id === "patientInformation" &&
        (!this.papForm[this.stepperActiveIndex][fName].value ||
          !this.papForm[this.stepperActiveIndex][lName].value ||
          !this.papForm[this.stepperActiveIndex][dob].value)
      ) {
        this.alert = {
          status: "warning",
          statusText: "Are you sure you want to close PAP form?",
          key: "saveClose",
          headerText: "First Name, Last Name and DOB are required!",
          showCancelButton: true,
        };
        this.openAlert = true;
      } else {
        // this.papForm[0].forEach((i) => {
        //   if (i.required === true) i.mandatoryFields = false;
        // });
        // console.log(this.papForm);
        this.nextStepper();
      }
    },

    // On click privious button
    priviousStepper() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        this.isPageReloaded = false;
        this.dirtFormFlag = false;
        if (
          this.stepperActiveIndex === this.stepperForm.stepperId &&
          this.checkForChanges()
        ) {
          this.stepperArr[this.stepperActiveIndex].active = false;
          this.stepperActiveIndex -= 1;
          this.setStepperForm();
        } else {
          this.stepperArr[this.stepperActiveIndex].active = false;
          this.stepperActiveIndex -= 1;
        }
        this.$router.push(
          `/pap-form/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
        );
        this.dirtFormFlag = true;
      }
    },

    // Event listner for on click in stepper
    updateStepperActiveIndex(index) {
      //this.stepperActiveIndex = index;
    },

    // Handle GET api call for form fields and stepper, and prefilling papForm
    getPapForm() {
      this.loadingCount++;
      this.axios
        .get(
          `Program/getProgramFields?programId=${sessionStorage.getItem(
            "programId"
          )}`
        )
        .then((res) => {
          if (res.data.error) {
            --this.loadingCount;
          } else {
            this.$store.commit("setForm", {
              papFormTitle: JSON.parse(
                JSON.stringify(res.data.data[0].programImageUrl)
              ),
              papForm: JSON.parse(JSON.stringify(res.data.data[0].papForm)),
              papFormFax: JSON.parse(
                JSON.stringify(res.data.data[0].papFormFax)
              ),
            });
            this.getFields(res.data.data[0].papForm);

            // if (this.$route.params.id) {
            //   if (
            //     this.$route.params.stepperId > this.stepperArr.length ||
            //     this.$route.params.stepperId <= 1
            //   ) {
            //     this.stepperActiveIndex = 0;
            //   } else if (
            //     res.data.data.visited[
            //       this.stepperArr[this.$route.params.stepperId - 1].id
            //     ] === true
            //   ) {
            //     this.stepperActiveIndex = this.$route.params.stepperId - 1;
            //   } else {
            //     for (let i = this.$route.params.stepperId - 1; i > 0; i--) {
            //       if (res.data.data.visited[this.stepperArr[i - 1].id] === true) {
            //         this.stepperActiveIndex = i + 1;
            //       }
            //     }
            //   }
            //   this.$router.push(
            //     `/pap-form/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
            //   );
            // }

            if (Object.keys(this.enrollmentData).length > 0) {
              this.prefillFields();
            } else {
              this.setStepperForm();
            }
            --this.loadingCount;
          }
        })
        .catch((err) => {
          --this.loadingCount;
          console.log(err);
        });
    },

    getFields(programFields) {
      this.stepperArr = programFields.steppers.sort(
        (a, b) => a.stateId - b.stateId
      );

      if (
        this.$route.params.id &&
        (this.$route.params.stepperId > this.stepperArr.length ||
          this.$route.params.stepperId < 1)
      ) {
        this.stepperActiveIndex = 0;
        this.$router.push(
          `/pap-form/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
        );
      }
      for (let i = 0; i <= this.stepperActiveIndex; i++) {
        this.stepperArr[i].active = true;
      }

      this.stepperArr.forEach((i, index) => {
        this.missingFieldArr.push([]);
        this.papForm.push(
          programFields[i.id].sort((a, b) => {
            return a.fieldIndex - b.fieldIndex;
          })
        );
      });
      this.setStepperForm();
    },

    prefillFields() {
      if (
        this.$route.params.id &&
        Object.keys(this.enrollmentData).length > 0
      ) {
        this.tableData = this.enrollmentData.documents
          ? this.enrollmentData.documents
          : [];
          this.render++;
        // if (
        //   this.stepperArr[this.stepperActiveIndex].id === "patientInformation"
        // ) {
        //   this.disablePatientDetail();
        // }
        if (this.enrollmentData.prescriptgrxCall) {
          this.disablePrescriptionHCP();
          this.prescriptgrxCall = true;
        }
        this.statusCode =
          this.enrollmentData.statusCode >= 0
            ? this.enrollmentData.statusCode
            : null;

        if (this.enrollmentData.physicianInformation.id) {
          this.hcpEdit = true;
        }
      }
      this.stepperArr.forEach((i, index) => {
        if (Object.keys(this.enrollmentData).length > 0) {
          if (i.id === "patientInformation") {
            if (this.enrollmentData[i.id]?.state) {
              this.setUSResident(this.enrollmentData[i.id].state);
            }
          }
          let heading;

          // Prefilling data into fields
          this.papForm[index].forEach((field) => {
            if (
              i.id === "insuranceAndSignatures" &&
              Object.keys(this.enrollmentData[i.id]).length > 0
            ) {
              if (field.type === "heading") {
                heading = field.key;
              } else if (
                this.enrollmentData[i.id][heading]?.[field.key] != undefined
              ) {
                field.value = this.enrollmentData[i.id][heading][field.key];
              }
            } else if (this.enrollmentData[i.id]?.[field.key] != undefined) {
              field.value = this.enrollmentData[i.id][field.key];
            }

            if (
              i.id === "prescriptionAndDiagnosis" &&
              field.key === "prescription"
            ) {
              this.disableFields(index, field.value);
            }
          });
        }
      });
      this.setStepperForm();
      if (
        this.papEnrollmentId &&
        this.enrollmentData.missingStatus?.missingFields
      ) {
        this.buildMissingArr(this.enrollmentData.missingStatus.missingFields);
      }
      this.updateMissingFlag();
    },

    disablePrescriptionHCP() {
      let prescriptionIndex = this.stepperArr.findIndex(
        (i) => i.id === "prescriptionAndDiagnosis"
      );
      let hcpIndex = this.stepperArr.findIndex(
        (i) => i.id === "physicianInformation"
      );
      this.papForm[prescriptionIndex].forEach((i) => {
        i.disabled = true;
      });
      this.papForm[hcpIndex].forEach((i) => {
        i.disabled = true;
      });
    },

    // disablePatientDetail() {
    //   let patientIndex = this.stepperArr.findIndex(
    //     (i) => i.id === "patientInformation"
    //   );
    //   let fName = this.papForm[patientIndex].findIndex(
    //     (i) => i.key === "firstName"
    //   );
    //   let lName = this.papForm[patientIndex].findIndex(
    //     (i) => i.key === "lastName"
    //   );
    //   let dob = this.papForm[patientIndex].findIndex(
    //     (i) => i.key === "dateOfBirth"
    //   );
    //   this.papForm[patientIndex][fName].disabled = true;
    //   this.papForm[patientIndex][lName].disabled = true;
    //   this.papForm[patientIndex][dob].disabled = true;
    // },

    buildMissingArr(missingFields, index = null) {
      if (index != null && index >= 0) {
        this.missingFieldArr[index] = [];
        if (missingFields[this.stepperArr[index].id]?.length > 0) {
          missingFields[this.stepperArr[index].id].forEach((i) => {
            this.missingFieldArr[index].push(i);
          });
        }
      } else {
        for (const key in missingFields) {
          if (missingFields.hasOwnProperty(key)) {
            index = this.stepperArr.findIndex((i) => i.id === key);
            missingFields[key].forEach((i) => {
              this.missingFieldArr[index].push(i);
            });
          }
        }
      }
      //this.updateMissingFlag();
    },

    updateMissingFlag() {
      for (let i = 0; i < this.missingFieldArr.length; i++) {
        if (this.missingFieldArr[i].length > 0) {
          this.missingFlag = true;
          return;
        }
      }
      this.missingFlag = false;
    },

    // Getting breakpoint for col on the basis of screen inner width
    colBreakpoint() {
      this.breakpoint = window.innerWidth <= 1224 ? 12 : 6;
    },

    //Function to handle addition of file to upload
    onFileChange(e) {
      if (!this.templateData.length) this.getDocumentsName();
      this.openUploadPopup = true;
      // let file = e.target.files || e.dataTransfer.files;
      // this.filesToUpload.push(file[0]);
    },
    getDocumentsName() {
      this.axios.get(`/Shared/getConfig?payload=fileTypes`).then((res) => {
        this.templateData = res.data.data.map((prop) => {
          return prop.fileType;
        });
      });
    },
    //Function to handle deletion of file to upload
    removeFile(index) {
      this.filesToUpload.splice(index, 1);
    },

    // Return boolean for condtional class rendering
    classForLabel(index, itemKey, type) {
      return (
        type != "radio" ||
        (type === "radio" &&
          this.stepperArr[index].id === "prescriptionAndDiagnosis" &&
          (itemKey === "prescription" || itemKey === "dosageIncrease"))
      );
    },

    // Handle post api for getting prefill enrollment data
    getEnrollmentData(documentFlag = false) {
      if (!documentFlag) {
        this.loadingCount++;
      }
      this.axios
        .post(`Enrollment/searchEnrollmentById/`, {
          id: `${this.$route.params.id}`,
        })
        .then((res) => {
          if (res.data.error) {
            if (!documentFlag) {
              --this.loadingCount;
            }
          } else {
            this.tableData = res.data.data.documents;
            this.render++;
            if (!documentFlag) {
              this.enrollmentData = res.data.data;

              if (
                this.stepperArr.length > 0 &&
                this.papForm.length === this.stepperArr.length
              ) {
                this.prefillFields();
              }
              --this.loadingCount;
            }
          }
        })
        .catch((err) => {
          if (documentFlag) {
            this.isLoading = false;
          } else --this.loadingCount;
          console.log(err);
        });
    },

    // Handle get api call for states
    getStates() {
      this.allStates = this.$store.state.configState;
      this.allStates.forEach((item) => {
        if (item.name === "Others") {
          this.states.push(item.name);
        } else {
          this.states.push(item.abbreviation);
        }
      });
    },

    // Function to handle close button onclick
    closeAction() {
      if (!this.checkForChanges()) {
        this.close = true;
        this.alert = {
          status: "warning",
          statusText: this.getDirtyformText(),
          key: "closeAction",
          headerText: "Data will not be saved!",
          showCancelButton: true,
        };
        this.openAlert = true;
      } else {
        this.isPageReloaded = false;
        this.$route.params.id
          ? this.changeLockInStatus()
          : this.$router.push("/enrollments");
      }
    },

    // Function to determine if a field is mandatory or not to stop the flow of steppers
    // isRequired(index, fieldKey) {
    //   return (
    //     this.stepperArr[index].id === "patientInformation" &&
    //     (fieldKey === "firstName" ||
    //       fieldKey === "lastName" ||
    //       fieldKey === "dateOfBirth" ||
    //       fieldKey === "streetAddress" ||
    //       fieldKey === "city" ||
    //       fieldKey === "state" ||
    //       fieldKey === "zipCode" ||
    //       fieldKey === "gender")
    //   );
    // },

    // Fuction to set stepperForm which is used to check for unsaved data
    setStepperForm() {
      this.stepperForm.stepperId = this.stepperActiveIndex;
      this.stepperForm.papFormStep = this.papForm[this.stepperActiveIndex].map(
        (a) => ({ ...a })
      );
    },

    // Fuction to check for unsaved data
    checkForChanges() {
      if (
        JSON.stringify(this.papForm[this.stepperForm.stepperId]) ===
        JSON.stringify(this.stepperForm.papFormStep)
      ) {
        return true;
      } else {
        return false;
      }
    },

    papFormLockIn() {
      if (!this.$route.params.id) {
        return;
      }
      this.loadingCount++;
      let data = {
        enrollmentId: this.$route.params.id,
        userEmail: JSON.parse(sessionStorage.getItem("account")).username,
      };
      this.axios
        .post(`Enrollment/getEnrollmentLockedInStatus`, data)
        .then((res) => {
          --this.loadingCount;
          if (res.data.error) {
            this.dirtFormFlag = false;
            this.close = true;
            this.$router.push("/enrollments");
          } else {
            if (
              res.data.data.isLockedIn &&
              JSON.parse(sessionStorage.getItem("account")).username !==
                res.data.data.userEmail
            ) {
              this.close = true;
              this.alert = {
                status: "warning",
                statusText:
                  "Another user is currently working on this PAP form!",
                key: "papFormLockIn",
                headerText: "Alert!!",
              };
              this.openAlert = true;
            }
          }
        })
        .catch((err) => {
          --this.loadingCount;
          console.log(err);
          this.dirtFormFlag = false;
          this.close = true;
          this.$router.push("/enrollments");
        });
    },

    changeLockInStatus() {
      this.isLoading = true;
      let data = {
        enrollmentId: this.papEnrollmentId,
        isLockedIn: false,
      };
      this.axios
        .post(`Enrollment/getEnrollmentLockedInStatus`, data)
        .then((res) => {
          this.isLoading = false;
            this.dirtFormFlag = false;
            this.close = true;
            this.$router.push("/enrollments");
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    gotoPage(){
      if(this.$store.state.isAdmin && !this.$store.state.isAgent && !this.$store.state.isOrderEntry) this.$store.commit("updateAlert", "403");
    }
  },

  computed: mapState(["configState", "currentUser", "logoutFlag", "isAdmin", "isAgent", "isOrderEntry"]),

  created() {
    if (
      !this.$route.params.stepperId ||
      (!this.$route.params.id && this.$route.params.stepperId != 1)
    ) {
      this.$router.push(`/pap-form/${this.stepperActiveIndex + 1}`);
    }
    this.stepperActiveIndex = this.$route.params.stepperId - 1;
    this.programId = sessionStorage.getItem("programId");
    this.getStates();
    if (this.$route.params.id) {
      this.papEnrollmentId = this.$route.params.id;
      this.papFormLockIn();
    }
    if (Object.keys(this.$store.state.papForm).length > 0) {
      let papFormData = JSON.parse(JSON.stringify(this.$store.state.papForm));
      this.getFields(papFormData);
    } else {
      this.getPapForm();
    }
    if (Object.keys(this.$store.state.papEnrollmentData).length > 0) {
      this.enrollmentData = this.$store.state.papEnrollmentData;
      if (Object.keys(this.$store.state.papForm).length > 0) {
        this.prefillFields();
      }
      this.$store.commit("deleteEnrollmentData");
    } else if (this.$route.params.id) {
      this.getEnrollmentData();
    }
    window.addEventListener("resize", this.colBreakpoint);
    this.colBreakpoint();
    this.$store.dispatch("loggedIn", true);
  },
};
</script>

<style lang="scss" scoped>
th.text-start {
  font-size: 14px !important;
}
.papFormStepName {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a73e9;
}
.field-label {
  width: 35%;
  font-weight: bolder;
}
.input-field {
  width: 65%;
  max-width: 350px;
}
.radioWithDropdown {
  width: 35%;
  padding-right: 12px;
}
.countrycode-field {
  width: 100px;
}
.left-col {
  padding-right: 1rem;
  padding-left: 0px;
}
.right-col {
  padding-left: 1rem;
  padding-left: 0px;
}
.button-v-card {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.button-v-card .v-btn {
  width: 158px;
  height: 48px;
}
.filesToUpload {
  color: #1a73e9;
}
.closeIcon {
  color: #1a73e9;
}
.upload-button {
  cursor: default !important;
}
.mdi-arrow-left-circle-outline::before {
  font-size: 3rem !important;
}
.form--card {
  border: 1px solid #ceced6;
  border-radius: 4px;
}
.pap--list {
  list-style-type: none;
}
</style>
