<template>
  <div>
    <loader v-if="isLoading" />
    <custom-alert
      v-show="lockInStatus"
      headerText="Alert !!"
      status="warning"
      statusText="Some other user is processing this form"
      @confirm="lockInStatus = false"
      @close="lockInStatus = false"
    />
    <custom-alert
      v-show="isUnprocessed"
      :headerText="alert.headerText"
      status="warning"
      :statusText="alert.statusText"
      @confirm="isUnprocessed = false"
      @close="isUnprocessed = false"
    />
    <div>
      <v-row>
        <v-col cols="4" class="pd--0 media-flex">
          <div class="page-tile">Extracted List</div>
          <span class="underline-grey"></span>
        </v-col>
        <v-col class="pd--0 media-flex">
          <v-row justify="end">
            <InputField
              class="mr-2 seachField"
              placeholder="Search by Last Name or Source Fax"
              v-model="searchData"
              type="text"
              hide-details="auto"
              :searchBox="true"
              @on-enter="getData(true)"
            />
          </v-row>
        </v-col>
        <v-col class="pd--0 fit--content display-content">
          <v-row justify="end">
            <v-btn
              class="mr-2 btn--color--blue"
              @click="getData(true)"
              style="
                padding: 20px;
                font-size: 16px;
                text-transform: capitalize;
              "
              elevation="0"
            >
              Search
            </v-btn>
            <v-btn
              @click="oninput"
              class="btn--color--blue"
              style="
                padding: 20px;
                font-size: 16px;
                text-transform: capitalize;
              "
              elevation="0"
            >
              Upload
              <v-icon class="ml-2" medium>mdi-tray-arrow-up</v-icon>
            </v-btn>
            <input
              ref="uploadFile"
              type="file"
              @change="onUpload"
              multiple
              class="d-none"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="onUnprocessed"
                  class="ml-2 mr-2 btn--color--blue"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  style="
                    padding: 20px;
                    font-size: 16px;
                    text-transform: capitalize;
                  "
                  elevation="0"
                >
                  <v-icon medium>mdi-file-document-alert-outline</v-icon>
                </v-btn>
              </template>
              <span>Unextracted List</span></v-tooltip
            >
          </v-row>
        </v-col>
      </v-row>
      <v-card class="card-padding enrollment-list">
        <v-row>
          <v-col cols="12">
            <data-table
              class="data-table"
              :key="reRender"
              :headers="headers"
              :tableData="tableData"
              :totalCount="tableDataCount"
              :itemsPerPage="itemsPerPage"
              :pageNo="pageNo"
              @page-change="onPageChange"
              @item-change="onItemsPerPageChange"
              style="border: #ceced6 solid 1px"
            >
              <template slot="columns" slot-scope="props">
                <td>
                  <span>{{ props.item.date }}, </span>
                  <span style="color: #9e9ead">{{ props.item.time }}</span>
                </td>
                <td style="color: #0057ed" @click="onClickAction(props.item)">
                  <span class="cursor--pointer">
                    {{
                      props.item.enrollment_list.last_name != ""
                        ? props.item.enrollment_list.last_name
                        : "Unknown"
                    }}
                  </span>
                </td>
                <td>
                  {{
                    props.item.enrollment_list.first_name != ""
                      ? props.item.enrollment_list.first_name
                      : "Unknown"
                  }}
                </td>
                <td>{{ props.item.source }}</td>
                <td>{{ props.item.enrollment_list.fax }}</td>
                <td>
                  <span v-if="!props.item.loading">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                          <v-icon @click="showPDFPreview(props.item)"
                            >mdi-eye-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Preview</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="20"
                      width="3"
                    >
                    </v-progress-circular>
                  </span>
                </td>
              </template>
            </data-table>
          </v-col>
          <v-col>
            <v-dialog
              v-model="showpreview"
              width="35vw"
              style="max-height: 100% !important"
              persistent
              no-click-animation
            >
              <v-card height="100vh">
                <v-card-title class="preview_card"
                  ><v-spacer></v-spacer><span>Preview</span><v-spacer></v-spacer
                  ><v-icon
                    color="white"
                    size="20"
                    style="margin-right: 2%"
                    @click="
                      {
                        showpreview = false;
                        previewReset += 1;
                      }
                    "
                    >mdi-window-close</v-icon
                  ></v-card-title
                >
                <v-carousel height="95vh" :key="previewReset">
                  <v-carousel-item
                    class="image-item"
                    v-for="(item, i) in img_url"
                    :key="i"
                    :src="item"
                  ></v-carousel-item>
                </v-carousel>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>
<script>
import DataTable from "../components/DataTable.vue";
import Loader from "../components/Loader.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import InputField from "../components/InputField.vue";
import axios from "axios";
import moment from "moment";
export default {
  components: { DataTable, Loader, CustomAlert, InputField, },
  data: () => ({
    headers: [
      {
        text: "Date & Time",
        value: "datetime",
        width: "20%",
        sortable: false,
      },
      {
        text: "Last Name",
        value: "lastname",
        width: "20%",
        sortable: false,
      },
      {
        text: "First Name",
        value: "firstname",
        sortable: false,
        width: "20%",
      },
      {
        text: "Source",
        value: "source",
        width: "12.5%",
        sortable: false,
      },
      {
        text: "Fax No.",
        value: "fax",
        width: "12.5%",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        width: "15%",
        sortable: false,
      },
    ],
    tableData: [],
    skip: 0,
    pageNo: 1,
    tableDataCount: 0,
    itemsPerPage: 10,
    isSelecting: false,
    reRender: 0,
    img_url: [],
    showpreview: false,
    isLoading: false,
    previewReset: 0,
    lockInStatus: false,
    isUnprocessed: false,
    searchData: "",
    alert: {},
  }),
  watch: {
    searchData(data) {
      if(!data){
        this.getData(true);
      }
    },
  },
  methods: {
    getDateTime() {
      this.tableData.forEach((e) => {
        let Date = e.datetime.$date.split("T")[0];
        let formattedDate = moment(Date).format("MM/DD/YYYY");
        let formattedTime = moment(e.datetime.$date).format("hh:mm A");
        e.date = formattedDate;
        e.time = formattedTime;
      });
    },
    onUnprocessed() {
      this.$router.push("/pre-enrollment-list/unextracted");
    },
    onPageChange(data) {
      this.pageNo = data;
      this.skip = (data - 1) * this.itemsPerPage;
      this.getData();
    },
    onItemsPerPageChange(data) {
      this.itemsPerPage = data;
      this.getData();
    },
    oninput() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploadFile.click();
    },
    onUpload() {
      let input = this.$refs.uploadFile;
      let files = input.files;
      for (var i = 0; i < files.length; i++) {
        let formData = new FormData();
        let item = {};
        let params = {};
        params.uploaded_by = JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase();
        item.datetime = {};
        item.enrollment_list = {};
        item.datetime.$date = new Date().toJSON();
        item.loading = true;
        item.enrollment_list.first_name = "Unknown";
        item.enrollment_list.last_name = "Unknown";
        item.enrollment_list.fax = "";
        item.source = "Upload";
        let file = files[i];
        formData.append("file", file);
        let headers = {
          authorization: sessionStorage.getItem("accessToken"),
        };
        axios
          .post(
            `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/form-queue`,
            formData,
            { headers, params }
          )
          .then((res) => {
            if (res.data.data == null) {
              this.getData(true);
            }
            if (
              res.data.data.is_processed == 4
            ) {
              this.alert = {
                statusText: `${
                  "Document you just uploaded is not in confluence with ISTAMET. " +
                  "Click <img width='30' height='30' class='unprocessed-button mr-2 mt-2 ml-2' src='/unprocessed-button.png'/>" +
                  "icon to review the uploaded document."
                }`,
                headerText: "Error in Uploading Document",
              };
              this.isUnprocessed = true;
            }
            this.tableData.forEach((item) => {
              if (item.id == res.data.id) {
                item.is_extracted = 1;
                item.images_url = res.data.images_url;
                item.loading = false;
              }
            });
            setTimeout(() => {
              this.getData(true);
            }, 2000);
          })
          .catch((e) => {
            const errorItem = this.tableData.find(
              (q) => (q.uniqueId = item.uniqueId)
            );
          });
        this.tableData.unshift(item);
        this.getDateTime();
        this.tableDataCount = this.tableData.length;
      }
    },
    async showPDFPreview(item) {
      this.isLoading = true;
      let params = {};
      params.id = item._id.$oid;
      await this.axios
        .get(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-images-url`, {
          params,
        })
        .then((response) => {
          this.img_url = response.data.data;
          this.showpreview = true;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    async getData(searchFlag = false) {
      if(searchFlag && this.pageNo != 1){
        this.pageNo = 1;
        return;
      }
      this.isLoading = true;
      let params = {};
      params.queue_type = "preEnrollmentList";
      params.limit = this.itemsPerPage;
      params.offset = this.pageNo - 1 < 0 ? 0 : this.pageNo - 1;
      params.program_id = sessionStorage.getItem("programId");
      params.search_data = this.searchData;
      await this.axios
        .get(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/form-queue`, {
          params,
        })
        .then((response) => {
          this.tableDataCount = response.data.count;
          this.tableData = response.data.data;
          this.tableData.map((q) => ({ ...q, date: "", time: "" }));
          this.getDateTime();
          this.reRender++;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    async onClickAction(data) {
      this.isLoading = true;
      let payload = {};
      let params = {};
      params.id = data._id.$oid;
      payload.id = data._id.$oid;
      payload.lockedInStatus = true;
      const res1 = this.axios.post(
        `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-lock-in-status`,
        payload
      );
      const res2 = this.axios.get(
        `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-images-url`,
        { params }
      );

      Promise.all([res1, res2]).then((responses) => {
        if (responses[0].data.data) {
          this.lockInStatus = true;
        } else {
          data['images_url'] = responses[1].data.data;
          this.$store.commit("setEnrollmentData", data);
          this.$router.push({
            path: "/pap-form-fax/1",
            query: { fax_id: `${data._id.$oid}` },
          });
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style scoped>
form {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  margin: 0 0 30px;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #9e9ead;
}

form.searchbox {
  border: none;
  padding: 0;
}
.preview_card {
  background-color: #0057ed;
  padding: 0px !important;
  color: white;
  height: 5vh;
}
input.searchbox {
  border: 1px solid #555;
  border-radius: 10px;
  width: inherit;
  padding: 9px 4px 7px 38px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}
.tableheaders {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.unprocessed-button {
  width: 1.3rem;
}
.seachField{
  width: 385px !important;
}

@media only screen and (max-width: 1329px) {
  .media-flex {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .display-content {
    display: contents !important;
  }
}
</style>
