<template>
  <div>
    <loader v-if="isLoading" />
    <div>
      <div class="d-flex justify-space-between flex-wrap">
      <div class="mr--3">
        <div class="page-tile">Logs</div>
        <span class="underline-grey"></span>
      </div>
      <div class="d-flex mt--3">
        <InputField
          class="mr--3"
          placeholder="Start Date"
          type="text"
          @on-input="onInput($event, 'startDate')"
          @on-enter="getData(true)"
        />
        <InputField
          class="mr--2"
          placeholder="End Date"
          type="text"
          @on-input="onInput($event, 'endDate')"
          @on-enter="getData(true)"
        />
        <DropDown
          class="box--shadow--none mr--2 width--field"
          placeholder="Event Type"
          :items="items"
          item="Application error"
          @on-change="onInput($event, 'eventType')"
        />
            <v-btn
              @click="getData(true)"
              style="
                padding: 1.25rem 2.5rem;
                text-transform: capitalize;
              "
              class="btn--color--blue"
              elevation="0"
            >
              Search
            </v-btn>
            <v-btn
              @click="downloadItem"
              style="
                margin-left: 8px;
                padding: 1.25rem 2.5rem;
                text-transform: capitalize;
              "
              class="btn--color--blue"
              elevation="0"
            >
              Export to CSV
            </v-btn>
            </div>
            </div>
            <v-card class="card-padding enrollment-list">
              <data-table
                class="data-table"
                :key="reRender"
                :headers="headers"
                :tableData="tableData"
                :totalCount="tableDataCount"
                :itemsPerPage="itemsPerPage"
                :pageNo="pageNo"
                @page-change="onPageChange"
                @item-change="onItemsPerPageChange"
                style="border: #ceced6 solid 1px"
              >
                <template slot="columns" slot-scope="props">
                  <td>
                    <span>{{ props.item.date }}, </span>
                    <span style="color: #9e9ead">{{ props.item.time }}</span>
                  </td>
                  <td style="color: #0057ed">{{ props.item.endPoint }}</td>
                  <td style="text-align: center">{{ props.item.requestType }}</td>
                  <td :class="props.item.isOverflow ? 'text-wrap' : 'text-overflow'"
                    @click="removeClasses(props.item)"
                    style="cursor: pointer;">{{ props.item.payload }}</td>
                  <td
                    :class="props.item.isOverflow ? 'text-wrap' : 'text-overflow'"
                    @click="removeClasses(props.item)"
                    style="cursor: pointer;"
                  >
                    {{ props.item.beforeUpdate }}
                  </td>
                  <td
                    class="cursor--pointer"
                    :class="props.item.isOverflow ? 'text-wrap' : 'text-overflow'"
                    @click="removeClasses(props.item)"
                  >
                    {{ props.item.afterUpdate }}
                  </td>
                  <td>{{ props.item.createdBy }}</td>
                  <td :class="props.item.status == 'Success' ? 'green-color' : 'red-color'">{{ props.item.status }}</td>
                  <td :class="props.item.isOverflow ? 'text-wrap' : 'text-overflow'"
                    @click="removeClasses(props.item)"
                    style="cursor: pointer;">{{ props.item.errorMessage }}</td>
                </template>
              </data-table>
            </v-card>
    </div>
  </div>
</template>
<script>
import DataTable from "../components/DataTable.vue";
import Loader from "../components/Loader.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import InputField from "@/components/InputField.vue";
import DropDown from "@/components/DropDown.vue";
import moment from "moment";
export default {
  components: { DataTable, Loader, CustomAlert, InputField, DropDown },
  data: () => ({
    headers: [
      {
        text: "Date & Time",
        value: "datetime",
        sortable: false,
      },
      {
        text: "Endpoint",
        value: "endpoint",
        sortable: false,
      },
      {
        text: "Request Type",
        value: "requestType",
        sortable: false,
      },
      {
        text: "Payload",
        value: "payload",
        sortable: false,
      },
      {
        text: "Before Update",
        value: "beforeUpdate",
        sortable: false,
      },
      {
        text: "After Update",
        value: "afterUpdate",
        sortable: false,
      },
      {
        text: "Accessed By",
        value: "accessedBy",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Error Message",
        value: "error",
        sortable: false,
      },
    ],
    searchData: {
      startDate: "",
      endDate: "",
      eventType: 2
    },
    tableData: [],
    skip: 0,
    pageNo: 1,
    tableDataCount: 0,
    itemsPerPage: 50,
    reRender: 0,
    isLoading: false,
    items: [],
    logsData: {},
  }),

  watch: {
    'searchData.startDate': function(newValue, oldValue) {
      if(!newValue && !this.searchData.endDate) this.getData(true);
    },
    'searchData.endDate': function(newValue, oldValue) {
      if(!newValue && !this.searchData.startDate) this.getData(true);
    },
  },

  methods: {
    downloadItem(){
      let payload = {
        startDate: this.searchData.startDate,
        endDate: this.searchData.endDate,
        eventTypeId: parseInt(this.searchData.eventType),
        skip: this.pageNo - 1 < 0 ? 0 : this.pageNo - 1,
        limit: this.itemsPerPage
      };
      this.isLoading = true;
      this.axios.post(`${process.env.VUE_APP_BASE_URL}Logger/exportLogsData`, payload).then((res) => {
        let content = atob(res.data.data);
        var blob = new Blob([content], {type: "data:application/octet-stream;base64"});
        var url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "file.csv";
        a.click();
        this.isLoading = false;
      }).catch(()=> this.isLoading = false);
    },
    onInput(val, key){
      if(key == "eventType"){
        let index = this.logsData.findIndex((i)=> i.type == val);
        if(index > -1) this.searchData[key] = this.logsData[index].id;
      }else this.searchData[key] = val;
    },

    removeClasses(val) {
      val.isOverflow = !val.isOverflow;
      this.reRender++;
    },
    getDateTime() {
      this.tableData.forEach((e) => {
        e.isOverflow = false;
        let Date = e.createdDate.split("T")[0];
        let formattedDate = moment(Date).format("MM/DD/YYYY");
        let formattedTime = moment(e.createdDate).format("hh:mm A");
        e.date = formattedDate;
        e.time = formattedTime;
        if (e.isError) e.status = "Error";
        else e.status = "Success";
      });
    },

    onPageChange(data) {
      this.pageNo = data;
      this.skip = (data - 1) * this.itemsPerPage;
      this.getData();
    },

    onItemsPerPageChange(data) {
      this.itemsPerPage = data;
      this.getData();
    },

    async getEventTypeId() {
      this.isLoading = true;
      await this.axios
        .get(`${process.env.VUE_APP_BASE_URL}Shared/getEventTypeId`)
        .then((res) => {
          this.logsData = res.data.data;
          this.logsData.forEach((e) => {
            this.items.push(e.type);
          });
          this.isLoading = false;
        });
    },

    async getData(searchFlag = false) {
      if(searchFlag && this.pageNo != 1){
        this.pageNo = 1;
        return;
      }
      this.isLoading = true;
      let payload = {
        startDate: this.searchData.startDate,
        endDate: this.searchData.endDate,
        eventTypeId: parseInt(this.searchData.eventType),
        skip: this.pageNo - 1 < 0 ? 0 : this.pageNo - 1,
        limit: this.itemsPerPage
      };
      await this.axios.post(`${process.env.VUE_APP_BASE_URL}Logger/getLogs`, payload).then((response) => {
          if (response.data.statusCode != 204) {
            this.tableDataCount = response.data.data.count;
            this.tableData = response.data.data.logs;
            this.tableData.map((q) => ({
              ...q,
              date: "",
              time: "",
              status: "",
              isOverflow: false,
            
            }));
            this.getDateTime();
            this.reRender++;
          } else {
            this.tableDataCount = 0;
            this.tableData = [];
          }
          this.isLoading = false;
        });
    },
  },

  async created() {
    await this.getEventTypeId();
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.text-overflow {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width--field {
  width: 16rem;
  @media screen and (max-width: 1040px){
    width: 12rem;
  }
}
.green-color{
  color: green;
}
.red-color{
  color: red;
}
</style>
