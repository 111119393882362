import axios from "axios";
import store from "../store";
import msalAuth from "./auth";
import moment from "moment";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

let sessionTimeout = null;
let timeout = null;

instance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers.authorization = sessionStorage.getItem("accessToken");
    let expiresTime = moment(sessionStorage.getItem("expiresTime")).format();
    let isTokenExpired = moment(expiresTime).isSameOrBefore(moment().format());
    if (sessionStorage.getItem("accessToken") && isTokenExpired) {
      console.log("Token expired!!");
      let account = JSON.parse(sessionStorage.getItem("account"));
      let request = {
        account: account,
        scopes: ["user.read"],
      };
      msalAuth.acquireTokenSilent(request).then((res) => {
        if (res) {
          config.headers.authorization = res.accessToken;
          sessionStorage.setItem("accessToken", res.accessToken);
          sessionStorage.setItem("expiresTime", res.expiresOn);
        }
      });
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
      if (sessionStorage.getItem("accessToken")) {
        sessionStorage.setItem("sessionExpired", true);
        store.commit("updateAlert", "expired");
      }
    }, 3600000);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (sessionStorage.getItem("accessToken")) {
        store.commit("updateAlert", "beforeExpired");
      }
    }, 3585000);
    if (
      sessionStorage.getItem("accessToken") &&
      response &&
      response.data &&
      response.data.data &&
      response.data.data.status_code === 401
    ) {
      store.commit("updateAlert", "401");
    }

    let arr = [];
    if(response && response.data && response.data.user && response.data.user.role) arr = response.data.user.role;
    let storeUser = store.state.currentUser.role;
    if(storeUser != undefined && arr.length != 0){
      let isRoleUpdated = JSON.stringify(storeUser.sort()) !== JSON.stringify(arr.sort());
      if(isRoleUpdated){
        store.commit("isRoleUpdated");
        store.commit("updateUser", response.data.user);
        let headers = {
          "authorization": sessionStorage.getItem("accessToken")
        };
        axios.get(`${process.env.VUE_APP_BASE_URL}Program/getProgramFields?programId=${sessionStorage.getItem("programId")}`,{headers}).then((res) => {
            store.commit("setForm", {
              papFormTitle: JSON.parse(JSON.stringify(res.data.data[0].programImageUrl)),
              papForm: JSON.parse(JSON.stringify(res.data.data[0].papForm)),
              papFormFax: JSON.parse(JSON.stringify(res.data.data[0].papFormFax)),
            });
            store.commit("updateAlert", "roleUpdated");
          });
      }
    }
    return response;
  },
  (error) => {
    if(sessionStorage.getItem("accessToken") && !store.state.isRoleUpdated){
      if (error.response.status === 401) store.commit("updateAlert", "401");
      else if (error.response.status === 403) store.commit("updateAlert", "403");
      else if (error.response.data.error.statusCode == 404 && error.response.data.error.name == "User not found") store.commit("updateAlert", "user_not_found");
      else store.commit("updateAlert", "something went wrong"); 
    }
    return Promise.reject(error);
  }
);

export default instance;
