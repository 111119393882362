var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isLoading)?_c('loader'):_vm._e(),(_vm.loadingCount === 0)?_c('v-row',{staticClass:"fax-content-container d-flex"},[_c('div',[_c('div',{staticClass:"fax--image--container"},[_c('form-preview',{attrs:{"coordinates":_vm.coordinates,"overlay":_vm.overlay,"showImage":_vm.showImage,"previewImage":_vm.previewImage,"urls":_vm.urls},on:{"previewImageChange":_vm.handlePreviewEvent}})],1)]),_c('div',{staticClass:"right-col-container"},[_c('header',{staticClass:"col-header d-flex justify-space-between"},[_c('div',[_vm._v("Form Extraction")]),(_vm.statusCode != null)?_c('div',[_vm._v(" Enrollment Status: "),_c('span',{staticClass:"enrollment-status-code"},[_vm._v(_vm._s(_vm.enrollmentStatus))])]):_vm._e()]),_c('div',{staticClass:"form--border"},[_c('div',[_c('stepper',{attrs:{"stepperArr":_vm.stepperArr,"stepperActiveIndex":_vm.stepperActiveIndex,"hideLeftHeading":true,"missingFlag":_vm.missingFlag},on:{"clickMissingInfo":function($event){_vm.missingPopupDisplay = true}}})],1),_c('v-card',{staticClass:"card--spacing"},[(
              _vm.stepperArr[_vm.stepperActiveIndex] &&
              _vm.stepperArr[_vm.stepperActiveIndex].name
            )?_c('div',{staticClass:"d-flex justify-space-between align-center mb--3"},[_c('span',{staticClass:"papFormStepName barlow-bold"},[_vm._v(" "+_vm._s(_vm.stepperArr[_vm.stepperActiveIndex].name)+" ")]),_c('span',{staticClass:"asterix-required-field"},[_vm._v("*Required fields")])]):_vm._e(),_c('v-row',{staticClass:"fields--heading barlow-bold"},[_c('div',{staticClass:"field-label-heading"},[_vm._v("Fields")]),(_vm.mergeFlag && _vm.stepperActiveIndex > 0)?_c('div',{staticClass:"previous--field--heading"},[_vm._v(" Previous Value ")]):_vm._e(),_c('div',{staticClass:"form--field--heading"},[_vm._v(" "+_vm._s(_vm.mergeFlag === true && _vm.stepperActiveIndex > 0 ? "Merge Value" : "Value")+" ")])]),_vm._l((_vm.papForm),function(item,i){return _c('div',{key:i},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepperActiveIndex === i),expression:"stepperActiveIndex === i"}]},[_c('v-form',{ref:_vm.stepperArr[i].id,refInFor:true,staticClass:"w--full",model:{value:(_vm.formModels[_vm.stepperArr[i].id]),callback:function ($$v) {_vm.$set(_vm.formModels, _vm.stepperArr[i].id, $$v)},expression:"formModels[stepperArr[i].id]"}},[_c('v-col',{staticClass:"pb--0 pl--0"},_vm._l((item.length),function(field,index){return _c('v-row',{key:index,staticClass:"pb--3 flex-nowrap align-center"},[(item[index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(item[index].label)+" ")]):_c('div',{staticClass:"field-label pr--3"},[_c('span',[_vm._v(" "+_vm._s(item[index].label)+" "),(item[index].required)?_c('span',{staticClass:"asterix-required-field"},[_vm._v("*")]):_vm._e()])]),(_vm.mergeFlag && _vm.stepperActiveIndex > 0)?_c('div',{staticClass:"previous--form--field mr--3 ml--3"},[_vm._v(" "+_vm._s(item[index].previousValue)+" ")]):_vm._e(),_c('div',{staticClass:"form--field",on:{"mouseover":function($event){return _vm.getBoundingBox(item[index].key)},"mouseleave":function($event){_vm.overlay = false}}},[(
                          item[index].type === 'text' ||
                          item[index].type === 'phone' ||
                          item[index].type === 'date' ||
                          item[index].type === 'partialPassword'
                        )?_c('InputField',{staticClass:"input-field",attrs:{"placeholder":item[index].placeholder,"reGex":item[index].reGex,"errorMsg":item[index].error,"type":item[index].type,"defaultValue":item[index].value,"disabled":item[index].disabled ? true : false || item[index].isAccess ? false : true,"maxlength":item[index].maxlength,"hide-details":'auto'},on:{"on-input":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}},model:{value:(_vm.papForm[i][index].value),callback:function ($$v) {_vm.$set(_vm.papForm[i][index], "value", $$v)},expression:"papForm[i][index].value"}}):_vm._e(),(item[index].type === 'dropdown')?_c('DropDown',{staticClass:"input-field box--shadow--none",attrs:{"items":item[index].key === 'state'
                            ? _vm.states
                            : item[index].items,"item":item[index].value,"disabled":item[index].disabled ? true : false || item[index].isAccess ? false : true,"placeholder":item[index].placeholder,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}},model:{value:(_vm.papForm[i][index].value),callback:function ($$v) {_vm.$set(_vm.papForm[i][index], "value", $$v)},expression:"papForm[i][index].value"}}):_vm._e(),(item[index].type === 'radio')?_c('Checkbox',{attrs:{"items":item[index].items,"defaultValue":item[index].value,"disabled":item[index].disabled ? true : false || item[index].isAccess ? false : true,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}},model:{value:(_vm.papForm[i][index].value),callback:function ($$v) {_vm.$set(_vm.papForm[i][index], "value", $$v)},expression:"papForm[i][index].value"}}):_vm._e()],1)])}),1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.stepperArr[_vm.stepperActiveIndex].id ===
                  'prescriptionAndDiagnosis'
                ),expression:"\n                  stepperArr[stepperActiveIndex].id ===\n                  'prescriptionAndDiagnosis'\n                "}],staticClass:"pl--0"},[_c('v-row',[_c('div',{staticClass:"pb--3"},[_c('v-btn',{staticClass:"btn--color--blue cursor--pointer",attrs:{"depressed":""},on:{"click":_vm.onFileChange,"close":_vm.closeUploadPopup}},[_vm._v("Upload Documents "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-upload ")])],1)],1)]),_c('v-row',[_c('div',{staticClass:"pt--3 table--container"},[_c('data-table',{key:_vm.render,staticClass:"data-table",attrs:{"headers":_vm.headers,"tableData":_vm.tableData},scopedSlots:_vm._u([{key:"columns",fn:function(props){return [_c('td',[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.fileName)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.fileType)+" ")]),_c('td',[_c('v-icon',{staticStyle:{"font-size":"18px","cursor":"pointer !important"},attrs:{"color":"#0057ED"},on:{"click":function($event){return _vm.getPreview(props.item.fileName)}}},[_vm._v("mdi-eye")]),(_vm.$store.state.isOrderEntry)?_c('v-icon',{staticStyle:{"font-size":"18px","cursor":"pointer !important","margin-left":"12px"},attrs:{"color":"#0057ED"},on:{"click":function($event){return _vm.deleteDocuments(props)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}}],null,true)})],1)])],1)],1)],1)})],2)],1),_c('v-card',{staticClass:"card--spacing d-flex justify-space-between button-v-card"},[_c('div',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepperActiveIndex > 0),expression:"stepperActiveIndex > 0"}],staticClass:"cursor--pointer",attrs:{"src":require("../assets/images/previousIcon.svg"),"alt":""},on:{"click":function($event){return _vm.priviousStepper()}}})]),_c('div',[_c('v-btn',{staticClass:"btn--color--white ml--3",attrs:{"depressed":""},on:{"click":function($event){return _vm.closeAction()}}},[_vm._v(" Cancel ")]),(
              _vm.stepperArr[_vm.stepperActiveIndex] &&
              _vm.stepperArr[_vm.stepperActiveIndex].last
            )?_c('v-btn',{staticClass:"btn--color--blue ml--3",attrs:{"depressed":""},on:{"click":function($event){return _vm.saveFormData()}}},[_vm._v(" Submit ")]):_c('v-btn',{staticClass:"btn--color--blue ml--3",attrs:{"depressed":""},on:{"click":function($event){return _vm.nextStepper()}}},[_vm._v(" Continue ")])],1)])],1)]):_vm._e(),_c('v-dialog',{key:_vm.rerender,attrs:{"persistent":"","width":"auto"},on:{"click:outside":_vm.closePreview},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.fileType == 'pdf')?_c('div',{attrs:{"id":"pdf-viewer"}}):_c('img',{attrs:{"src":_vm.imageSrc,"alt":""}})])],1),(_vm.missingPopupDisplay)?_c('MissingPopup',{attrs:{"missingFieldArr":_vm.missingFieldArr,"stepperArr":_vm.stepperArr},on:{"closeAction":function($event){_vm.missingPopupDisplay = false}}}):_vm._e(),(_vm.openUploadPopup)?_c('upload-popup',{attrs:{"template-data":_vm.templateData},on:{"close":_vm.closeUploadPopup,"uploadedDocument":_vm.getDocuments}}):_vm._e(),(_vm.openAlert)?_c('custom-alert',{attrs:{"headerText":_vm.alert.headerText,"status":_vm.alert.status,"statusText":_vm.alert.statusText,"showCancelButton":_vm.alert.showCancelButton},on:{"confirm":function($event){return _vm.onConfirm(_vm.alert.key)},"close":function($event){return _vm.onClose(_vm.alert.key)}}}):_vm._e(),(_vm.openAlertList)?_c('custom-alert',{attrs:{"headerText":_vm.alertList.headerText,"alertType":_vm.alertList.type,"status":_vm.alertList.status,"statusText":_vm.alertList.statusText,"listArr":_vm.alertList.listArr},on:{"confirm":function($event){return _vm.onConfirmList(_vm.alertList.key)},"close":function($event){return _vm.onCloseList(_vm.alert.key)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }