<template>
  <!-- image -->
  <v-container fluid>
    <loader v-if="isLoading" />
    <v-row v-if="loadingCount === 0" class="fax-content-container d-flex">
      <div>
        <!-- <header class="col-header">Fax</header> -->
        <div class="fax--image--container">
          <form-preview
            :coordinates="coordinates"
            :overlay="overlay"
            :showImage="showImage"
            :previewImage="previewImage"
            :urls="urls"
            @previewImageChange="handlePreviewEvent"
          ></form-preview>
        </div>
      </div>
      <!-- stepper -->
      <div class="right-col-container">
        <header class="col-header d-flex justify-space-between">
          <div>Form Extraction</div>
          <div v-if="statusCode != null">
            Enrollment Status:
            <span class="enrollment-status-code">{{ enrollmentStatus }}</span>
          </div>
        </header>
        <div class="form--border">
          <div>
            <stepper
              :stepperArr="stepperArr"
              :stepperActiveIndex="stepperActiveIndex"
              :hideLeftHeading="true"
              :missingFlag="missingFlag"
              v-on:clickMissingInfo="missingPopupDisplay = true"
            />
          </div>
          <v-card class="card--spacing">
            <div
              class="d-flex justify-space-between align-center mb--3"
              v-if="
                stepperArr[stepperActiveIndex] &&
                stepperArr[stepperActiveIndex].name
              "
            >
              <span class="papFormStepName barlow-bold">
                {{ stepperArr[stepperActiveIndex].name }}
              </span>
              <span class="asterix-required-field">*Required fields</span>
            </div>
            <v-row class="fields--heading barlow-bold">
              <div class="field-label-heading">Fields</div>
              <div
                v-if="mergeFlag && stepperActiveIndex > 0"
                class="previous--field--heading"
              >
                Previous Value
              </div>
              <div class="form--field--heading">
                {{
                  mergeFlag === true && stepperActiveIndex > 0
                    ? "Merge Value"
                    : "Value"
                }}
              </div>
            </v-row>
            <div v-for="(item, i) in papForm" :key="i">
              <v-row v-show="stepperActiveIndex === i">
                <v-form
                  v-model="formModels[stepperArr[i].id]"
                  :ref="stepperArr[i].id"
                  class="w--full"
                >
                  <v-col class="pb--0 pl--0">
                    <v-row
                      class="pb--3 flex-nowrap align-center"
                      v-for="(field, index) in item.length"
                      :key="index"
                    >
                      <h3 class="mb--3" v-if="item[index].type === 'heading'">
                        {{ item[index].label }}
                      </h3>
                      <div v-else class="field-label pr--3">
                        <span>
                          {{ item[index].label }}
                          <span
                            class="asterix-required-field"
                            v-if="item[index].required"
                            >*</span
                          >
                        </span>
                      </div>
                      <div
                        v-if="mergeFlag && stepperActiveIndex > 0"
                        class="previous--form--field mr--3 ml--3"
                      >
                        {{ item[index].previousValue }}
                      </div>

                      <div
                        class="form--field"
                        @mouseover="getBoundingBox(item[index].key)"
                        @mouseleave="overlay = false"
                      >
                        <InputField
                          v-if="
                            item[index].type === 'text' ||
                            item[index].type === 'phone' ||
                            item[index].type === 'date' ||
                            item[index].type === 'partialPassword'
                          "
                          class="input-field"
                          :placeholder="item[index].placeholder"
                          :reGex="item[index].reGex"
                          :errorMsg="item[index].error"
                          v-model="papForm[i][index].value"
                          :type="item[index].type"
                          :defaultValue="item[index].value"
                          :disabled="item[index].disabled ? true : false || item[index].isAccess ? false : true"
                          :maxlength="item[index].maxlength"
                          :hide-details="'auto'"
                          @on-input="onInput($event, i, item[index].fieldIndex)"
                        />
                        <DropDown
                          v-if="item[index].type === 'dropdown'"
                          class="input-field box--shadow--none"
                          :items="
                            item[index].key === 'state'
                              ? states
                              : item[index].items
                          "
                          v-model="papForm[i][index].value"
                          :item="item[index].value"
                          :disabled="item[index].disabled ? true : false || item[index].isAccess ? false : true"
                          :placeholder="item[index].placeholder"
                          :hide-details="'auto'"
                          @on-change="
                            onInput($event, i, item[index].fieldIndex)
                          "
                        />
                        <Checkbox
                          v-if="item[index].type === 'radio'"
                          v-model="papForm[i][index].value"
                          :items="item[index].items"
                          :defaultValue="item[index].value"
                          :disabled="item[index].disabled ? true : false || item[index].isAccess ? false : true"
                          :hide-details="'auto'"
                          @on-change="
                            onInput($event, i, item[index].fieldIndex)
                          "
                        />
                      </div>
                    </v-row>
                  </v-col>
                </v-form>
                <v-col
                  class="pl--0"
                  v-show="
                    stepperArr[stepperActiveIndex].id ===
                    'prescriptionAndDiagnosis'
                  "
                >
                  <v-row>
                    <div class="pb--3">
                      <v-btn
                        depressed
                        class="btn--color--blue cursor--pointer"
                        @click="onFileChange"
                        @close="closeUploadPopup"
                        >Upload Documents
                        <v-icon right dark> mdi-upload </v-icon></v-btn
                      >
                    </div>
                  </v-row>
                  <v-row>
                    <div class="pt--3 table--container">
                      <data-table
                        :key="render"
                        class="data-table"
                        :headers="headers"
                        :tableData="tableData"
                      >
                        <template slot="columns" slot-scope="props">
                          <td>
                            {{ props.index + 1 }}
                          </td>
                          <td>
                            {{ props.item.fileName }}
                          </td>
                          <td>
                            {{ props.item.fileType }}
                          </td>
                          <td>
                            <v-icon
                              color="#0057ED"
                              style="
                                font-size: 18px;
                                cursor: pointer !important;
                              "
                              @click="getPreview(props.item.fileName)"
                              >mdi-eye</v-icon
                            >
                            <v-icon
                              v-if="$store.state.isOrderEntry"
                              color="#0057ED"
                              style="
                                font-size: 18px;
                                cursor: pointer !important;
                                margin-left: 12px;
                              "
                              @click="deleteDocuments(props)"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </template>
                      </data-table>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
        <v-card
          class="card--spacing d-flex justify-space-between button-v-card"
        >
          <div>
            <img
              src="../assets/images/previousIcon.svg"
              alt=""
              v-show="stepperActiveIndex > 0"
              class="cursor--pointer"
              @click="priviousStepper()"
            />
          </div>
          <div>
            <v-btn
              depressed
              class="btn--color--white ml--3"
              @click="closeAction()"
            >
              Cancel
            </v-btn>
            <!-- <v-btn
              v-if="
                stepperArr[stepperActiveIndex] &&
                !stepperArr[stepperActiveIndex].last
              "
              depressed
              class="btn--color--white ml--3"
              @click="saveClose()"
            >
              Save & Close
            </v-btn> -->
            <v-btn
              v-if="
                stepperArr[stepperActiveIndex] &&
                stepperArr[stepperActiveIndex].last
              "
              depressed
              class="btn--color--blue ml--3"
              @click="saveFormData()"
            >
              Submit
            </v-btn>
            <v-btn
              v-else
              depressed
              class="btn--color--blue ml--3"
              @click="nextStepper()"
            >
              Continue
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-row>
    <v-dialog :key="rerender" v-model="dialog" persistent width="auto" @click:outside="closePreview">
      <v-card>
        <div v-if="fileType == 'pdf'" id="pdf-viewer"></div>
        <img v-else :src="imageSrc" alt="">
      </v-card>
    </v-dialog>
    <MissingPopup
      v-if="missingPopupDisplay"
      :missingFieldArr="missingFieldArr"
      :stepperArr="stepperArr"
      v-on:closeAction="missingPopupDisplay = false"
    />
    <upload-popup
      v-if="openUploadPopup"
      :template-data="templateData"
      @close="closeUploadPopup"
      @uploadedDocument="getDocuments"
    />

    <custom-alert
      v-if="openAlert"
      :headerText="alert.headerText"
      :status="alert.status"
      :statusText="alert.statusText"
      :showCancelButton="alert.showCancelButton"
      @confirm="onConfirm(alert.key)"
      @close="onClose(alert.key)"
    />
    <custom-alert
      v-if="openAlertList"
      :headerText="alertList.headerText"
      :alertType="alertList.type"
      :status="alertList.status"
      :statusText="alertList.statusText"
      :listArr="alertList.listArr"
      @confirm="onConfirmList(alertList.key)"
      @close="onCloseList(alert.key)"
    />
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Stepper from "../components/Stepper.vue";
import headerNav from "../components/headerNav.vue";
import InputField from "../components/InputField.vue";
import Checkbox from "../components/Checkbox.vue";
import DropDown from "../components/DropDown.vue";
import CheckboxWithDropdown from "../components/CheckboxWithDropdown.vue";
import Loader from "../components/Loader.vue";
import MissingPopup from "@/components/MissingPopup.vue";
import FormPreview from "@/components/FormPreview.vue";
import CustomAlert from "../components/CustomAlert.vue";
import DataTable from "../components/DataTable.vue";
import UploadPopup from "@/components/UploadPopup.vue";

export default {
  components: {
    Stepper,
    headerNav,
    InputField,
    Checkbox,
    DropDown,
    CheckboxWithDropdown,
    MissingPopup,
    Loader,
    FormPreview,
    CustomAlert,
    DataTable,
    UploadPopup,
  },

  props: {
    faxId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      dialog: false,
      imageSrc: "",
      isLoading: false,
      isFormValid: false,
      programName: "Medvantica",
      programId: "",
      rerender: 0,
      stepperArr: [],
      stepperActiveIndex: 0,
      papForm: [],
      disabled: false,
      breakpointFlag: false,
      filesToUpload: [],
      states: [],
      allStates: [],
      enrollmentData: {},
      saveCloseFlag: false,
      papEnrollmentId: "",
      formModels: {},
      stepperForm: {},
      hcpEdit: false,
      userId: null,
      statusCode: null,
      dirtFormFlag: true,
      missingFlag: false,
      missingPopupDisplay: false,
      missingFieldArr: [],
      isPageReloaded: false,
      loadingCount: 0,
      previewImage: null,
      showImage: false,
      overlay: false,
      urls: [],
      coordinates: {},
      faxUrls: [],
      openAlert: false,
      openAlertList: false,
      alert: {},
      alertList: {},
      faxBoundingBox: {},
      mergeFlag: false,
      enrollmentStatus: "",
      changeImage: 0,
      prescriptgrxCall: false,
      openUploadPopup: false,
      headers: [
        {
          text: "S.No",
          value: "id",
        },
        {
          text: "Form Name",
          value: "formName",
        },
        {
          text: "Form Type",
          value: "formType",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      tableData: [],
      routePath: "",
      keyFlag: false,
      close: false,
      templateData: [],
      formId: this.faxId,
      isSubmitted: false,
      faxDeleteFlag: false,
      faxRefresh: false,
      papLogoutFlag: false,
      render: 0,
      fileType: "",
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.checkForChanges() && !this.close && !this.faxDeleteFlag && !this.$store.state.isRoleUpdated) {
      this.alert = {
        status: "warning",
        statusText:
          "If you Cancel now, your data would not be saved. Do you still want to continue?",
        key: "beforeRouteLeave",
        headerText: "Data will not be saved!",
        showCancelButton: true,
      };
      this.openAlert = true;
      this.routePath = to.path;
      if (this.keyFlag) {
        next();
      }
    } else if (
      this.formId &&
      !this.isSubmitted &&
      !this.close &&
      !this.faxDeleteFlag
    ) {
      this.isLoading = true;
      let data = {
        enrollmentId: this.papEnrollmentId,
        formId: this.formId,
      };
      this.axios
        .post(`Enrollment/deleteEnrollment`, data)
        .then((res) => {
          this.isLoading = false;
          if (!res.data.error) {
            next();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    } else if (this.$route.params.id && !this.close && this.isSubmitted) {
      this.isLoading = true;
      let data = {
        enrollmentId: this.papEnrollmentId,
        isLockedIn: false,
      };
      this.axios
        .post(`Enrollment/getEnrollmentLockedInStatus`, data)
        .then((res) => {
          this.isLoading = false;
          if (!res.data.error) {
            next();
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    } else {
      this.faxDeleteFlag = false;
      next();
    }
  },
  watch: {
    loadingCount(data) {
      if (data != 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },
    configState: {
      deep: true,
      handler() {
        this.getStates();
      },
    },
    currentUser: {
      immediate: true,
      handler() {
        this.userId = this.$store.state.currentUser._id;
      },
    },
    statusCode: {
      immediate: true,
      handler(data) {
        this.getEnrollmentStatus(data);
      },
    },
    configStatus: {
      deep: true,
      handler() {
        this.getEnrollmentStatus(this.statusCode);
      },
    },
    logoutFlag(newValue, oldValue) {
      if (newValue) {
        this.preventNav();
      }
    },
    isAdmin: {
      immediate: true,
      handler() {
        this.gotoPage();
      },
    },
    isAgent: {
      immediate: true,
      handler() {
        this.gotoPage();
      },
    },
    isOrderEntry: {
      immediate: true,
      handler() {
        this.gotoPage();
      },
    },
  },

  methods: {
    closePreview(){
      this.dialog = false;
    },
    getPreview(file){
      var currPage = 1;
      var thePDF = null;
      this.isLoading = true;
      this.fileType = file.split('.').pop();
      this.axios.get(`Enrollment/getDocumentUrl?filename=${file}`).then((res)=> {
        if(this.fileType == "pdf"){
        pdfjsLib.getDocument(res.data.data).then((pdf) => {
        thePDF = pdf;
        pdf.getPage(1).then( handlePages );
        });
        function handlePages(page){
        var viewport = page.getViewport(1.82);
        var canvas = document.createElement("canvas");
        canvas.style.display = "block";
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        let pdfViewer = document.getElementById('pdf-viewer');
        pdfViewer.appendChild( canvas );
        page.render({canvasContext: context, viewport: viewport});
        currPage++;
        if ( thePDF !== null && currPage <= 2 ){
          thePDF.getPage( currPage ).then( handlePages );
        }
      }
        }else this.imageSrc = res.data.data;
      this.rerender++;
      this.dialog = true;    
      setTimeout(()=> this.isLoading = false, 3000);
      });
    },
    gotoPage(){
      if(this.$store.state.isAdmin && !this.$store.state.isAgent && !this.$store.state.isOrderEntry) this.$store.commit("updateAlert", "403");
    },
    preventNav(event) {
      let data = {};
      if (
        this.formId &&
        !this.isSubmitted &&
        !this.close &&
        !this.papLogoutFlag
      ) {
        let data = {
          enrollmentId: this.papEnrollmentId,
          formId: this.formId,
        };
        this.axios
          .post(`Enrollment/deleteEnrollment`, data)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
        if (this.$route.params.id) {
          this.close = true;
          sessionStorage.setItem("faxRefresh", true);
        }
      } else if (this.isSubmitted && !this.close && !this.papLogoutFlag && this.$route.params.id) {
        data = {
          enrollmentId: this.papEnrollmentId,
          isLockedIn: false,
        };
        this.axios
          .post(`Enrollment/getEnrollmentLockedInStatus`, data)
          .then((response) => {
            //console.log("response:", response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (this.$store.state.logoutFlag) this.papLogoutFlag = true;

      // if (
      //   !this.checkForChanges() &&
      //   !sessionStorage.getItem("sessionExpired")
      // ) {
      //   event.preventDefault();
      //   event.returnValue = "";
      //   return;
      // }
    },

    deleteEnrollment() {
      //if(this.formId && this.isSubmitted)
      let data = { enrollmentId: this.papEnrollmentId, formId: this.formId };
      this.isLoading = true;
      this.axios
        .post(`Enrollment/deleteEnrollment`, data)
        .then((res) => {
          this.close = true;
          this.isLoading = false;
          this.$router.push("/pre-enrollment-list");
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    getDocuments() {
      let documentFlag = true;
      this.getEnrollmentData(documentFlag);
      this.closeUploadPopup(false);
    },
    closeUploadPopup(val) {
      this.openUploadPopup = val;
    },

    onClose(key) {
      if (key == "papFormLockIn") {
        this.$router.push("/enrollments");
        this.openAlert = false;
      }
      if (key == "enrollmentSubmitted") {
        this.changeLockInStatus();
        this.openAlert = false;
      }
      if (key == "eligibilityInfo") {
        this.incrementStepper();
      }
      if (key == "preEnrollmentLockIn") {
        this.$router.push("/pre-enrollment-list");
        this.openAlert = false;
      }
      if (
        key == "saveClose" ||
        key == "closeAction" ||
        key == "beforeRouteLeave"
      ) {
        this.close = false;
      }
      this.openAlert = false;
    },

    onConfirm(key) {
      if (key == "preEnrollmentLockIn") {
        this.$router.push("/pre-enrollment-list");
      }
      if (key == "enrollmentSubmitted") {
        this.changeLockInStatus();
      }
      if (key == "saveClose" || key == "papFormLockIn") {
        this.$router.push("/enrollments");
      }
      if (key == "eligibilityInfo") {
        this.incrementStepper();
      }
      if (key == "closeAction") {
        this.isPageReloaded = false;
        !this.formId && this.isSubmitted
          ? this.changeLockInStatus()
          : this.deleteEnrollment();
        delete sessionStorage.papEnrollmentId;
      }
      if (key == "beforeRouteLeave") {
        this.isLoading = true;

        let data = {};
        if (this.formId && !this.isSubmitted) {
          data = {
            enrollmentId: this.papEnrollmentId,
            formId: this.formId,
          };
          this.axios
            .post(`Enrollment/deleteEnrollment`, data)
            .then((res) => {
              this.isLoading = false;
              if (!res.data.error) {
                this.keyFlag = true;
                this.$router.push(this.routePath);
                delete sessionStorage.papEnrollmentId;
              }
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
        } else {
          data = {
            enrollmentId: this.papEnrollmentId,
            isLockedIn: false,
          };
          this.axios
            .post(`Enrollment/getEnrollmentLockedInStatus`, data)
            .then((res) => {
              this.isLoading = false;
              if (!res.data.error) {
                this.keyFlag = true;
                this.$router.push(this.routePath);
                delete sessionStorage.papEnrollmentId;
              }
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        }
      }
      if (key == "delete") {
        let data = {
          enrollmentId: this.deleteProps.item.enrollmentId,
          fileName: this.deleteProps.item.fileName,
        };
        this.axios
          .delete(`Enrollment/deleteFromS3`, { data: { data } })
          .then((res) => {
            this.tableData.splice(this.deleteProps.index, 1);
            this.render++;
          });
      }
      this.openAlert = false;
    },
    onConfirmList(key) {
      if (key == "eligibilityInfo") {
        this.incrementStepper();
      }
      this.openAlertList = false;
    },
    onCloseList(key) {
      if (key == "eligibilityInfo") {
        this.incrementStepper();
      }
      this.openAlertList = false;
    },
    onInput(val, stepperIndex, fieldIndex) {
      let index = this.papForm[stepperIndex].findIndex(
        (i) => i.fieldIndex == fieldIndex
      );

      if (
        this.stepperArr[stepperIndex].id === "prescriptionAndDiagnosis" &&
        this.papForm[stepperIndex][index].key === "prescription"
      ) {
        this.disableFields(stepperIndex, val);
      }
    },

    // Prefilling is USResident checkbox on basis of patient state
    setUSResident(val) {
      let stepperIndex = this.stepperArr.findIndex(
        (i) => i.id === "eligibilityInformation"
      );
      let index = this.papForm[stepperIndex].findIndex(
        (i) => i.key === "usResident"
      );
      if (this.papForm[stepperIndex][index].value) {
        return;
      }
      if (val === "Others") {
        this.papForm[stepperIndex][index].value = "No";
      } else {
        this.papForm[stepperIndex][index].value = "Yes";
      }
    },

    // Disabling/Enabling fields onInput prescription on file checkbox
    disableFields(stepperIndex, flag) {
      if (!this.prescriptgrxCall) {
        if (flag === "Yes") {
          this.papForm[stepperIndex].forEach((item) => {
            if (
              item.key != "prescription" &&
              item.key != "icdCode" &&
              item.key != "diagnosis"
            ) {
              if (item.key != "productRequested") item.value = "";
              item.disabled = true;
            }
          });
        } else {
          this.papForm[stepperIndex].forEach((item) => {
            if (
              item.key != "prescription" &&
              item.key != "icdCode" &&
              item.key != "diagnosis"
            ) {
              item.disabled = false;
            }
          });
        }
      }
    },
    deleteDocuments(props) {
      this.deleteProps = props;
      this.alert = {
        status: "warning",
        statusText: "Are you sure you want to delete?",
        showCancelButton: true,
        key: "delete",
      };
      this.openAlert = true;
    },
    getBoundingBox(fieldKey) {
      if (
        this.faxBoundingBox[this.stepperArr[this.stepperActiveIndex].id]?.[
          fieldKey
        ] &&
        Object.keys(
          this.faxBoundingBox[this.stepperArr[this.stepperActiveIndex].id][
            fieldKey
          ]
        ).length > 0
      ) {
        let item =
          this.faxBoundingBox[this.stepperArr[this.stepperActiveIndex].id][
            fieldKey
          ];
        let Left = item.BoxLeft ? item.BoxLeft * 700 : item.Left * 700;
        let Top = item.BoxTop ? item.BoxTop * 750 + 55 : item.Top * 750 + 55;
        let Width = item.BoxWidth ? item.BoxWidth * 780 : item.Width * 780;
        let Height = item.BoxHeight ? item.BoxHeight * 860 : item.Height * 860;
        this.coordinates = {
          left: Left,
          top: Top,
          width: Width,
          height: Height,
        };
        this.changeImage = item.File_Name;
        this.change_image(this.changeImage);
        this.overlay = true;
      }
    },

    handlePreviewEvent(data) {
      this.previewImage = data.index;
    },

    change_image(image) {
      let arr = image.split("s");
      arr = arr[1].split(".");
      let index = arr[0];
      this.previewImage = parseInt(index);
    },

    // OnClick Submit button
    saveFormData() {
      let id = this.stepperArr[this.stepperActiveIndex].id;
      this.$refs[id][0].validate();
      if (this.formModels[id]) {
        this.isPageReloaded = false;
        this.close = true;
        let data = {};
        let endPoint = "";
        [data, endPoint] = this.getPayload();

        // Building payload
        this.createFormData(data.formData, this.stepperActiveIndex);
        this.isLoading = true;
        if (this.formId && !this.isSubmitted) {
          let preEnrollmentData = this.getFaxFormUpdate(data.formData);
          const promise1 = this.axios.post(
            `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/update-pre-enrollment-stepper?id=${this.formId}`,
            preEnrollmentData
          );
          const promise2 = this.axios.post(`Enrollment/${endPoint}`, data);
          Promise.all([promise1, promise2])
            .then((responses) => {
              this.isLoading = false;
              if (!responses[0].data.data.error && !responses[1].data.error)
                this.enrollmentSubmittedPopUp();
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        } else {
          this.axios
            .post(`Enrollment/${endPoint}`, data)
            .then((res) => {
              this.isLoading = false;
              if (!res.data.error) this.enrollmentSubmittedPopUp();
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        }
      }
    },

    enrollmentSubmittedPopUp() {
      this.alert = {
        status: "success",
        statusText: "Enrollment is submitted!",
        key: "enrollmentSubmitted",
        headerText: "Success",
      };
      this.openAlert = true;
    },

    // On click next button
    nextStepper() {
      let id = this.stepperArr[this.stepperActiveIndex].id;
      this.$refs[id][0].validate();
      let patientConditionalReqFlag = false;
      if (
        this.stepperArr[this.stepperActiveIndex].id === "patientInformation" &&
        !this.saveCloseFlag
      ) {
        let flag = this.checkConditionalReq(this.stepperActiveIndex);
        patientConditionalReqFlag = this.requiredPopup(
          flag,
          this.stepperActiveIndex
        );
      }
      if (this.formModels[id] && !patientConditionalReqFlag) {
        if (!this.$route.params.id) this.faxDeleteFlag = true;
        this.isPageReloaded = false;
        this.dirtFormFlag = false;
        let endPoint = "";
        let data = {};
        [data, endPoint] = this.getPayload();

        // Building payload
        this.createFormData(data.formData, this.stepperActiveIndex);

        if (
          this.stepperArr[this.stepperActiveIndex].id ===
            "patientInformation" &&
          data.formData?.patientInformation?.state
        ) {
          this.setUSResident(data.formData.patientInformation.state);
        }

        if (
          this.prescriptgrxCall &&
          (this.stepperArr[this.stepperActiveIndex].id ===
            "prescriptionAndDiagnosis" ||
            this.stepperArr[this.stepperActiveIndex].id ===
              "physicianInformation")
        ) {
          if (this.saveCloseFlag) {
            this.changeLockInStatus();
          } else {
            this.incrementStepper();
          }
        } else {
          this.isLoading = true;
          if (this.formId && !this.isSubmitted) {
            let preEnrollmentData = this.getFaxFormUpdate(data.formData);
            const promise1 = this.axios.post(
              `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/update-pre-enrollment-stepper?id=${this.formId}`,
              preEnrollmentData
            );
            const promise2 = this.axios.post(`Enrollment/${endPoint}`, data);
            Promise.all([promise1, promise2])
              .then((responses) => {
                this.isLoading = false;
                if (responses[0].data.data.error || responses[1].data.error) {
                  this.dirtFormFlag = true;
                  this.faxDeleteFlag = false;
                } else {
                  let res = responses[1];
                  this.continueStepper(res, data);
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.faxDeleteFlag = false;
                console.log(err);
                this.dirtFormFlag = true;
              });
          } else {
            this.axios
              .post(`Enrollment/${endPoint}`, data)
              .then((res) => {
                this.isLoading = false;
                if (res.data.error) {
                  this.dirtFormFlag = true;
                  this.faxDeleteFlag = false;
                } else {
                  this.continueStepper(res, data);
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.faxDeleteFlag = false;
                console.log(err);
                this.dirtFormFlag = true;
              });
          }
        }
      } else {
        this.saveCloseFlag = false;
      }
    },

    continueStepper(res, data) {
      // Updating statusCode and image urls
      this.statusCode = res.data.data.enrollmentStatus;
      if (
        JSON.stringify(this.urls) != JSON.stringify(res.data.data.faxImagesUrl)
      ) {
        this.urls = res.data.data.faxImagesUrl;
      }

      // Checking and updating missing fields
      if (res.data.data.missingStatus?.missingFields) {
        this.buildMissingArr(
          res.data.data.missingStatus.missingFields,
          this.stepperActiveIndex
        );
      } else {
        this.buildMissingArr({}, this.stepperActiveIndex);
      }
      this.updateMissingFlag();

      // if (
      //   this.stepperArr[this.stepperActiveIndex].id === "patientInformation"
      // ) {
      //   this.disablePatientDetail();
      // }

      // Checking for prescription grx flag
      if (
        this.stepperArr[this.stepperActiveIndex].id ===
          "prescriptionAndDiagnosis" &&
        res.data.data.enrollment.prescriptgrxCall
      ) {
        this.prescriptgrxCall = true;
        this.disablePrescription();
      }

      // Handling Merge and Previous data if exists
      if (this.stepperActiveIndex === 0 && !this.$route.params.id) {
        this.papEnrollmentId = res.data.data.enrollment.id;
        if (
          res.data.data.faxData != null &&
          Object.keys(res.data.data.faxData).length > 0
        ) {
          this.mergeFlag = true;
          this.prefillmergeData(res.data.data.faxData, res.data.data.documents);
        }

        this.$router.push(
          `/pap-form-fax/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
        );
        sessionStorage.setItem("papEnrollmentId", this.papEnrollmentId);
      }

      // Check if hcp created
      if (
        this.stepperArr[this.stepperActiveIndex].id ===
          "physicianInformation" &&
        res.data.data.enrollment.physicianInformation.id
      ) {
        this.hcpEdit = true;
      }

      // Check if patient eligibility in eligibilityInformation
      if (
        this.stepperArr[this.stepperActiveIndex].id === "eligibilityInformation"
      ) {
        let text = "";
        let list = "";
        let eligibilityStatus;
        if (res.data.data.eligibilityStatus) {
          [list, text, eligibilityStatus] = this.getPopupInfo(
            res.data.data.eligibilityStatus.eligibilityFields,
            res.data.data.eligibilityStatus.isEligible,
            data.formData.state,
            data.formData.usResident
          );
        }
        if (eligibilityStatus == "notEligible") {
          this.alertList = {
            type: "pre-defined-list",
            listArr: list,
            headerText: "Eligibility Status",
            statusText: text,
            key: "eligibilityInfo",
          };
          this.openAlertList = true;
        } else {
          this.alert = {
            type: "pre-defined-list",
            headerText: "Eligibility Status",
            statusText: text,
            status: eligibilityStatus,
            key: "eligibilityInfo",
          };
          this.openAlert = true;
        }
      } else {
        if (this.saveCloseFlag) {
          this.changeLockInStatus();
        } else {
          this.incrementStepper();
        }
      }
    },

    // disablePatientDetail() {
    //   let patientIndex = this.stepperArr.findIndex(
    //     (i) => i.id === "patientInformation"
    //   );
    //   let fName = this.papForm[patientIndex].findIndex(
    //     (i) => i.key === "firstName"
    //   );
    //   let lName = this.papForm[patientIndex].findIndex(
    //     (i) => i.key === "lastName"
    //   );
    //   let dob = this.papForm[patientIndex].findIndex(
    //     (i) => i.key === "dateOfBirth"
    //   );
    //   this.papForm[patientIndex][fName].disabled = true;
    //   this.papForm[patientIndex][lName].disabled = true;
    //   this.papForm[patientIndex][dob].disabled = true;
    // },

    getFaxFormUpdate(formData) {
      let data = {};
      let stepperId = this.stepperArr[this.stepperActiveIndex].id;
      if (stepperId === "patientInformation" && this.stepperActiveIndex === 0) {
        data[stepperId] = formData[stepperId];
      } else {
        if (stepperId === "eligibilityInformation") {
          data[stepperId] = JSON.parse(JSON.stringify(formData));
          delete data[stepperId].state;
        } else data[stepperId] = formData;
      }
      return data;
      // this.axios
      //   .post(
      //     `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/update-pre-enrollment-stepper?id=${this.formId}`,
      //     data
      //   )
      //   .then((res) => {
      //     if (res.data.error) {
      //       console.log(res.data.error);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },

    // To get payload structure
    getPayload() {
      let data = {};
      let endPoint = "";
      if (this.stepperActiveIndex == 0) {
        data = {};
        if (this.$route.params.id) {
          data = {
            programId: this.programId,
            userId: this.userId,
            userEmail: JSON.parse(sessionStorage.getItem("account")).username,
            source: "System",
            formData: {},
            enrollmentId: this.$route.params.id,
          };
        } else {
          data = {
            programId: this.programId,
            userId: this.userId,
            userEmail: JSON.parse(sessionStorage.getItem("account")).username,
            source: "System",
            formData: {},
            faxImages: this.faxUrls,
            faxBoundingBox: this.faxBoundingBox,
            faxId: this.faxId,
          };
        }
        endPoint = "patientInformationFax";
      } else if (this.stepperActiveIndex == 1) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        if (this.hcpEdit) data.edit = true;
        endPoint = "physicianInformationFax";
      } else if (this.stepperActiveIndex == 2) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        endPoint = "prescriptionAndDiagnosisFax";
      } else if (this.stepperActiveIndex == 3) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        endPoint = "eligibilityInformationFax";
      } else if (this.stepperActiveIndex == 4) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        endPoint = "insuranceFax";
      } else if (this.stepperActiveIndex == 5) {
        data = {
          userId: this.userId,
          formData: {},
          enrollmentId: this.$route.params.id,
        };
        if (this.formId) data.faxId = this.formId;
        endPoint = "signaturesFax";
      }
      return [data, endPoint];
    },

    createFormData(data, index) {
      if (this.stepperArr[index].id === "patientInformation" && index === 0) {
        this.stepperArr.forEach((item, stepperIndex) => {
          if (this.$route.params.id && stepperIndex > 0) {
            return;
          }
          data[item.id] = {};
          this.papForm[stepperIndex].forEach((i) => {
            if (i.type != "heading") {
              data[item.id][i.key] = i.value;
            }
          });
        });
      } else {
        this.papForm[index].forEach((i) => {
          if (i.type != "heading") {
            data[i.key] = i.value;
          }
        });
      }

      // Taking state form patient info for eligibility info payload
      if (
        this.stepperArr[this.stepperActiveIndex].id === "eligibilityInformation"
      ) {
        let stepperIndex = this.stepperArr.findIndex(
          (i) => i.id === "patientInformation"
        );
        let index = this.papForm[stepperIndex].findIndex(
          (i) => i.key === "state"
        );
        let val = this.papForm[stepperIndex][index].value;
        if (val === "Others") {
          data["state"] = "Others";
        } else {
          data["state"] = val;
        }
      }
    },

    checkConditionalReq(index) {
      let hPhone = this.papForm[index].findIndex((i) => i.key === "homePhone");
      let mPhone = this.papForm[index].findIndex(
        (i) => i.key === "mobilePhone"
      );
      let aPhone = this.papForm[index].findIndex(
        (i) => i.key === "advocateContactPhone"
      );
      return (
        !this.papForm[index][hPhone].value &&
        !this.papForm[index][mPhone].value &&
        !this.papForm[index][aPhone].value
      );
    },

    disablePrescriptionHCP() {
      let index = this.stepperArr.findIndex(
        (i) => i.id === "prescriptionAndDiagnosis"
      );
      let hcpIndex = this.stepperArr.findIndex(
        (i) => i.id === "physicianInformation"
      );
      this.papForm[index].forEach((i) => {
        i.disabled = true;
      });
      this.papForm[hcpIndex].forEach((i) => {
        i.disabled = true;
      });
    },

    requiredPopup(flag, index) {
      let reqField = [];
      this.papForm[index].forEach((i) => {
        if (i.required && !i.value) {
          reqField.push(i.label);
        }
      });
      if (reqField.length > 0 || flag) {
        var str = "<ul>";
        reqField.forEach((label) => {
          str += "<li>" + label + "</li>";
        });
        if (flag) {
          str += "<li>" + "Home Phone/Mobile Phone/Advocate Phone" + "</li>";
        }
        str += "</ul>";
        this.alertList = {
          type: "list",
          listArr: str,
          headerText: "Missing Fields",
          statusText:
            "You must fill in all the required fields (*) to proceed with the enrollment.",
          status: "warning",
          key: "requiredPopup",
        };
        this.openAlertList = true;
      } else {
        return false;
      }
      return true;
    },

    getPopupInfo(data, status, state, usResident) {
      var list = "<ul>";
      let eligibilityStatus;
      if (this.enrollmentDetails && this.enrollmentDetails.missingSteppers) {
        this.enrollmentDetails.missingSteppers.forEach((item) => {
          list += "<li>" + item + "</li>";
        });
      }
      let text = "";
      if (status === "No") {
        if (data) {
          let patientInsuredIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("patientInsured")) return true;
          });
          let patientUninsuredIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("patientUninsured")) return true;
          });
          let incomeValidationIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("incomeValidation")) return true;
          });
          let ageIndex = data.findIndex((i) => {
            if (i.hasOwnProperty("age")) return true;
          });
          if (
            !data[patientInsuredIndex].patientInsured ||
            !data[patientUninsuredIndex].patientUninsured
          ) {
            list +=
              "<li>" +
              "Can not determine patient insurance information." +
              "</li>";
          } else if (
            data[patientInsuredIndex].status === "No" ||
            data[patientUninsuredIndex].status === "No"
          ) {
            list += "<li>" + "Patient is insured." + "</li>";
          }

          if (!data[incomeValidationIndex].incomeValidation) {
            list += "<li>" + "Income validation failed." + "</li>";
          }
          if (data[ageIndex].status === "No") {
            list += "<li>" + "Patient is under 18 yrs of age." + "</li>";
          }
        } else {
          if (state === "Others" && usResident === "Yes") {
            list +=
              "<li>" +
              "Patient is not a US resident as State for the patient is entered as Others." +
              "</li>";
          } else {
            list += "<li>" + "Patient is not a US resident." + "</li>";
          }
        }
        text = "The patient is not eligible due to the following reasons.";
        eligibilityStatus = "notEligible";
        // list += '<li>'+ "The patient is not eligible due to the following reasons." + '</li>';
      } else if (status === "Yes") {
        text = "Patient is eligible for Enrollment!";
        eligibilityStatus = "success";
        // list += '<li>'+ "Patient is eligible for Enrollment!" + '</li>';
      } else if (status === "Unknown") {
        eligibilityStatus = "warning";
        if (state === "") {
          text =
            "Eligibility could not be verified due to the non-availability of data (State for the patient is not entered)!";
          // list += '<li>'+ "Eligibility could not be verified due to the non-availability of data (State for the patient is not entered)!" + '</li>';
        } else {
          text =
            "Eligibility could not be verified due to the non-availability of data!";
          // list += '<li>'+ "Eligibility could not be verified due to the non-availability of data!" + '</li>';
        }
        list += "</ul>";
      }
      return [list, text, eligibilityStatus];
    },

    prefillmergeData(data, docs) {
      this.tableData = docs;
      this.render++;
      this.stepperArr.forEach((i, index) => {
        if (data.mergedData?.[i.id] && data.previousData?.[i.id]) {
          this.papForm[index].forEach((field) => {
            if (data.mergedData[i.id][field.key] != undefined) {
              field.value = data.mergedData[i.id][field.key];
            }
            if (data.previousData[i.id][field.key] != undefined) {
              field.previousValue = data.previousData[i.id][field.key];
            }
          });
        }
      });
    },

    // Jumping to next stepper after onClick continue button
    incrementStepper() {
      this.stepperActiveIndex += 1;
      this.stepperArr[this.stepperActiveIndex].active = true;
      this.$router.push(
        `/pap-form-fax/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
      );
      this.dirtFormFlag = true;
      // Set stepperForm which will be used to check for unsaved data
      if (this.stepperActiveIndex > this.stepperForm.stepperId) {
        this.setStepperForm();
      }
    },

    // OnClick Save & Close Button
    // saveClose() {
    //   this.isPageReloaded = false;
    //   this.saveCloseFlag = true;
    //   this.close = true;
    //   let fName, lName, dob;
    //   if (
    //     this.stepperArr[this.stepperActiveIndex].id === "patientInformation"
    //   ) {
    //     fName = this.papForm[this.stepperActiveIndex].findIndex(
    //       (i) => i.key === "firstName"
    //     );
    //     lName = this.papForm[this.stepperActiveIndex].findIndex(
    //       (i) => i.key === "lastName"
    //     );
    //     dob = this.papForm[this.stepperActiveIndex].findIndex(
    //       (i) => i.key === "dateOfBirth"
    //     );
    //   }
    //   if (
    //     this.stepperArr[this.stepperActiveIndex].id === "patientInformation" &&
    //     (!this.papForm[this.stepperActiveIndex][fName].value ||
    //       !this.papForm[this.stepperActiveIndex][lName].value ||
    //       !this.papForm[this.stepperActiveIndex][dob].value)
    //   ) {
    //     this.alert = {
    //       status: "warning",
    //       statusText: "Are you sure you want to close PAP form?",
    //       key: "saveClose",
    //       headerText: "First Name, Last Name and DOB are required!",
    //     };
    //     this.openAlert = true;
    //   } else {
    //     this.nextStepper();
    //   }
    // },

    // On click privious button
    priviousStepper() {
      let id = this.stepperArr[this.stepperActiveIndex].id;
      this.$refs[id][0].validate();
      if (this.formModels[id]) {
        this.isPageReloaded = false;
        this.dirtFormFlag = false;
        if (
          this.stepperActiveIndex === this.stepperForm.stepperId &&
          this.checkForChanges()
        ) {
          this.stepperArr[this.stepperActiveIndex].active = false;
          this.stepperActiveIndex -= 1;
          this.setStepperForm();
        } else {
          this.stepperArr[this.stepperActiveIndex].active = false;
          this.stepperActiveIndex -= 1;
        }
        this.$router.push(
          `/pap-form-fax/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
        );
        id = this.stepperArr[this.stepperActiveIndex].id;
        this.$refs[id][0].validate();
      }
      this.dirtFormFlag = true;
    },

    // Event listner for on click in stepper
    updateStepperActiveIndex(index) {
      //this.stepperActiveIndex = index;
    },

    // Fax extracted Values
    getExtractedValue() {
      this.loadingCount++;
      this.axios
        .post(
          `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/fax-enrollment?id=${this.faxId}`
        )
        .then((res) => {
          if (res.data.data.error) {
            --this.loadingCount;
            this.close = true;
            this.$router.push(`/pre-enrollment-list`);
          } else {
            this.enrollmentData = res.data.data;
            if (
              this.stepperArr.length > 0 &&
              this.papForm.length === this.stepperArr.length
            ) {
              this.prefillFields();
            }
            --this.loadingCount;
            if (res.data.data.is_locked_in) {
              this.close = true;
              this.alert = {
                status: "warning",
                statusText: "Some other user is processing this form",
                key: "preEnrollmentLockIn",
                headerText: "Alert!!",
              };
              this.openAlert = true;
            }
          }
        })
        .catch((err) => {
          --this.loadingCount;
          console.log(err);
          this.close = true;
          this.$router.push(`/pre-enrollment-list`);
        });
    },

    // Handle post api for getting prefill enrollment data
    getEnrollmentData(documentFlag = false) {
      if (!documentFlag) {
        this.loadingCount++;
      }
      this.axios
        .post(`Enrollment/searchEnrollmentById/`, {
          id: `${this.$route.params.id}`,
        })
        .then((res) => {
          if (res.data.error) {
            if (!documentFlag) {
              --this.loadingCount;
            }
          } else {
            this.tableData = res.data.data.documents;
            this.render++;
            if (!documentFlag) {
              this.enrollmentData = res.data.data;
              if (
                this.stepperArr.length > 0 &&
                this.papForm.length === this.stepperArr.length
              ) {
                this.prefillFields();
              }
              --this.loadingCount;
            }
          }
        })
        .catch((err) => {
          if (documentFlag) {
            this.isLoading = false;
          } else --this.loadingCount;
          console.log(err);
        });
    },

    // Handle GET api call for form fields and stepper, and prefilling papForm
    getPapFormFax() {
      this.loadingCount++;
      this.axios
        .get(
          `Program/getProgramFields?programId=${sessionStorage.getItem(
            "programId"
          )}`
        )
        .then((res) => {
          if (res.data.error) {
            --this.loadingCount;
          } else {
            this.$store.commit("setForm", {
              papFormTitle: JSON.parse(
                JSON.stringify(res.data.data[0].programImageUrl)
              ),
              papForm: JSON.parse(JSON.stringify(res.data.data[0].papForm)),
              papFormFax: JSON.parse(
                JSON.stringify(res.data.data[0].papFormFax)
              ),
            });
            this.getFields(res.data.data[0].papFormFax);

            if (Object.keys(this.enrollmentData).length > 0) {
              this.prefillFields();
            } else {
              this.setStepperForm();
            }
            --this.loadingCount;
          }
        })
        .catch((err) => {
          --this.loadingCount;
          console.log(err);
        });
    },

    getFields(programFields) {
      this.stepperArr = programFields.steppers.sort(
        (a, b) => a.stateId - b.stateId
      );
      if (
        this.$route.params.id &&
        (this.$route.params.stepperId > this.stepperArr.length ||
          this.$route.params.stepperId < 1)
      ) {
        this.stepperActiveIndex = 0;
        this.$router.push(
          `/pap-form-fax/${this.stepperActiveIndex + 1}/${this.papEnrollmentId}`
        );
      }
      for (let i = 0; i <= this.stepperActiveIndex; i++) {
        this.stepperArr[i].active = true;
      }

      for (let i = 0; i <= this.stepperActiveIndex; i++) {
        this.stepperArr[i].active = true;
      }
      this.stepperArr.forEach((i, index) => {
        this.missingFieldArr.push([]);
        this.formModels[i.id] = false;
        this.papForm.push(
          programFields[i.id].sort((a, b) => {
            return a.fieldIndex - b.fieldIndex;
          })
        );
      });
      this.setStepperForm();
    },

    prefillFields() {
      if (!this.$route.params.id && this.faxId) {
        this.urls = this.enrollmentData.images_url;
        this.faxBoundingBox = this.enrollmentData.hovering_values;
        this.faxUrls = this.enrollmentData.images;
        this.enrollmentData = this.enrollmentData.pap_object;
        this.faxDataCorrection();
      } else if (this.$route.params.id) {
        // if (
        //   this.stepperArr[this.stepperActiveIndex].id === "patientInformation"
        // ) {
        //   this.disablePatientDetail();
        // }
        if (this.enrollmentData.prescriptgrxCall) {
          this.disablePrescriptionHCP();
          this.prescriptgrxCall = true;
        }
        if (this.enrollmentData.isSubmitted) this.isSubmitted = true;
        this.faxBoundingBox = this.enrollmentData.faxBoundingBox;
        this.urls = this.enrollmentData.faxImagesUrl;
        this.tableData = this.enrollmentData.documents
          ? this.enrollmentData.documents
          : [];
        this.render++;
      }

      if (Object.keys(this.enrollmentData).length > 0) {
        this.stepperArr.forEach((i, index) => {
          if (this.$route.params.id) {
            this.statusCode =
              this.enrollmentData.statusCode >= 0
                ? this.enrollmentData.statusCode
                : null;

            if (this.enrollmentData.physicianInformation.id) {
              this.hcpEdit = true;
            }
          }

          if (i.id === "patientInformation") {
            if (this.enrollmentData[i.id]?.state) {
              this.setUSResident(this.enrollmentData[i.id].state);
            }
          }

          // Prefilling data into fields
          this.papForm[index].forEach((field) => {
            if (this.enrollmentData[i.id]?.[field.key] != undefined) {
              field.value = this.enrollmentData[i.id][field.key];
            }

            if (
              i.id === "prescriptionAndDiagnosis" &&
              field.key === "prescription"
            ) {
              this.disableFields(index, field.value);
            }
          });
        });
      }

      this.setStepperForm();
      if (
        this.papEnrollmentId &&
        this.enrollmentData.missingStatus?.missingFields
      ) {
        this.buildMissingArr(this.enrollmentData.missingStatus.missingFields);
      }
      this.updateMissingFlag();
    },

    // Correcting fax extract data for checkbox
    faxDataCorrection() {
      // if (this.enrollmentData.insurance) {
      //   if (this.enrollmentData.insurance.multiplePlans === "No") {
      //     this.enrollmentData.insurance.multiplePlans = "";
      //   }
      //   this.enrollmentData.insurance.multiplePlans =
      //     this.enrollmentData.insurance.multiplePlans === "No"
      //       ? ""
      //       : this.enrollmentData.insurance.multiplePlans;
      //   this.enrollmentData.insurance.insuranceAttached =
      //     this.enrollmentData.insurance.insuranceAttached === "No"
      //       ? ""
      //       : this.enrollmentData.insurance.insuranceAttached;
      // }

      if (this.enrollmentData.signatures) {
        this.enrollmentData.signatures.physicianSignature = this.enrollmentData
          .signatures.physicianSignature
          ? "Yes"
          : "";
        this.enrollmentData.signatures.papAcknowledgementSignature = this
          .enrollmentData.signatures.papAcknowledgementSignature
          ? "Yes"
          : "";
        this.enrollmentData.signatures.patientHIDSignature = this.enrollmentData
          .signatures.patientHIDSignature
          ? "Yes"
          : "";
        this.enrollmentData.signatures.legalRepresentativeSignature = this
          .enrollmentData.signatures.legalRepresentativeSignature
          ? "Yes"
          : "";
      }

      let strength = "";
      let stepperIndex = this.stepperArr.findIndex(
        (i) => i.id === "prescriptionAndDiagnosis"
      );
      if (this.enrollmentData.prescriptionAndDiagnosis?.strength) {
        let text = this.enrollmentData.prescriptionAndDiagnosis.strength
          .replace("mg", "")
          .replace(/ /g, "");

        let strengthIndex = this.papForm[stepperIndex].findIndex(
          (i) => i.key === "strength"
        );
        this.papForm[stepperIndex][strengthIndex].items.forEach((i) => {
          if (i.split("-")[0].includes(text)) {
            strength = i;
            this.enrollmentData.prescriptionAndDiagnosis.strength = i;
            return;
          }
        });
      }

      if (strength && this.enrollmentData.prescriptionAndDiagnosis?.quantity) {
        let prescriptionIndex = this.papForm[stepperIndex].findIndex(
          (i) => i.key === "prescription"
        );
        this.papForm[stepperIndex][prescriptionIndex].value = "No";
      }
    },

    buildMissingArr(missingFields, index = null) {
      if (index != null && index >= 0) {
        this.missingFieldArr[index] = [];
        if (missingFields[this.stepperArr[index].id]?.length > 0) {
          missingFields[this.stepperArr[index].id].forEach((i) => {
            this.missingFieldArr[index].push(i);
          });
        }
      } else {
        for (const key in missingFields) {
          if (missingFields.hasOwnProperty(key)) {
            index = this.stepperArr.findIndex((i) => i.id === key);
            missingFields[key].forEach((i) => {
              this.missingFieldArr[index].push(i);
            });
          }
        }
      }
      //this.updateMissingFlag();
    },

    updateMissingFlag() {
      for (let i = 0; i < this.missingFieldArr.length; i++) {
        if (this.missingFieldArr[i].length > 0) {
          this.missingFlag = true;
          return;
        }
      }
      this.missingFlag = false;
    },

    // Getting breakpoint for col on the basis of screen inner width
    colBreakpoint() {
      this.breakpointFlag = window.innerWidth <= 1520 ? true : false;
    },

    //Function to handle addition of file to upload
    onFileChange(e) {
      if (!this.templateData.length) this.getDocumentsName();
      this.openUploadPopup = true;

      // let file = e.target.files || e.dataTransfer.files;
      // this.filesToUpload.push(file[0]);
    },
    getDocumentsName() {
      this.axios.get(`/Shared/getConfig?payload=fileTypes`).then((res) => {
        this.templateData = res.data.data.map((prop) => {
          return prop.fileType;
        });
      });
    },

    //Function to handle deletion of file to upload
    removeFile(index) {
      this.filesToUpload.splice(index, 1);
    },

    // Return boolean for condtional class rendering
    classForLabel(index, itemKey, type) {
      return (
        type != "radio" ||
        (type === "radio" &&
          this.stepperArr[index].id === "prescriptionAndDiagnosis" &&
          (itemKey === "prescription" || itemKey === "dosageIncrease"))
      );
    },

    // Handle get api call for states
    getStates() {
      this.allStates = this.$store.state.configState;
      this.allStates.forEach((item) => {
        if (item.name === "Others") {
          this.states.push(item.name);
        } else {
          this.states.push(item.abbreviation);
        }
      });
    },

    getEnrollmentStatus(data) {
      let allStatus = this.$store.state.configStatus;
      let index = allStatus.findIndex((i) => i.statusCode == data);
      if (index > -1) this.enrollmentStatus = allStatus[index].statusType;
    },

    // Function to handle close button onclick
    closeAction() {
      this.close = true;
      if ((this.formId && !this.isSubmitted) || !this.checkForChanges()) {
        this.closePopUp();
      } else {
        this.isPageReloaded = false;
        if (this.$route.params.id) this.changeLockInStatus();
      }
    },
    closePopUp() {
      let text;
      if (this.formId && !this.isSubmitted) {
        text =
          "Are you sure you want to close the form - any changes made in the data may not be saved.";
      } else {
        text =
          "If you Cancel now, your data would not be saved. Do you still want to continue?";
      }
      this.alert = {
        status: "warning",
        statusText: text,
        key: "closeAction",
        headerText: "Data will not be saved!",
        showCancelButton: true,
      };
      this.openAlert = true;
    },

    papFormLockIn() {
      if (!this.$route.params.id) {
        return;
      }
      this.loadingCount++;
      let data = {
        enrollmentId: this.$route.params.id,
        userEmail: JSON.parse(sessionStorage.getItem("account")).username,
      };
      this.axios
        .post(`Enrollment/getEnrollmentLockedInStatus`, data)
        .then((res) => {
          --this.loadingCount;
          if (res.data.error) {
            this.dirtFormFlag = false;
            this.$router.push("/enrollments");
          } else {
            if (
              res.data.data.isLockedIn &&
              JSON.parse(sessionStorage.getItem("account")).username !==
                res.data.data.userEmail
            ) {
              this.close = true;
              this.alert = {
                status: "warning",
                statusText:
                  "Another user is currently working on this PAP form!",
                key: "papFormLockIn",
                headerText: "Alert!!",
              };
              this.openAlert = true;
            }
          }
        })
        .catch((err) => {
          --this.loadingCount;
          console.log(err);
          this.dirtFormFlag = false;
          this.$router.push("/enrollments");
        });
    },

    // used for checkong the lochIn status
    changeLockInStatus() {
      this.isLoading = true;
      let data = {
        enrollmentId: this.papEnrollmentId,
        isLockedIn: false,
      };
      this.axios
        .post(`Enrollment/getEnrollmentLockedInStatus`, data)
        .then((res) => {
          this.isLoading = false;
            this.dirtFormFlag = false;
            this.close = true;
            this.$router.push("/enrollments");
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    // Fuction to set stepperForm which is used to check for unsaved data
    setStepperForm() {
      this.stepperForm.stepperId = this.stepperActiveIndex;
      this.stepperForm.papFormStep = this.papForm[this.stepperActiveIndex].map(
        (a) => ({ ...a })
      );
    },

    // Fuction to check for unsaved data
    checkForChanges() {
      if (
        JSON.stringify(this.papForm[this.stepperForm.stepperId]) ===
        JSON.stringify(this.stepperForm.papFormStep)
      ) {
        return true;
      } else {
        return false;
      }
    },

    // Function to determine if a field is mandatory or not to stop the flow of steppers
    isRequired(index, fieldKey) {
      return (
        this.stepperArr[index].id === "patientInformation" &&
        (fieldKey === "firstName" ||
          fieldKey === "lastName" ||
          fieldKey === "dateOfBirth")
      );
    },
  },

  computed: mapState([
    "configState",
    "currentUser",
    "configStatus",
    "logoutFlag",
    "isAdmin", 
    "isAgent", 
    "isOrderEntry"
  ]),

  beforeDestroy() {
    this.$store.commit("sideNavToggle", true);
  },

  created() {
    if (sessionStorage.getItem("faxRefresh")) {
      sessionStorage.removeItem("faxRefresh");
      this.$router.push("/pre-enrollment-list");
      return;
    }
    if (
      !this.$route.params.stepperId ||
      (!this.$route.params.id && !this.faxId) ||
      (this.faxId && this.$route.params.stepperId != 1)
    ) {
      this.$router.push(`/enrollments`);
    }
    this.stepperActiveIndex = this.$route.params.stepperId - 1;
    this.programId = sessionStorage.getItem("programId");
    if (this.$route.params.id) {
      this.papEnrollmentId = this.$route.params.id;
      if (!this.formId) this.papFormLockIn();
    }
    this.$store.commit("sideNavToggle", false);
    this.getStates();

    if (Object.keys(this.$store.state.papFormFax).length > 0) {
      let papFormData = JSON.parse(
        JSON.stringify(this.$store.state.papFormFax)
      );
      this.getFields(papFormData);
    } else {
      this.getPapFormFax();
    }
    if (Object.keys(this.$store.state.papEnrollmentData).length > 0) {
      this.enrollmentData = this.$store.state.papEnrollmentData;
      if (Object.keys(this.$store.state.papFormFax).length > 0) {
        this.prefillFields();
      }
      this.$store.commit("deleteEnrollmentData");
    } else if (!this.$route.params.id && this.faxId) {
      this.getExtractedValue();
    } else if (this.$route.params.id) {
      this.getEnrollmentData();
    }

    window.addEventListener("resize", this.colBreakpoint);
    this.colBreakpoint();
    //this.$store.dispatch("loggedIn", true);
  },
};
</script>

<style lang="scss" scoped>
.pb--3x3 {
  padding-bottom: 9px !important;
}
.col-header {
  color: white;
  background-color: #0c0c33;
  padding: 10px;
  font-size: 1.5em;
}
.right-col-container {
  background-color: white;
  margin-left: 1.5rem;
  width: 100%;
  min-width: 500px;
}
.asterix-required-field {
  color: #e52643;
  font-weight: 500;
}
.papFormStepName {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a73e9;
}
.field-label {
  width: 35%;
  font-weight: bolder;
}
.form--field {
  width: 65%;
  min-width: 100px;
}
.previous--form--field {
  width: 40%;
  color: #9e9ead;
  white-space: nowrap;
  max-width: 350px;
  overflow: hidden;
}
.field-label-heading {
  width: 35%;
  font-weight: bolder;
}
.previous--field--heading {
  width: 40%;
  max-width: 350px;
  font-weight: bolder;
}
.form--field--heading {
  width: 65%;
  font-weight: bolder;
}
.input-field {
  width: 100%;
  max-width: 350px;
}
.radioWithDropdown {
  width: 35%;
  padding-right: 12px;
}
.countrycode-field {
  width: 100px;
}
.button-v-card .v-btn {
  width: 158px;
  height: 48px;
}
.filesToUpload {
  color: #1a73e9;
}
.closeIcon {
  color: #1a73e9;
}
.upload-button {
  cursor: default !important;
}
.pricious--icon {
  color: #1b1b1b;
  height: 2rem;
  width: 2rem;
}
.mdi-arrow-left-circle-outline::before {
  font-size: 3rem !important;
}
.form--border {
  border: 1px solid #ceced6;
  border-radius: 4px;
}
.enrollment-status-code {
  color: #00cea2;
  font-size: 1.15rem;
}
.fields--heading {
  flex-wrap: nowrap;
}
.fax--image--container {
  position: sticky !important;
  top: 67px;
}
.fax-content-container {
  flex-wrap: nowrap;
}
@media screen and (max-width: 1480px) {
  .fax-content-container {
    flex-wrap: wrap;
  }
  .right-col-container {
    margin-left: 0px;
    margin-top: 1.5rem;
  }
}
</style>
