<template>
  <v-app>
    <v-select
      class="dropdown--field"
      :class="{ disabledField: disabled }"
      :items="items"
      :label="placeholder"
      :disabled="disabled"
      :rules="required ? [(v) => !!v || 'This is mandatory field'] : []"
      :hide-details="hideDetails"
      v-model="value"
      solo
      dense
      outlined
      @change="getDropdown"
    ></v-select>
  </v-app>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    item: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      default: false,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    getDropdown(data) {
      this.$emit("on-change", data);
      this.$emit("input", data);
    },
  },
  watch: {
    item(data) {
      this.value = data;
    },
    items(data) {
      if (data > 0 && this.item) {
        if (!data.includes(this.item)) {
          this.$emit("on-change", "");
          this.$emit("input", "");
        }
      }
    },
  },
  created() {
    if (this.items.length > 0 && this.item) {
      if (!this.items.includes(this.item)) {
        this.$emit("on-change", "");
        this.$emit("input", "");
      }
    }
    this.value = this.item;
  },
};
</script>

<style lang="scss" scoped>
.dropdown--field {
  border-radius: 4px;
}
</style>
