var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"700px"}},[(_vm.isLoading)?_c('Loader'):_vm._e(),_c('div',[_c('h1',{staticClass:"header-css"},[_vm._v(_vm._s(_vm.getTitle))]),(_vm.template)?_c('editor',{attrs:{"api-key":"no-api-key","initialValue":_vm.template,"init":{
        height: 700,
        menubar: false,
        statusbar: false,
        content_style: `div { font-size: 16px !important; }`,
        setup(ed) {
          ed.on('change', function () {
            _vm.template = ed.getContent();
          });
        },
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
      }}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end mt--5"},[_c('v-btn',{staticClass:"btn--color--white btn-css",attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"btn--color--blue btn-css ml--3",attrs:{"depressed":""},on:{"click":_vm.sendLetter}},[_vm._v("Send")])],1),(_vm.openAlert)?_c('custom-alert',{attrs:{"status":"success","statusText":"Fax successfully sent!"},on:{"confirm":_vm.onConfirm,"close":_vm.onConfirm}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }