<template>
  <div>
    <div class="patient-list-container">
      <loader v-if="isLoading" />

      <div class="d-flex justify-space-between flex-wrap mb--5">
      <div class="mr--3">
        <div class="page-tile">Users</div>
        <span class="underline-grey"></span>
      </div>
      <div class="d-flex mt--3">
        
        <InputField
          class="mr-2 seachField"
          placeholder="Search by Email or Last Name"
          v-model="searchData"
          type="text"
          hide-details="auto"
          :searchBox="true"
          @on-enter="searchUser(true)"
        />

        <v-btn class="mr-2 btn--color--blue" style="
                padding: 20px;
                font-size: 16px;
                text-transform: capitalize;
              " elevation="0"
              @click="searchUser(true)">
              Search
            </v-btn>
            <v-btn class="btn--color--blue" style="
                padding: 20px;
                font-size: 16px;
                text-transform: capitalize;
              " elevation="0" @click="addUser(`add`)">
              <v-icon medium>mdi-plus</v-icon>
              ADD
            </v-btn>

            <!-- <v-btn
              @click="getData"
              style="
                padding: 1.25rem 2.5rem;
                color: white;
                background-color: #0057ed;
                text-transform: capitalize;
                border-radius: 10px;
              "
              elevation="0"
            >
              Search
            </v-btn> -->
            </div>
      </div>

      <v-card class="card-padding enrollment-list">
        <data-table class="data-table" :key="render" :headers="headers" style="border: #ceced6 solid 1px" :tableData="tableData" :itemsPerPage="itemsPerPage" :pageNo="pageNo" :totalCount="tableDataCount"
          @page-change="onPageChange"
          @item-change="onItemsPerPageChange"
        >
          <template slot="columns" slot-scope="props">
            <td>{{ props.item.lastName }}</td>
            <td>{{ props.item.firstName }}</td>
            <td>{{ props.item.workEmail }}</td>
            <td>{{ getRoles(props.item.roles) }}</td>
            <td class="d-flex align-center">
                <v-icon depressed color="#0057ED" style="font-size: 18px; cursor: pointer !important" class="actions--css"
                  @click="addUser(`edit`, props.item)">mdi-pencil</v-icon>
                <v-switch dense flat :false-value="true" :true-value="false" v-model="props.item.isDeleted" hide-details="auto" @change="switchAction(props.item)" class="actions--css"></v-switch>
            </td>
          </template>
        </data-table>
      </v-card>
    </div>
    <add-edit-role-popup v-if="openPopup" :title="actionTitle" :userData="prefillUserData" @close="closePopup" @userAddedEdit="getData"></add-edit-role-popup>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import AddEditRolePopup from "@/components/AddEditRolePopup.vue";
import Loader from "@/components/Loader.vue";
import InputField from "../components/InputField.vue";
export default {
  data() {
    return {
      title: "Add",
      isLoading: false,
      searchData: "",
      switch1: false,
      render: 0,
      actionTitle: "",
      tableData: [],
      headers: [
        {
          text: "Last Name",
          value: "lastname",
          width: "20%",
          sortable: false,
        },
        {
          text: "First Name",
          value: "firstname",
          width: "20%",
          sortable: false,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: false,
          width: "20%",
        },
        {
          text: "Role",
          value: "role",
          width: "12.5%",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          width: "15%",
          sortable: false,
        },
      ],
      openPopup: false,
      skip: 0,
      pageNo: 1,
      itemsPerPage: 10,
      tableDataCount: 0,
      prefillUserData: {}
    };
  },
  components: {
    DataTable,
    AddEditRolePopup,
    Loader,
    InputField,
  },

  watch: {
    searchData(data) {
      if(!data){
        this.getData();
      }
    },
  },
  

  methods: {
    getData() {
      this.isLoading = true;
      let params = {};
      params.limit = this.itemsPerPage;
      params.offset = this.pageNo - 1 < 0 ? 0 : this.pageNo - 1;
      this.axios
        .get(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-users`, {
          params,
        })
        .then((response) => {
          this.tableData = response.data.data;
          this.tableDataCount = response.data.count;
          this.render++;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getRoles(data){
        let roleName = "";
        data.forEach((item, index) => {
          if(index > 0) roleName += ", " + item.name;
          else roleName += item.name;
        });
      return roleName;
    },
    searchUser(searchFlag = false){
      if(searchFlag && this.pageNo != 1){
        this.pageNo = 1;
        return;
      }
      if(!this.searchData){        
        this.getData();
        return;
      }
      this.isLoading = true;
      let params = {};
      params.user = this.searchData;
      params.limit = this.itemsPerPage;
      params.offset = this.pageNo - 1 < 0 ? 0 : this.pageNo - 1;
      this.axios
        .get(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/search-user`, {
          params,
        })
        .then((response) => {
          this.tableData = response.data.data;
          this.tableDataCount = response.data.count;
          this.render++;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    addUser(val, data) {
      this.openPopup = true;
      if (val === "edit") {
        this.actionTitle = "Edit";
        this.prefillUserData = data;
      }
      if (val === "add") {
        this.actionTitle = "Add";
      }
    },
    switchAction(data){
      this.isLoading = true;
      let params = {};
      params.id = data._id.$oid;
      params.isDeleted = data.isDeleted;
      this.axios
        .delete(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/manage-users`, {
          params,
        })
        .then((response) => {
          this.isLoading = false;
        })
        .catch((err) => {
          data.isDeleted = !data.isDeleted;
          this.isLoading = false;
          console.log(err);
        });
    },
    switchValue(item) {
      return !item.isDeleted;
    },
    onPageChange(data){
      this.pageNo = data;
      if(this.searchData) this.searchUser();
      else this.getData();
    },
    onItemsPerPageChange(data){
      this.itemsPerPage = data;
      if(this.searchData) this.searchUser();
      else this.getData();
    },      
    closePopup(val) {
      this.openPopup = val;
    },
    deleteUser(index) {
      this.tableData.splice(index, 1);
    },
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped>
form {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  margin: 0 0 30px;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #9e9ead;
}

/* form.searchbox {
  border: none;
  padding: 0;
} */

.seachField{
  width: 385px !important;
}
.actions--css{
  margin: auto 4px !important;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
