<template>
  <div>
    <loader v-if="isLoading" />
    <custom-alert
      v-show="lockInStatus"
      headerText="Alert !!"
      status="warning"
      statusText="Some other user is processing this form"
      @confirm="lockInStatus = false"
      @close="lockInStatus = false"
    />
    <custom-alert
      v-show="openAlert"
      headerText="Alert !!"
      status="warning"
      statusText="Are you sure you want to delete this record?"
      :showCancelButton="true"
      @confirm="onConfirm()"
      @close="openAlert = false"
    />
    <custom-alert
      v-show="confirmUnextracted"
      headerText="Alert !!"
      status="warning"
      statusText="Are you sure you want to process this record?"
      :showCancelButton="true"
      @confirm="onConfirm2()"
      @close="confirmUnextracted = false"
    />
    <div>
      <div class="page-tile">Unextracted List</div>
      <span class="underline-grey"></span>
      <v-card class="card-padding enrollment-list">
        <v-row>
          <v-col cols="12">
            <data-table
              class="data-table"
              :key="reRender"
              :headers="headers"
              :tableData="tableData"
              :totalCount="tableDataCount"
              :itemsPerPage="itemsPerPage"
              :pageNo="pageNo"
              @page-change="onPageChange"
              @item-change="onItemsPerPageChange"
              style="border: #ceced6 solid 1px"
            >
              <template slot="columns" slot-scope="props">
                <td>
                  <!-- props.item?.datetime.$date | getDateTime(props.item.datetime.$date) -->
                  <span>{{ props.item.date }}, </span>
                  <span style="color: #9e9ead">{{ props.item.time }}</span>
                </td>
                <!-- style="color: #0057ed" -->
                <td>
                  {{ props.item.formname }}
                </td>
                <td>{{ props.item.source }}</td>
                <td>{{ props.item.enrollment_list.fax }}</td>
                <td>
                  <!-- <span class="mr-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="onDelete(props.item)"
                          >mdi-delete-outline</v-icon
                        >
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </span> -->
                  <span class="mr-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="onClickAction(props.item)"
                          >mdi-file-document-plus-outline</v-icon
                        >
                      </template>
                      <span>Create Enrollment</span>
                    </v-tooltip>
                  </span>
                  <span v-if="!props.item.loading">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                          <v-icon @click="showPDFPreview(props.item)"
                            >mdi-eye-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Preview</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="20"
                      width="3"
                    >
                    </v-progress-circular>
                  </span>
                </td>
              </template>
            </data-table>
          </v-col>
          <v-col>
            <v-dialog
              v-model="showpreview"
              width="35vw"
              style="max-height: 100% !important"
              persistent
              no-click-animation
            >
              <v-card height="100vh">
                <v-card-title class="preview_card"
                  ><v-spacer></v-spacer><span>Preview</span><v-spacer></v-spacer
                  ><v-icon
                    color="white"
                    size="20"
                    style="margin-right: 2%"
                    @click="
                      {
                        showpreview = false;
                        previewReset += 1;
                      }
                    "
                    >mdi-window-close</v-icon
                  ></v-card-title
                >
                <v-carousel height="95vh" :key="previewReset">
                  <v-carousel-item
                    class="image-item"
                    v-for="(item, i) in img_url"
                    :key="i"
                    :src="item"
                  ></v-carousel-item>
                </v-carousel>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
      <v-row justify="end">
        <v-btn
          outlined
          @click="onClose"
          class="mb mr-3 btn--color--white"
          style="
            padding: 20px 60px;
            font-size: 16px;
            font-weight: bold;
            text-transform: capitalize;
          "
          elevation="0"
        >
          Close
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<script>
import DataTable from "../components/DataTable.vue";
import Loader from "../components/Loader.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import moment from "moment";
export default {
  components: { DataTable, Loader, CustomAlert },
  data: () => ({
    headers: [
      {
        text: "Date & Time",
        value: "datetime",
        width: "20%",
        sortable: false,
      },
      {
        text: "File Name",
        value: "filename",
        width: "40%",
        sortable: false,
      },

      {
        text: "Source",
        value: "source",
        width: "12.5%",
        sortable: false,
      },
      {
        text: "Fax No.",
        value: "fax",
        width: "12.5%",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        width: "15%",
        sortable: false,
      },
    ],
    tableData: [],
    skip: 0,
    previewReset: 0,
    pageNo: 1,
    tableDataCount: 0,
    itemsPerPage: 10,
    isSelecting: false,
    reRender: 0,
    img_url: [],
    formItem: {},
    formItem2: {},
    openAlert: false,
    confirmUnextracted: false,
    showpreview: false,
    isLoading: false,
    lockInStatus: false,
  }),
  methods: {
    onClickAction(item){
      this.formItem2 = item;
      this.confirmUnextracted = true;
    },
    async onConfirm2() {
      this.confirmUnextracted = false;
      this.isLoading = true;
      let payload = {};
      let params = {};
      params.id = this.formItem2._id.$oid;
      payload.id = this.formItem2._id.$oid;
      payload.lockedInStatus = true;
      const res1 = this.axios.post(
        `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-lock-in-status`,
        payload
      );
      const res2 = this.axios.get(
        `${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-images-url`,
        { params }
      );

      Promise.all([res1, res2]).then((responses) => {
        if (responses[0].data.data) {
          this.lockInStatus = true;
        } else {
          this.formItem2["images_url"] = responses[1].data.data;
          this.$store.commit("setEnrollmentData", this.formItem2);
          this.$router.push({
            path: "/pap-form-fax/1",
            query: { fax_id: `${this.formItem2._id.$oid}` },
          });
        }
        this.isLoading = false;
      })
      .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getDateTime() {
      this.tableData.forEach((e) => {
        let Date = e.datetime.$date.split("T")[0];
        let formattedDate = moment(Date).format("MM/DD/YYYY");
        let formattedTime = moment(e.datetime.$date).format("hh:mm A");
        e.date = formattedDate;
        e.time = formattedTime;
      });
    },
    onConfirm() {
      this.openAlert = false;
      this.isLoading = true;
      let params = {};
      params.id = this.formItem._id.$oid;
      params.name = this.formItem.formname;
      this.axios
      .delete(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/form-queue`, {
          params,
        })
        .then((response) => {
          if (response.data.data == "Deleted") {
            this.getData();
          }
        });
    },
    async onDelete(item) {
      this.formItem = item;
      this.openAlert = true;
    },
    onClose() {
      this.$router.back();
    },
    onPageChange(data) {
      this.pageNo = data;
      this.skip = (data - 1) * this.itemsPerPage;
      this.getData();
    },
    onItemsPerPageChange(data) {
      this.itemsPerPage = data;
      this.getData();
    },

    async showPDFPreview(item) {
      this.isLoading = true;
      let params = {};
      params.id = item._id.$oid;
      await this.axios
        .get(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/get-images-url`, {
          params,
        })
        .then((response) => {
          this.img_url = response.data.data;
          this.showpreview = true;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    async getData() {
      this.isLoading = true;
      let params = {};
      params.limit = this.itemsPerPage;
      params.queue_type = "unprocessed";
      params.offset = this.pageNo - 1 < 0 ? 0 : this.pageNo - 1;
      await this.axios
        .get(`${process.env.VUE_APP_EXTRACT_APP_BASE_URL}/form-queue`, {
          params,
        })
        .then((response) => {
          this.tableDataCount = response.data.count;
          this.tableData = response.data.data;
          this.tableData.map((q) => ({ ...q, date: "", time: "" }));
          this.getDateTime();
          this.reRender++;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>
<style scoped>
form {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  margin: 0 0 30px;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #9e9ead;
}

form.searchbox {
  border: none;
  padding: 0;
}
.preview_card {
  background-color: #0057ed;
  padding: 0px !important;
  color: white;
  height: 5vh;
}
input.searchbox {
  border: 1px solid #555;
  border-radius: 10px;
  width: inherit;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}
.tableheaders {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>
