<template>
  <v-app>
    <v-text-field
      v-model="value"
      class="input--field"
      :class="{ disabledField: disabled, searchbox: searchBox }"
      :label="label"
      :type="fieldType"
      :placeholder="placeholder"
      :outlined="outline"
      :rules="
        type === 'partialPassword'
          ? partialPasswordRules
          : type === 'date'
          ? required
            ? requiredDateRules
            : dateRules
          : required
          ? requiredFieldRules
          : fieldRules
      "
      dense
      :maxlength="maxlength"
      :prepend-inner-icon="this.type === 'phone' ? icon : this.searchBox? searchIcon:noIcon"
      :disabled="disabled"
      :hide-details="hideDetails"
      @input="onInput"
      @keyup.enter="onEnter"
    ></v-text-field>
  </v-app>
</template>

<script>
import moment from "moment";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    outline: {
      type: Boolean,
      default: true,
    },
    reGex: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 100000,
    },
    hideDetails: {
      default: false,
    },
    searchBox: {
      default: false,
    }
  },
  data() {
    return {
      value: "",
      regExp: new RegExp(this.reGex),
      icon: "mdi-numeric-positive-1",
      searchIcon: "mdi-magnify",
      noIcon: "",
      fieldType: "text",
      fieldRules: [(v) => !v || this.regExp.test(v) || this.errorMsg],
      requiredFieldRules: [
        (v) => !v || this.regExp.test(v) || this.errorMsg,
        (v) => !!v || "This is mandatory field",
      ],
      dateRules: [(v) => !v || this.dateTest(v) || this.errorMsg],
      requiredDateRules: [
        (v) => !v || this.dateTest(v) || this.errorMsg,
        (v) => !!v || "This is mandatory field",
      ],
      partialPasswordRules: [
        (v) => !v || this.regExp.test(this.fullSSN) || this.errorMsg,
      ],
      requiredRules: [(v) => !!v || "This is mandatory field"],
      ssnFirstFive: "",
      secondHalf: "",
      fullSSN: "",
    };
  },
  methods: {
    onInput() {
      if (this.type === "partialPassword") {
        this.detectChanges();
        if (this.value.length > 8) {
          this.secondHalf = this.value.substring(5);
          this.value = "*****" + this.secondHalf;
        } else {
          this.secondHalf = "";
          //this.value = this.value.replace(/./g, "*");
        }
        this.fullSSN = this.ssnFirstFive + this.secondHalf;
        this.$emit("on-input", this.fullSSN);
        this.$emit("input", this.fullSSN);
      } else {
        this.$emit("on-input", this.value);
        this.$emit("input", this.value);
      }
    },
    detectChanges() {
      let num = this.value.length <= 5 ? this.value.length : 5;
      let i;
      for (i = 0; i < num; i++) {
        if (this.value[i] != "*") {
          this.ssnFirstFive = this.ssnFirstFive.substring(0, i) + this.value[i];
        }
      }
      this.ssnFirstFive = this.ssnFirstFive.substring(0, i);
    },
    onEnter() {
      this.$emit("on-enter");
    },
    // Check for valid date
    dateTest(value) {
      let date = moment(value, "MM/DD/YYYY", true);
      let todayDate = new Date();
      todayDate = moment(todayDate, "MM/DD/YYYY", true);
      return (
        date.isValid() && date.isBefore(todayDate) && date.year() != "0000"
      );
    },
  },
  watch: {
    defaultValue(data) {
      if (this.type != "partialPassword") {
        this.value = data;
      }
    },
  },
  created() {
    if (
      this.type != "phone" &&
      this.type != "date" &&
      this.type != "partialPassword"
    ) {
      this.fieldType = this.type;
    }
    this.value = this.defaultValue;
    if (this.type === "partialPassword") {
      this.onInput();
    }
  },
};
</script>

<style lang="scss" scoped>
.input--field {
  border-radius: 4px;
}
</style>
