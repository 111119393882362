<template>
  <div class="patient-list-container">
    <loader v-if="isLoading" />
    <div class="d-flex justify-space-between">
      <div>
        <div class="page-tile">Enrollments</div>
        <span class="underline-grey"></span>
      </div>
      <v-btn
        depressed
        class="btn-padding btn--color--blue"
        @click="$router.push('/pap-form')"
        >+ New Enrollment</v-btn
      >
    </div>
    <v-card class="card-padding pa--3 pl--0">
      <v-form class="d-flex align-center" ref="form">
        <div class="filter-left-content d-flex">
          <div class="d-flex filter-input-width">
            <span class="margin--title font-weight-500 text-no-wrap"
              >Last Name</span
            >
            <input-field
              class="width--field"
              placeholder="Enter Last Name"
              :value="form.lastName"
              :maxlength="35"
              @on-input="onInput($event, 'lastName')"
              @on-enter="searchData"
            />
          </div>
          <div class="d-flex filter-input-width">
            <span class="margin--title font-weight-500 text-no-wrap"
              >Date Of Birth</span
            >
            <input-field
              class="width--field"
              placeholder="MM/DD/YYYY"
              :value="form.dateOfBirth"
              @on-input="onInput($event, 'dob')"
              @on-enter="searchData"
            />
          </div>
          <div class="d-flex filter-input-width">
            <span class="margin--title font-weight-500 text-no-wrap"
              >Last Accessed</span
            >
            <dropdown
              class="box--shadow--none width--field"
              :items="getUserName(allUsersInfo)"
              placeholder="Select User"
              @on-change="onInput($event, 'userId')"
            />
          </div>
          <div class="d-flex filter-input-width">
            <span class="margin--title font-weight-500 status-width"
              >Status</span
            >
            <v-select
              class="box--shadow--none width--field dropdown--field"
              :items="getDropdownItems($store.state.configStatus)"
              placeholder="Select Status"
              v-model="statusCodeObj"
              outlined
              multiple
              chips
              deletable-chips
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              @change="onInput($event, 'status')"
            >
              <template v-slot:selection="{ item, index }">
                <div v-if="isSelectedValueSingle">
                  {{ statusCodeObj[0] }}
                </div>
                <v-chip v-else-if="index === 0">
                  <span class="chip-text">{{ statusCodeObj[0] }}</span>
                  <v-icon class="ml-1" @click.stop="remove(item)"
                    >mdi-close-circle</v-icon
                  >
                </v-chip>
                <span v-if="index === 1" class="chip-number">
                  +{{ statusCodeObj.length - 1 }}
                </span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="d-flex align-center flex-column mt--3 ml--3">
          <v-btn
            depressed
            class="search-padding btn--color--blue"
            @click="searchData"
            >Search</v-btn
          >
          <a href="javascript:void(0)" class="mt--3" @click="resetForm"
            >Clear All</a
          >
        </div>
      </v-form>
    </v-card>

    <v-card class="d-flex justify-end align-center mt--7">
      <div class="d-flex align-baseline filter-input-width">
            <span class="font-weight-500 text-no-wrap mr--6"
              >Sort By</span
            >
            <dropdown
              :key="reRender"
              class="box--shadow--none width--field"
              :items="filterMethods"
              :hideDetails="true"
              placeholder="Select Column"
              @on-change="onInput($event, 'sortBy')"
            />
          </div>
          <div class="d-flex align-baseline filter-input-width">
            <span class="font-weight-500 text-no-wrap mr--6"
              >Sort Method</span
            >
            <dropdown
              :key="reRender"
              class="box--shadow--none width--field"
              :items="filterMethod"
              :hideDetails="true"
              placeholder="Select Method"
              @on-change="onInput($event, 'sortMethod')"
            />
          </div>
    </v-card>
  
    <v-card class="card-padding">
      <data-table
        class="data-table"
        :key="reRender"
        :headers="headers"
        :tableData="tableData"
        :totalCount="tableDataCount"
        :itemsPerPage="itemsPerPage"
        :pageNo="pageNo"
        @page-change="onPageChange"
        @item-change="onItemsPerPageChange"
      >
        <template slot="columns" slot-scope="props">
          <td>
            {{
              props.item?.createdDate | getCreatedDate(props.item.createdDate)
            }}
          </td>
          <td>{{ props.item.patientInformation.lastName }}</td>
          <td>{{ props.item.patientInformation.firstName }}</td>
          <td>{{ props.item.patientInformation.dateOfBirth }}</td>
          <td>
            {{ props.item?.physicianInformation?.firstName }}
            {{ props.item?.physicianInformation?.lastName }}
          </td>
          <td>{{ getStatusByID(props.item.statusCode) }}</td>
          <td>{{ props.item.source }}</td>
          <td>{{ getUser(props.item.userId) }}</td>
          <td class="d-flex align-center">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="menu-bar">
                  <v-icon
                    class="cursor--pointer"
                    style="color: white; font-size: 18px"
                  >
                    mdi-dots-vertical</v-icon
                  >
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-if="props.item.source === 'Manual'"
                  class="cursor--pointer action--list"
                  @click="onClickAction('Copy', props.item)"
                >
                  <v-list-item-title>
                    <img src="../assets/images/copy2.svg" alt="" />
                    <span class="ml--2">Copy</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="cursor--pointer action--list"
                  v-if="!props.item.isComplete"
                  @click="onClickAction('Edit', props.item)"
                >
                  <v-list-item-title>
                    <img src="../assets/images/edit2.svg" alt="" />
                    <span class="ml--2">Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="cursor--pointer action--list"
                  @click="onClickAction('Follow Up', props.item)"
                >
                  <v-list-item-title>
                    <img src="../assets/images/followUp.svg" alt="" />
                    <span class="ml--2">Follow Up</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="props.item.isComplete"
                  class="cursor--pointer action--list"
                  @click="onClickAction('Preview', props.item)"
                >
                  <v-list-item-title>
                    <img src="../assets/images/previewEye.svg" alt="" />
                    <span class="ml--2">Preview</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </template>
      </data-table>
    </v-card>
    <!-- v-if="previewDialog" -->
    <preview
      v-if="previewDialog"
      @close="close"
      :previewItem="previewItem"
    ></preview>
  </div>
</template>

<script>
import InputField from "../components/InputField.vue";
import DataTable from "../components/DataTable.vue";
import dropdown from "../components/DropDown.vue";
import Loader from "../components/Loader.vue";
import Preview from "../components/Preview.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  components: {
    InputField,
    DataTable,
    dropdown,
    Loader,
    Preview,
  },

  filters: {
    getCreatedDate(date) {
      let Date = date.split("T")[0];
      let formattedDate = moment(Date).format("MM/DD/YYYY");
      return formattedDate;
    },
    // showLastFourOnly(data) {
    //   return data.replace(/.(?=.{4})/g, "*");
    // },
  },

  data: () => ({
    actionValue: "",
    reRender: 0,
    statusCodeObj: [],
    filterMethod: ["Ascending", "Descending"],
    form: {
      lastName: "",
      dateOfBirth: "",
      userId: null,
      statusCode: [],
      programId: sessionStorage.getItem("programId"),
    },
    isLoading: false,
    headers: [
      {
        text: "Created Date",
        value: "createdDate",
        sortable: false,
      },
      {
        text: "Last Name",
        value: "lastName",
        sortable: false,
      },
      {
        text: "First Name",
        align: "start",
        value: "firstName",
        sortable: false,
      },
      {
        text: "Date Of Birth",
        value: "dob",
        sortable: false,
      },
      {
        text: "HCP",
        value: "hcp",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Source",
        value: "source",
        sortable: false,
      },
      {
        text: "Last Accessed By",
        value: "lastAccessedBy",
        sortable: false,
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
      },
    ],
    tableData: [],
    filterMethods: ["First Name", "Last Name", "Created Date", "HCP", "Status"],
    allUsersInfo: [],
    allStatus: [],
    skip: 0,
    pageNo: 1,
    itemsPerPage: 10,
    tableDataCount: 0,
    sort: {
      name: "",
      method: "",
    },
    previewDialog: false,
    previewItem: {},
  }),

  computed: {
    ...mapState(["configStatus"]),
    isSelectedValueSingle() {
      return (
        typeof this.statusCodeObj == "number" ||
        typeof this.statusCodeObj === "string" ||
        this.statusCodeObj.length === 1
      );
    },
  },
  methods: {
    onClickAction(action, item) {
      if (action == "Edit") this.editAction(item);
      if (action == "Copy") this.copyAction(item);
      if (action == "Follow Up") this.followAction(item);
      if (action == "Preview") this.previewAction(item);
    },
    onPageChange(data) {
      this.pageNo = data;
      this.skip = (data - 1) * this.itemsPerPage;
      this.getPatientList(this.form);
      this.sort = {};
    },
    onItemsPerPageChange(data) {
      this.itemsPerPage = data;
      this.getPatientList(this.form);
      this.sort = {};
    },
    getPatientList(data) {
      this.isLoading = true;
      let apiData = {
        payload: data,
        skip: this.skip,
        limit: this.itemsPerPage,
      };
      this.axios
        .post(`Enrollment/searchEnrollment`, apiData)
        .then((res) => {
          this.isLoading = false;
          this.tableData = res.data.data.enrollments;
          this.tableDataCount = res.data.data.count;
          this.reRender++;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    searchData() {
      if(this.pageNo != 1){
        this.pageNo = 1;
        return;
      }
      this.getPatientList(this.form);
    },

    resetForm() {
      let isEmpty = Object.values(this.form).every(
        (x) => x === null || x === ""
      );
      if (!isEmpty) {
        this.$refs.form.reset();
        this.form.statusCode = [];
        this.form.userId = null;
        this.statusCodeObj = [];
        this.sort = {};
        if(this.pageNo != 1){
          this.pageNo = 1;
          return;
        }
        this.getPatientList(this.form);
      }
    },
    remove(item) {
      this.statusCodeObj = this.statusCodeObj.filter((p) => {
        return p !== item;
      });
      let arr = this.allStatus.filter((prop) => {
        return prop.statusType.includes(item);
      });
      this.form.statusCode = this.form.statusCode.filter((i) => {
        return i !== arr[0].statusCode;
      });
    },
    onInput(value, key) {
      if (key == "lastName") this.form.lastName = value;
      if (key == "dob") this.form.dateOfBirth = value;

      if (key == "status") {
        let index = this.allStatus.filter((i) => value.includes(i.statusType));
        if (index.length) {
          this.form.statusCode = index.map((i) => {
            return i.statusCode;
          });
        } else {
          this.form.statusCode = [];
        }
      }

      if (key == "userId") {
        let index = this.allUsersInfo.findIndex(
          (i) => i.firstName + " " + i.lastName == value
        );
        if (index > -1) this.form.userId = this.allUsersInfo[index].id;
      }

      if (key == "sortBy" || key == "sortMethod") {
        function sortBy(field, type) {
          return function (a, b) {
            if (type == "Ascending") {
              if (Object.keys(a[field]).length == 0) return -1;
              if (Object.keys(b[field]).length == 0) return 1;
              if (
                a[field].firstName.toLowerCase() >
                b[field].firstName.toLowerCase()
              )
                return 1;
              else if (
                a[field].firstName.toLowerCase() <
                b[field].firstName.toLowerCase()
              )
                return -1;
            } else if (type == "Descending") {
              if (Object.keys(a[field]).length == 0) return 1;
              if (Object.keys(b[field]).length == 0) return -1;
              if (
                a[field].firstName.toLowerCase() >
                b[field].firstName.toLowerCase()
              )
                return -1;
              else if (
                a[field].firstName.toLowerCase() <
                b[field].firstName.toLowerCase()
              )
                return 1;
            }
            return 0;
          };
        }
        if (key == "sortBy") {
          this.sort.name = value;
        } else if (key == "sortMethod") this.sort.method = value;
        if (this.sort.method == "Ascending") {
          if (this.sort.name == "First Name")
            return this.tableData.sort((a, b) =>
              a.patientInformation.firstName.localeCompare(
                b.patientInformation.firstName
              )
            );
          if (this.sort.name == "Last Name")
            return this.tableData.sort((a, b) =>
              a.patientInformation.lastName.localeCompare(
                b.patientInformation.lastName
              )
            );
          if (this.sort.name == "Created Date")
            return this.tableData.sort((a, b) =>
              a.createdDate.localeCompare(b.createdDate)
            );
          if (this.sort.name == "HCP")
            return this.tableData.sort(
              sortBy("physicianInformation", this.sort.method)
            );
          if (this.sort.name == "Status")
            return this.tableData.sort((a, b) =>
              this.getStatusByID(a.statusCode).localeCompare(
                this.getStatusByID(b.statusCode)
              )
            );
        } else if (this.sort.method == "Descending") {
          if (this.sort.name == "First Name")
            return this.tableData.sort((a, b) =>
              b.patientInformation.firstName.localeCompare(
                a.patientInformation.firstName
              )
            );
          if (this.sort.name == "Last Name")
            return this.tableData.sort((a, b) =>
              b.patientInformation.lastName.localeCompare(
                a.patientInformation.lastName
              )
            );
          if (this.sort.name == "Created Date")
            return this.tableData.sort((a, b) =>
              b.createdDate.localeCompare(a.createdDate)
            );
          if (this.sort.name == "HCP")
            return this.tableData.sort(
              sortBy("physicianInformation", this.sort.method)
            );
          if (this.sort.name == "Status")
            return this.tableData.sort((a, b) =>
              this.getStatusByID(b.statusCode).localeCompare(
                this.getStatusByID(a.statusCode)
              )
            );
        }
      }
    },

    getDropdownItems(statusObj) {
      return statusObj.map((i) => i.statusType);
    },

    getStatusByID(id) {
      let index = this.allStatus.findIndex((i) => i.statusCode === id);
      if (index > -1) return this.allStatus[index].statusType;
    },

    editAction(item) {
      this.$store.commit("setEnrollmentData", item);
      if (item.source === "System") {
        this.$router.push(`/pap-form-fax/1/${item.id}`);
      } else if (item.source === "Manual") {
        this.$router.push(`/pap-form/1/${item.id}`);
      }
      sessionStorage.setItem("papEnrollmentId", item.id);
    },

    followAction(item) {
      this.$store.commit("setEnrollmentData", item);
      this.$router.push(`/follow-up/${item.id}`);
    },

    previewAction(item) {
      this.previewDialog = true;
      this.previewItem = item;
    },

    close(val) {
      this.previewDialog = val;
    },

    copyAction(item) {
      //let index = this.tableData.findIndex((i) => i.id === id);
      let data = {};
      data.patientInformation = item.patientInformation;
      data.prescriptionAndDiagnosis = {};
      data.eligibilityInformation = item.eligibilityInformation;
      data.insuranceAndSignatures = {};
      data.physicianInformation = item.physicianInformation;
      data.program = item.program;
      delete data.physicianInformation.id;
      data.enrollmentId = item.id;
      this.$store.commit("setEnrollmentData", data);
      this.$router.push(`/pap-form/`);
    },

    getAllUsers() {
      this.axios.get(`User/getAllUsers`).then((res) => {
        this.allUsersInfo = res.data.data;
      });
    },

    getUserName(userObj) {
      return userObj.map((i) => i.firstName + " " + i.lastName);
    },

    getUser(id) {
      let index = this.allUsersInfo.findIndex((i) => i.id == id);
      if (index > -1)
        return (
          this.allUsersInfo[index].firstName +
          " " +
          this.allUsersInfo[index].lastName
        );
    },
    getSelectedValue() {
      if (this.$route.params.id) {
        let id = this.$route.params.id.toLowerCase();
        if (id === "incomplete") {
          this.setValueOfStatus("Incomplete");
        } else if (id === "eligible") {
          this.setValueOfStatus("Eligible");
        } else if (id === "noteligible") {
          this.setValueOfStatus("Not eligible");
        } else if (id === "approved") {
          this.setValueOfStatus("Approved");
        } else if (id === "denied") {
          this.setValueOfStatus("Denied");
        }
      }
    },
    setValueOfStatus(value) {
      let arr = this.allStatus.filter((item) => {
        return item.statusType.includes(value);
      });
      this.form.statusCode = arr.map((i) => {
        return i.statusCode;
      });
      this.statusCodeObj = arr.map((item) => {
        return item.statusType;
      });
    },
  },

  watch: {
    configStatus: {
      immediate: true,
      handler() {
        this.allStatus = this.$store.state.configStatus;
      },
    },
  },

  created() {
    this.getSelectedValue();
    // if (!this.form.statusCode.length) {
    //   this.getPatientList(this.form);
    // } else {
    //   this.getPatientList({ ...this.form });
    // }
    this.getPatientList(this.form);
    this.getAllUsers();
  },
};
</script>

<style lang="scss" scoped>
.width--field {
  width: 68%;
}
.patient-list-container {
  padding: 20px;
}
.btn-padding {
  padding: 20px 16px !important;
}
.search-padding {
  padding: 21px 35px !important;
}
.card-padding {
  margin-top: 16px;
  border: 1px solid rgba(173, 173, 173, 0.5);
  border-radius: 4px;
}
.icon-css {
  width: 24px;
  height: 24px;
  background: rgba(26, 115, 233, 0.1);
  border-radius: 4px;
}
.margin--title {
  margin: 8px 8px 8px 1rem;
}
.pl--0 {
  padding-left: 0 !important;
}
.action--list:hover {
  background: #e8e8e8;
}
.theme--light.v-list-item:hover::before {
  opacity: 0.12;
}
.menu-bar {
  padding: 0 10px;
  width: 1.4rem;
  background: #0057ed;
  border-radius: 50%;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-left-content {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.filter-input-width {
  width: 350px;
}
.margin--title {
  width: 30%;
}

@media screen and (min-width: 1852px) {
  .status-width {
    width: 16% !important;
  }

  @media screen and (min-width: 1852px) {
    .status-width {
      width: 16% !important;
    }
  }
  .dropdown--field {
    border-radius: 5px;
  }
}
</style>
