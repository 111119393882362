<template>
  <v-dialog v-model="dialog">
    <loader v-if="loading" />
    <v-card v-if="!loading">
      <v-card-title style="background-color: #0057ed">
        <span
          style="color: white; font-weight: 800 !important; font-size: 1.2em"
          >Enrollment Preview</span
        >
        <v-spacer></v-spacer>
        <v-icon color="white" @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-form>
          <div v-for="(stepper, i) in stepperArr" :key="i">
            <fieldset class="my-4" style="border-radius: 15px; padding: 0.5%">
              <legend
                style="
                  color: black;
                  font-size: 1.8em;
                  font-weight: 600;
                  margin-left: 30px;
                  padding: 5px;
                "
              >
                {{ stepper.name }}
              </legend>
              <v-row>
                <v-col cols="6">
                  <v-row
                    v-for="index in range(0, Math.ceil(papForm[i].length / 2))"
                    :key="index"
                  >
                    <h3
                      class="mb--3"
                      v-if="papForm[i][index].type === 'heading'"
                    >
                      {{ papForm[i][index].label }}
                    </h3>

                    <v-row v-else>
                      <v-col cols="4">
                        <span class="form--label">{{
                          papForm[i][index].label
                        }}</span>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6">
                        <span class="form--value">
                          {{
                            papForm[i][index].value
                              ? papForm[i][index].value
                              : "Unknown"
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-row
                    v-for="index in range(
                      Math.ceil(papForm[i].length / 2),
                      papForm[i].length
                    )"
                    :key="index"
                  >
                    <h3
                      class="mb--3"
                      v-if="papForm[i][index].type === 'heading'"
                    >
                      {{ papForm[i][index].label }}
                    </h3>

                    <v-row v-else>
                      <v-col cols="4">
                        <span class="form--label">{{
                          papForm[i][index].label
                        }}</span>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6">
                        <span class="form--value">
                          {{
                            papForm[i][index].value
                              ? papForm[i][index].value
                              : "Unknown"
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  stepper.id === 'prescriptionAndDiagnosis' &&
                  tableData.length > 0
                "
              >
                <v-col md="6" xl="6" sm="6" class="ml--3">
                  <data-table
                    :headers="headers"
                    :tableData="tableData"
                    :hideDefaultFooter="tableData.length <= 10? true:false"
                    :key="reRender"
                  >
                    <template slot="columns" slot-scope="props">
                      <td>
                        {{ props.index + 1 }}
                      </td>
                      <td>
                        {{ props.item.fileName }}
                      </td>
                      <td>
                        {{ props.item.fileType }}
                      </td>
                      <!-- <td>
                  <v-icon
                    color="#0057ED"
                    style="font-size: 18px; cursor: pointer !important"
                    >mdi-eye</v-icon
                  >
                  <v-icon
                    color="#0057ED"
                    style="font-size: 18px; cursor: pointer !important"
                    @click="deleteDocuments(props.index)"
                    >mdi-delete</v-icon
                  >
                </td> -->
                    </template>
                  </data-table>
                </v-col>
              </v-row>
            </fieldset>
          </div>
          <v-row justify="end">
            <v-btn class="primary" @click="closeDialog">Close Preview</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../components/Loader.vue";
import DataTable from "../components/DataTable.vue";

export default {
  components: {
    Loader,
    DataTable,
  },
  props: {
    previewItem: { type: Object, default: {} },
  },
  data() {
    return {
      dialog: true,
      stepperArr: [],
      stepperActiveIndex: 1,
      papForm: [],
      fieldsKey: [],
      fieldIndex: [],
      loading: false,
      headers: [
        {
          text: "S.No",
          value: "id",
        },
        {
          text: "Form Name",
          value: "formName",
        },
        {
          text: "Form Type",
          value: "formType",
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
      tableData: [],
      reRender: 0,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    range(startIndex, endIndex) {
      let arr = [];
      for (let i = startIndex; i < endIndex; i++) {
        arr.push(i);
      }
      return arr;
    },
    getPapForm() {
      this.loading = true;
      this.axios
        .get(
          `Program/getProgramFields?programId=${sessionStorage.getItem(
            "programId"
          )}`
        )
        .then((res) => {
          this.loading = false;
          this.$store.commit("setForm", {
            papForm: JSON.parse(JSON.stringify(res.data.data[0].papForm)),
            papFormFax: JSON.parse(JSON.stringify(res.data.data[0].papFormFax)),
          });
          let programFields = {};
          if (this.previewItem.source === "Manual") {
            programFields = res.data.data[0].papForm;
          } else if (this.previewItem.source === "System") {
            programFields = res.data.data[0].papFormFax;
          }
          this.getFields(programFields);
        });
    },

    getFields(programFields) {
      this.stepperArr = programFields.steppers.sort(
        (a, b) => a.stateId - b.stateId
      );
      this.stepperArr.forEach((i) => {
        this.papForm.push(
          programFields[i.id].sort((a, b) => {
            return a.fieldIndex - b.fieldIndex;
          })
        );
      });

      this.tableData = this.previewItem.documents
        ? this.previewItem.documents
        : [];
      this.reRender++;

      this.stepperArr.forEach((i, index) => {
        let heading;

        // Prefilling data into fields
        this.papForm[index].forEach((field) => {
          if (
            i.id === "insuranceAndSignatures" &&
            Object.keys(this.previewItem[i.id]).length > 0
          ) {
            if (field.type === "heading") {
              heading = field.key;
            } else if (
              this.previewItem[i.id][heading][field.key] != undefined
            ) {
              field.value = this.previewItem[i.id][heading][field.key];
            }
          } else if (this.previewItem[i.id][field.key] != undefined) {
            field.value = this.previewItem[i.id][field.key];
          }
          if (field.type === "partialPassword" && field.value) {
            field.value = "*****" + field.value.slice(5);
          }
        });
      });
    },
  },
  created() {
    if (Object.keys(this.$store.state.papForm).length > 0) {
      let papFormData = {};
      if (this.previewItem.source === "Manual") {
        papFormData = JSON.parse(JSON.stringify(this.$store.state.papForm));
      } else if (this.previewItem.source === "System") {
        papFormData = JSON.parse(JSON.stringify(this.$store.state.papFormFax));
      }
      this.getFields(papFormData);
    } else {
      this.getPapForm();
    }
    // console.log(this.stepperArr);
  },
};
</script>

<style lang="scss" scoped>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
  margin-bottom: 0px !important;
}
.form--label {
  font-weight: 600;
  font-size: medium;
}
.form--value {
  font-size: medium;
}
</style>
