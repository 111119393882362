import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/css/helper.scss";
import "./assets/css/styles.scss";
import store from "./store";
import axios from "./services/axios-helper";
import VueAxios from "vue-axios";
import Auth from './services/auth';

Vue.use(VueAxios, axios);
Vue.prototype.$msal = Auth;

Vue.config.productionTip = false;

export const EventBus = new Vue();

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
