import { render, staticRenderFns } from "./UploadPopup.vue?vue&type=template&id=86959aa4&scoped=true&"
import script from "./UploadPopup.vue?vue&type=script&lang=js&"
export * from "./UploadPopup.vue?vue&type=script&lang=js&"
import style0 from "./UploadPopup.vue?vue&type=style&index=0&id=86959aa4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86959aa4",
  null
  
)

export default component.exports