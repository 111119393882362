import Vue from "vue";
import Vuex from "vuex";
import axios from "../services/axios-helper";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sideNavBar: true,
    isLoggedIn: true,
    papEnrollmentData: {},
    configStatus: [],
    configState: [],
    currentUser: [],
    isAlert: false,
    letterNotes: "",
    papForm: {},
    papFormFax: {},
    papFormTitle: "",
    papLetterData: {},
    logoutFlag: false,
    isAdmin: false,
    isAgent: false,
    isOrderEntry: false,
    isRoleUpdated: false
  },
  getters: {},
  mutations: {
    sideNavToggle(state, payload) {
      state.sideNavBar = payload;
    },
    loggedIn(state, payload){
      state.isLoggedIn = payload;
    },
    setEnrollmentData(state, payload){
      state.papEnrollmentData = payload;
    },
    setEnrollmentForLetter(state, payload){
      state.papLetterData = payload;
    },
    deleteEnrollmentData(state){
      state.papEnrollmentData = {};
    },
    getStatusConfig(state, payload){
      state.configStatus = payload;
    },
    getStateConfig(state, payload){
      state.configState = payload;
    },
    getCurrentUser(state, payload){
      state.currentUser = payload;
      for(let i of payload.role){
        if(i == "Admin") state.isAdmin = true;
        if(i == "Agent") state.isAgent = true;
        if(i == "Order Entry") state.isOrderEntry = true;
      }
    },
    updateAlert(state, payload){
      state.isAlert = payload;
    },
    resetAlert(state){
      state.isAlert = null;
    },
    saveLetterNote(state, payload){
      state.letterNotes = payload;
    },
    setForm(state, payload){
      state.papFormTitle = payload.papFormTitle;
      state.papForm = payload.papForm;
      state.papFormFax = payload.papFormFax;
    },
    updateLogoutFlag(state){
      state.logoutFlag = true;
    },
    updateUser(state, payload){
      state.currentUser = payload;
    },
    isRoleUpdated(state){
      state.isRoleUpdated = true;
    }
  },
  actions: {
    loggedIn({commit}, payload){
      commit('loggedIn', payload);
    },
    getCurrentUser({commit}) {
      let ID = JSON.parse(sessionStorage.getItem("account")).username.toLowerCase();
      axios.get(`User/getCurrentUserData?workEmail=${ID}`).then((res) => {
        commit("getCurrentUser", res.data.data);

      });
    },
  },
  modules: {},
});
