<template>
  <div class="nova-modal">
    <div class="nova-alert">
        <div class="d-flex justify-space-between align-center pa--5 border-bottom">
            <span class="header-text font--size--22">{{headerText}}</span>
            <img src="../assets/images/cross.svg" alt="" class="cursor--pointer" @click="closeAlert">
        </div>
        <div v-if="alertType === 'alert'">
            <div class="d-flex flex-column align-center pa--5 border-bottom">
            <img :src="getStatusIcon" alt="" style="width: 4rem;">
        <span v-html="statusText" class="header-text"></span>
        </div>
        </div>
        <div v-else class="border-bottom pa--5 text-align-intial">
          <span class="header-text">{{statusText}}</span>
          <div v-html="listArr"></div>
        </div>
        <div class="d-flex justify-center pa--4">
          <v-btn v-if="showCancelButton" depressed class="btn--color--white btn-css"  @click="closeAlert">{{leftButtonText}}</v-btn>
          <v-btn depressed class="btn--color--blue btn-css ml--3" @click="confirmAlert">{{rightButtonText}}</v-btn>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        status: {
            type: String,
            default: ""
        },
        statusText: {
            type: String,
            default: ""
        },
        headerText: {
            type: String,
            default: "Alert"
        },
        leftButtonText: {
            type: String,
            default: "Cancel"
        },
        rightButtonText: {
            type: String,
            default: "OK"
        },
        alertType: {
            type: String,
            default: "alert"
        },
        listArr: {
            type: Array | String
        },
        showCancelButton:{
          type: Boolean,
          default: false
        }
    },

    computed:{
        getStatusIcon(){
            if(this.status === "success") return require("../assets/images/tick.svg");
            else if(this.status === "error") return require("../assets/images/cross_circle.svg");
            else if(this.status === "warning") return require("../assets/images/warning.svg");
        }
    },

    methods:{
      closeAlert(){
        this.$emit("close");
      },
      confirmAlert(){
        this.$emit("confirm");
        //this.closeAlert();
      }
    },
}
</script>

<style lang="scss" scoped>
.nova-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .nova-alert {
    position: relative;
    width: 40rem;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    animation: 0.7s 1 novaAnimate;
    -webkit-animation: 0.7s 1 novaAnimate;
}
}
@keyframes novaAnimate {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: none;
  }
}

.header-text{
    font-size: 20px;
    font-weight: 600;
    color: #0C0C33;
}
.border-bottom{
   border-bottom: 1px solid #CECED6; 
}
.btn-css{
  width: 8rem !important;
}
.text-align-intial{
    text-align: initial;
}
</style>