var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.loading)?_c('loader'):_vm._e(),(!_vm.loading)?_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#0057ed"}},[_c('span',{staticStyle:{"color":"white","font-weight":"800 !important","font-size":"1.2em"}},[_vm._v("Enrollment Preview")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-form',[_vm._l((_vm.stepperArr),function(stepper,i){return _c('div',{key:i},[_c('fieldset',{staticClass:"my-4",staticStyle:{"border-radius":"15px","padding":"0.5%"}},[_c('legend',{staticStyle:{"color":"black","font-size":"1.8em","font-weight":"600","margin-left":"30px","padding":"5px"}},[_vm._v(" "+_vm._s(stepper.name)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.range(0, Math.ceil(_vm.papForm[i].length / 2))),function(index){return _c('v-row',{key:index},[(_vm.papForm[i][index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(_vm.papForm[i][index].label)+" ")]):_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"form--label"},[_vm._v(_vm._s(_vm.papForm[i][index].label))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"form--value"},[_vm._v(" "+_vm._s(_vm.papForm[i][index].value ? _vm.papForm[i][index].value : "Unknown")+" ")])])],1)],1)}),1),_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.range(
                    Math.ceil(_vm.papForm[i].length / 2),
                    _vm.papForm[i].length
                  )),function(index){return _c('v-row',{key:index},[(_vm.papForm[i][index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(_vm.papForm[i][index].label)+" ")]):_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"form--label"},[_vm._v(_vm._s(_vm.papForm[i][index].label))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"form--value"},[_vm._v(" "+_vm._s(_vm.papForm[i][index].value ? _vm.papForm[i][index].value : "Unknown")+" ")])])],1)],1)}),1)],1),(
                stepper.id === 'prescriptionAndDiagnosis' &&
                _vm.tableData.length > 0
              )?_c('v-row',[_c('v-col',{staticClass:"ml--3",attrs:{"md":"6","xl":"6","sm":"6"}},[_c('data-table',{key:_vm.reRender,attrs:{"headers":_vm.headers,"tableData":_vm.tableData,"hideDefaultFooter":_vm.tableData.length <= 10? true:false},scopedSlots:_vm._u([{key:"columns",fn:function(props){return [_c('td',[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.fileName)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.fileType)+" ")])]}}],null,true)})],1)],1):_vm._e()],1)])}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"primary",on:{"click":_vm.closeDialog}},[_vm._v("Close Preview")])],1)],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }