<template>
  <div class="loader-css">
    <v-progress-circular indeterminate color="primary" :size="70" :width="7">
      <v-overlay></v-overlay>
    </v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.loader-css {
  position: fixed;
  top: 45%;
  right: 45%;
  z-index: 999;
}
</style>
