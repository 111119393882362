import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENTID,
    tenantId: process.env.VUE_APP_MSAL_TENANTID,
    authority: process.env.VUE_APP_MSAL_AUTHORITY,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
  },
  request: {
    scopes: [`user.read`],
  },
  cache: {
    cacheLocation: "sessionStorage"
  },
};

if(sessionStorage.getItem('sessionExpired')) sessionStorage.clear();

const msalAuth = new msal.PublicClientApplication(msalConfig);

export default msalAuth;
