<template>
  <div>
    <loader v-if="isLoading" />
    <h1 class="mb--7">Follow Up</h1>
    <div class="card--padding follow-container">
      <div class="border-bottom pb--3">
        <h3 class="mb--2">Enrollments Details</h3>
        <div class="d-flex enrollment-container card--padding">
          <ul class="left-block list-style-none pa--0">
            <li
              v-for="(i, index) in enrollmentDetails.slice(0, 3)"
              :key="index"
              class="d-flex mb--3"
            >
              <div class="width-60 font--css font--weight--600">
                {{ i.name }}
              </div>
              <div class="font--css font--weight--500">{{ i.value }}</div>
            </li>
          </ul>
          <ul class="list-style-none width-60 margin-left-10 pa--0">
            <li
              v-for="(i, index) in enrollmentDetails.slice(3, 6)"
              :key="index"
              class="d-flex mb--3"
            >
              <div class="width-60 font--css font--weight--600">
                {{ i.name }}
              </div>
              <div class="font--css font--weight--500">{{ i.value }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="border-bottom">
        <h3 class="mt--4">Verification</h3>
        <div class="d-flex align-center">
          <v-checkbox v-model="checkbox"></v-checkbox>
          <span class="font--css font--weight--500"
            >Patient Insurance Verified</span
          >
        </div>
      </div>

        <h3 class="my--4">Send Communication Letter</h3>
        <v-form ref="form">
          <v-textarea
            class="note-container"
            outlined
            name="dialogInput"
            value=""
            placeholder="Enter Your Notes"
            :rules="rules"
            :v-model="notes"
            @input="onInputText"
          ></v-textarea>
          <div class="note-hint">Max limit 500 characters</div>
          <div class="d-flex justify-end my--3">
            <v-btn depressed class="btn--color--blue button-css" :class="{'mr--3': $store.state.isOrderEntry}" :disabled="allData.isComplete" @click="openNoteBox('incomplete')">Enrollment Incomplete</v-btn>
            <v-btn v-if="$store.state.isOrderEntry" depressed class="btn--color--blue button-css mr--3" :disabled="!allData.isComplete || hideButtonByStatus == 'Approved'" @click="openNoteBox('approval')">Enrollment Approval</v-btn>
            <v-btn v-if="$store.state.isOrderEntry" depressed class="btn--color--blue button-css" :disabled="!allData.isComplete || hideButtonByStatus == 'Denied'" @click="openNoteBox('denial')">Enrollment Denied</v-btn>
          </div>
          <div class="pb--3">
            <span class="font--css mr--2 cursor--pointer" @click="isRotate = !isRotate">History</span>
            <img src="../assets/images/right-arrow.svg" alt="" class="img-css cursor--pointer" :class="{'rotate': isRotate}" @click="isRotate = !isRotate"/>
          </div>
          <v-card v-if="isRotate" class="card-padding">
          <data-table
            class="data-table"
            :key="historyDetails.length"
            :headers="headers"
            :tableData="historyDetails"
          >
            <template slot="columns" slot-scope="props">
              <td>{{props.item.userFirstName + " " + props.item.userLastName}}</td>
              <td>{{props.item?.createdDate | getCreatedDate(props.item.createdDate)}}</td>
              <td class="text-capitalize">{{props.item.fileType}}</td>
              <td><img src="../assets/images/preview.svg" alt="" style="width:18px;" class="cursor--pointer"  @click="previewForm(props.item.url)" /></td>
            </template>
          </data-table>
        </v-card>
        </v-form>
      </div>
    <v-btn class="close-btn" outlined color="indigo" @click="$router.push('/enrollments')"> Close </v-btn>
    <custom-alert
      v-show="openAlert"
      status="warning"
      :statusText="alert.statusText"
      :rightButtonText="alert.btnText"
      :showCancelButton="alert.cancelBtn"
      @confirm="onConfirm(alert.key)"
      @close="openAlert = false"
    />
    <custom-alert
      v-if="openAlertList"
      alertType="list"
      headerText="Unable to send a fax with following missing information:"
      statusText="Try again after you’ve filled the information."
      :listArr="listArr"
      @confirm="onConfirm('fax')"
      @close="openAlertList = false"
    />
    <v-dialog :key="rerender" v-model="dialog" persistent width="70%" @click:outside="closePreview">
      <v-card>
        <div id="pdf-viewer"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Loader from "../components/Loader.vue";
import DataTable from "../components/DataTable.vue"
import CustomAlert from "../components/CustomAlert.vue";
export default {
  components:{
    Loader,
    DataTable,
    CustomAlert
  },
  data() {
    return {
      dialog: false,
      rerender: 0,
      checkbox: false,
      openAlert: false,
      openAlertList: false,
      isLoading: false,
      alert: {
        statusText: "",
        cancelBtn: false,
        btnText: "",
        key: ""
      },
      rules: [
        (v) => !!v || "",
        (v) => (v && v.length <= 500) || ""
      ],
      notes: "",
      isRotate: false,
      headers: [
      {
        text: "Sent By",
        value: "sentBy",
      },
      {
        text: "Created Date",
        value: "createdDate",
      },
      {
        text: "Communication Type",
        value: "communicationType",
      },
      {
        text: "Preview",
        value: "preview"
      }
      ],
      historyDetails: [],
      enrollmentDetails: [
        {
          name: "LAST NAME",
          value: "",
        },
        {
          name: "FIRST NAME",
          value: "",
        },
        {
          name: "DOB",
          value: "",
        },
        {
          name: "ENROLLMENT DATE",
          value: "",
        },
        {
          name: "PHYSICIAN NAME",
          value: "",
        },
        {
          name: "FAX NO.",
          value: "",
        },
      ],
      allData: [],
      listArr: [],
    };
  },

  filters: {
    getCreatedDate(date) {
      let Date = date.split("T")[0];
      let formattedDate = moment(Date).format("MM/DD/YYYY");
      return formattedDate;
    }
  },

  computed:{
     isDataComplete(){
      let arr = [];
      if(this.allData){
        if(this.allData['physicianInformation']){
          if(!this.allData['physicianInformation']['firstName']) arr.push("Physician First Name");
          if(!this.allData['physicianInformation']['lastName']) arr.push("Physician Last Name");
          if(!this.allData['physicianInformation']['officeFax']) arr.push("Physician Office Fax");
        }
        this.listArr = "<ul>";
        arr.forEach((i)=> {
         this.listArr += "<li>" + i + "</li>"
        })
        this.listArr += "</ul>";
        return arr;
      }
    },

    allStatus(){
      return this.$store.state.configStatus;
    },

    hideButtonByStatus(){
      let index = this.allStatus.findIndex((i)=> i.statusCode === this.allData.statusCode);
      if(index > -1) return this.allStatus[index].statusType;
    },

    isButtonDisable(){
      if(this.historyDetails.length !== 0) return this.historyDetails[0].fileType == "approval";
    }
  },

  methods:{
    closePreview(){
      this.dialog = false;
    },

    previewForm(url){
      var currPage = 1;
      var thePDF = null;
      pdfjsLib.getDocument(url).then((pdf) => {
        thePDF = pdf;
        pdf.getPage(1).then( handlePages );
      });
      function handlePages(page){
        var viewport = page.getViewport(1.82);
        var canvas = document.createElement("canvas");
        canvas.style.display = "block";
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        let pdfViewer = document.getElementById('pdf-viewer');
        pdfViewer.appendChild( canvas );
        page.render({canvasContext: context, viewport: viewport});
        currPage++;
        if ( thePDF !== null && currPage <= 2 ){
          thePDF.getPage( currPage ).then( handlePages );
        }
      }
      this.rerender++;
      this.dialog = true;    
      this.isLoading = true;
      setTimeout(()=> this.isLoading = false, 3000);
    },

    onInputText(data){
      this.$store.commit("saveLetterNote", data);
    },

    onConfirm(key){
      if(key == "fax") this.$router.push("/enrollments");
      if(key == "info") this.openAlert = false;
      if(key == "note") this.$router.push(`/enrollment-letter/${this.$route.params.id}`);
      this.openAlert = false;
    },

    openNoteBox(letter){
      if(this.isDataComplete.length == 0){
        if(letter == "approval" && !this.checkbox){
          this.alert = {
            statusText: "Patient Insurance needs to be verified first",
            key: "info"
          };
          this.openAlert = true;
        }else{
          let valid = this.$refs.form.validate();
          if(valid){
          this.alert = {
            statusText: 'Please review the note text. It cannot be changed once you click "Confirm".',
            cancelBtn: true,
            btnText: "Confirm",
            key: "note"
          };
          this.openAlert = true;
          sessionStorage.setItem('letter', letter);
        }
        }
      }
      else this.openAlertList = true;
    },

    getEnrollmentData() {
      this.axios.post(`Enrollment/searchEnrollmentById/`, {
          id: `${this.$route.params.id}`,
        }).then((res) => {
          this.$store.commit("setEnrollmentData", res.data.data);
          this.getDetails(res.data.data);
        }).catch((err) => {
          console.log(err);
        });
    },

    getDetails(data){
      this.allData = data;
      let patientInfo = data.patientInformation;
      let physicianInfo = data.physicianInformation;
      if(data.createdDate){
        let enrollmentDate = moment(data.createdDate.split()[0]).format("MM/DD/YYYY");
        this.enrollmentDetails.map((i)=> {
          if(i.name == "LAST NAME") i.value = patientInfo.lastName;
          if(i.name == "FIRST NAME") i.value = patientInfo.firstName;
          if(i.name == "DOB") i.value = patientInfo.dateOfBirth;
          if(i.name == "ENROLLMENT DATE") i.value = enrollmentDate;
          if(i.name == "PHYSICIAN NAME" && physicianInfo.firstName != undefined) i.value = physicianInfo.firstName + " " + physicianInfo.lastName;
          if(i.name == "FAX NO.") i.value = physicianInfo.officeFax;
        })
      }
      this.isDataComplete;
    },

    getHistoryDetails(){
      this.isLoading = true;
      this.axios.post(`Enrollment/getFollowUpDetails/`, {
          id: `${this.$route.params.id}`,
      }).then((res) => {
        let arr = [...res.data.data["Approval"], ...res.data.data["Denial"], ...res.data.data["Incomplete"]];
        this.historyDetails = arr.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    }
  },

  mounted(){
    this.getHistoryDetails();
  },
  
  created(){
    if(!this.$route.params.id) this.$router.push("/enrollments");
    if (Object.keys(this.$store.state.papEnrollmentData).length > 0) {
      let response = this.$store.state.papEnrollmentData;
      this.$store.commit("setEnrollmentForLetter", response);
      this.getDetails(response);
    } else if (this.$route.params.id) {
      this.getEnrollmentData();
    }
    this.$store.commit("deleteEnrollmentData");
  }
};
</script>

<style lang="scss" scoped>
.left-block {
  border-right: 1px solid #ceced6;
  width: 60%;
}
.width-60 {
  width: 60%;
}
.margin-left-10 {
  margin-left: 10%;
}
.enrollment-container {
  border: 1px solid #ceced6;
  border-radius: 4px;
}
.card--padding {
  padding: 12px 16px 12px 16px !important;
}
.font--css {
  font-size: 14px;
}
.border-bottom {
  border-bottom: 2px solid #ceced6;
}
.button-css {
  text-transform: capitalize !important;
  border-radius: 10px;
  padding: 22px!important;
}
.img-css {
  width: 9px;
  height: 9px;
}
.follow-container {
  border: 1px solid #ceced6;
  border-radius: 4px;
}
.close-btn {
  float: right;
  margin-top: 20px;
  padding: 1.3rem 2.8rem !important;
  border-radius: 10px;
}
.rotate{
  transform: rotate(90deg);
}
.note-hint{
  font-size: 12px;
  color: #FF0000;
}
</style>
