<template>
  <div class="fax-image-container">
    <div class="image-heading">Fax</div>
    <v-carousel class="fax-carousel-container" v-model="activeSlide">
      <v-carousel-item v-for="(item, i) in urls" :key="i">
        <img :src="item" class="image" />
      </v-carousel-item>
    </v-carousel>
    <!-- <img :src="urlImage" class="image" /> -->
    <!-- <img src="../../form4.png" class="image" :style="{height: `${imageHeight}px`}"/> -->
    <!-- <div>
      <v-carousel class="carousel--img">
        <v-carousel-item
          class="image"
          v-for="(item, i) in urlImage"
          :key="i"
          :src="item"
        ></v-carousel-item>
      </v-carousel>
    </div> -->
    <div
      v-if="overlay"
      class="overlay"
      :style="{
        left: `${coordinates.left}px`,
        top: `${coordinates.top}px`,
        width: `${coordinates.width}px`,
        height: `${coordinates.height}px`,
      }"
    ></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    // urlImage: "",
    activeSlide: 0,
  }),
  props: {
    coordinates: { type: Object, default: () => {} },
    overlay: { type: Boolean, default: false },
    imageHeight: { type: Number, default: 0 },
    tableData: { type: Array, default: () => [] },
    showImage: { type: Boolean, default: false },
    previewImage: { type: Number, default: 0 },
    // loading: { type: Boolean, default: false },
    urls: {
      type: Array,
      default: [],
    },
  },
  methods: {
    async showPreview() {
      // let url = "https://medvextractdevapi.veersatech.com" + "/images";
      // const params = {};
      // params.id = this.$route.params.id;
      // if (this.urls.length == 0) {
      //   let response = await this.axios.get(url, {
      //     params,
      //     headers: {
      //       accessToken: sessionStorage.accessToken,
      //     },
      //   });
      //   this.urls = response.data.data;
      // this.urlImage = this.urls[0];
      this.activeSlide = 0;
    },
    signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
  },
  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.tableData.length) {
          this.showImage = true;
        }
      },
    },
    previewImage: {
      immediate: true,
      handler(data) {
        this.urls.forEach((e, imgIndex) => {
          let index = e.split("form")[1].split("_")[0];
          if (index == data) {
            // this.urlImage = e;
            this.activeSlide = imgIndex;
          }
        });
      },
    },
    urls(data) {
      data.forEach((e, imgIndex) => {
        let index = e.split("form")[1].split("_")[0];
        if (index == this.previewImage) {
          // this.urlImage = e;
          this.activeSlide = imgIndex;
        }
      });
    },
    activeSlide(data) {
      if (data != this.previewImage)
        this.$emit("previewImageChange", { index: data });
    },
  },
  mounted() {
    this.showPreview();
  },
};
</script>

<style lang="scss" scoped>
/* .outer-box {
  margin-left: 10px;
} */
.show {
  display: contents;
}

.hide {
  display: none;
}
.image {
  width: 700px;
  height: 750px;
  border-left: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-bottom: #e0e0e0 solid 1px;
  display: flex;
  position: relative;
}
.image-heading {
  color: #fff;
  width: 700px;
  background-color: #0c0c33;
  padding: 10px;
  font-size: 1.5em;
}
.carousel--img {
  width: 600px;
}
.overlay {
  position: absolute;
  background-color: aqua;
  opacity: 30%;
}
.fax-carousel-container {
  height: 750px !important;
}
</style>
