<template>
  <v-app>
    <header-nav v-if="$store.state.isLoggedIn" />
    <side-nav v-if="$store.state.isLoggedIn" />
    <router-view
      :class="
        $store.state.sideNavBar && $store.state.isLoggedIn
          ? 'header--margin padding--layout'
          : 'header--margin layout--without--sidenav'
      "
    />
    <custom-alert
      v-show="openPopup"
      :headerText="alert.headerText"
      :status="alert.status"
      :statusText="alert.statusText"
      @confirm="onConfirm(alert.key)"
      @close="onClose(alert.key)"
    />
  </v-app>
</template>
<script>
import HeaderNav from "./components/headerNav.vue";
import SideNav from "./components/SideNav.vue";
import CustomAlert from "../src/components/CustomAlert.vue";
import axios from "axios";
export default {
  components: {
    HeaderNav,
    SideNav,
    CustomAlert,
  },
  data() {
    return {
      alert: {},
      openPopup: false,
    };
  },
  computed: {},
  methods: {
    getUpdatedPopupMessage() {
      this.openPopup = true;
      if (this.$store.state.isAlert === "beforeExpired") {
        this.alert = {
          status: "warning",
          statusText: "Your session is about to expire. Please click 'OK'",
          key: "beforeExpired",
        };
      } else if (this.$store.state.isAlert === "expired") {
        this.alert = {
          status: "error",
          statusText: "Your session is expired. Please login again!",
          key: "expired",
        };
        this.preventNav();
        sessionStorage.removeItem("accessToken");
      } else if (this.$store.state.isAlert === "401") {
        this.alert = {
          status: "error",
          statusText: "Unauthorized User",
          key: "unAuthorized",
        };
      }else if (this.$store.state.isAlert === "403") {
        this.alert = {
          status: "error",
          statusText: "Access Denied",
          key: "access_denied",
        };
      }else if (this.$store.state.isAlert === "user_not_found") {
        this.alert = {
          status: "error",
          statusText: "Your account is disabled!",
          key: "user_not_found",
        };
      }else if (this.$store.state.isAlert === "roleUpdated") {
        this.alert = {
          status: "warning",
          statusText: "Your role is updated!",
          key: "roleUpdated",
        };
      }else {
        this.alert = {
          status: "error",
          statusText: "Something went wrong",
          key: "errorApi",
        };
      }
    },
    preventNav() {
      let data = {
        workEmail: JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase(),
        isLoggedIn: false,
        isSessionExpired: true
      };
      this.axios
        .post(`User/setIsCurrentlyLoggedIn`, data)
        .then(() => {
          sessionStorage.clear();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatusConfig() {
      this.axios.get(`Shared/getConfig?payload=status`).then((res) => {
        this.$store.commit("getStatusConfig", res.data.data);
      });
    },
    getStateConfig() {
      this.axios.get(`Shared/getConfig?payload=state`).then((res) => {
        res.data.data.sort((a, b) => { return a.abbreviation.localeCompare(b.abbreviation)});
        this.$store.commit("getStateConfig", res.data.data);
      });
    },
    onConfirm(key) {
      if (key == "beforeExpired") {
        window.location.reload();
      }
      if (key == "expired") {
        window.location.reload();
      }
      if (key == "unAuthorized") {
        sessionStorage.clear();
        window.location.reload();
      }
      if (key == "access_denied") {
        this.openPopup = false;
        this.$router.push("/dashboard");
      }
      if (key == "user_not_found") {
        sessionStorage.clear();
        window.location.reload();
        this.openPopup = false;
      }
      if (key == "roleUpdated") {
        this.openPopup = false;
        window.location.reload();
      }
      if (key == "errorApi") {
        this.openPopup = false;
      }
    },
    onClose(key) {
      if (key == "expired") {
        window.location.reload();
      } else if (key == "unAuthorized") {
        sessionStorage.clear();
        window.location.reload();
      }else if (key == "access_denied") {
        this.openPopup = false;
        this.$router.push("/dashboard");
      }else if (key == "user_not_found") {
        sessionStorage.clear();
        window.location.reload();
        this.openPopup = false;
      }else if (key == "roleUpdated") {
        this.openPopup = false;
        window.location.reload();
      }else {
        this.openPopup = false;
      }
    },
    callSetTimeStamp() {
      let data = {
        workEmail: JSON.parse(
          sessionStorage.getItem("account")
        ).username.toLowerCase(),
      };
      setInterval(() => {
        axios.post(`${process.env.VUE_APP_BASE_URL}User/setTimeStamp`, data);
      }, 50000);
    },
  },
  watch: {
    $route(to, from) {
      if (from.path != to.path) {
        if (this.$store.state.configStatus.length == 0) this.getStatusConfig();
        if (this.$store.state.configState.length == 0) this.getStateConfig();
        if (this.$store.state.currentUser.length == 0) this.$store.dispatch("getCurrentUser");
      }
    },
    "$store.state.isAlert": function () {
      if(this.$store.state.isAlert != null) this.getUpdatedPopupMessage();
      this.$store.commit("resetAlert");
    },
  },

  created() {
    if (sessionStorage.getItem("accessToken")) {
      this.getStatusConfig();
      this.getStateConfig();
      this.$store.dispatch("getCurrentUser");
      this.callSetTimeStamp();
    }
  },
};
</script>
