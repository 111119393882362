<template>
  <div>
    <div v-if="$store.state.sideNavBar" class="sidenav-container">
      <span class="logo-container">
        <!-- <img
          class="logo"
          src="../assets/images/Medvantx_Logo.svg"
          width="200"
          height="100"
          alt=""
        /> -->
        <img
          class="logo"
          src="../assets/images/Veersa_Logo.png"
          width="200"
          alt=""
        />
      </span>
      <span
        style="
          background-color: #00cea2;
          width: 100%;
          height: 2px;
          display: block;
          margin-top: 10px;
          margin-bottom: 1.5rem;
        "
      ></span>
      <div class="pa--0 mt--2" v-for="(item, index) in itemList" :key="index">
        <v-row v-if="item.subItems.length" class="sublist-item" :class="{'d-none': ((isAdmin && !isAgent && !isOrderEntry) && item.name == 'Pre Enrollment') || (item.name == 'Settings' && !isAdmin)}">
          <v-expansion-panels v-model="item.expansionModel" tile accordion flat>
            <v-expansion-panel v-model="item.expansionModel">
              <v-expansion-panel-header
                class="sublist-header px--6 left-nav-item mb-2"
                :class="{'active-nav': item.isActive}"
                @click="showElements(index)"
              >
                <v-icon
                  :class="
                    item.name === 'Pre Enrollment' ? 'pre-enrollment-icon' : ''
                  "
                  style="color: #1a73e9; font-size: 24px"
                  >{{ item.icon }}</v-icon
                >
                <span style="color: white; font-size: 16px" class="ml--4">
                  {{ item.name }}</span
                >
                <template v-slot:actions>
                  <v-icon color="white"> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-divider
                class="white"
                style="height: 2px"
                v-if="item.expansionModel === 0"
              ></v-divider>
              <v-expansion-panel-content
                class="sidenav-sublist-expansion"
                v-for="(props, i) in item.subItems"
                :key="i"
              >
                <div
                  @click="showPage(props)"
                  class="sidenav-sublist-content"
                  :class="
                    item.subItems[i].isActive
                      ? 'active-nav px--6 left-nav-item mt-2'
                      : 'px--6 left-nav-item mt-2'
                  "
                >
                  <v-icon style="color: #1a73e9; font-size: 24px">{{
                    props.icon
                  }}</v-icon
                  ><span style="color: white; font-size: 16px" class="ml--4">
                    {{ props.name }}</span
                  >
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-row
          v-else
          class="pa--36 left-nav-item mb-2"
          :class="{'active-nav': item.isActive && !item.subItems.length, 'd-none': (isAdmin && !isAgent && !isOrderEntry) && item.name == 'Enrollment'}"
          @click="showPage(item)"
        >
          <v-icon style="color: #1a73e9; font-size: 24px">{{
            item.icon
          }}</v-icon
          ><span style="color: white; font-size: 16px" class="ml--4">
            {{ item.name }}</span
          ></v-row
        >
      </div>

      <div
        class="py-2"
        style="
          background-color: #2c2e3d;
          position: fixed;
          width: inherit;
          bottom: 0px;
        "
      >
        <span style="color: white; margin: 0px 40px"
          >Powered By
          <img src="../assets/images/vCare-logo.png" height="20" width="60"
        /></span>
      </div>
    </div>
    <div v-else class="sidenav-container width--auto">
      <div class="pa--0 mt--2" v-for="(item, index) in itemList" :key="index">
        <v-row
          class="pa--36 left-nav-item mb-2"
          :class="{'active-nav': item.isActive, 'd-none': ((isAdmin && !isAgent && !isOrderEntry) && (item.name == 'Enrollment' || item.name == 'Pre Enrollment')) || (item.name == 'Settings' && !isAdmin)}"
          @click="
            item.subItems.length ? showPage(item.subItems[0]) : showPage(item)
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="color: #1a73e9; font-size: 24px"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <span>{{ item.iconText }}</span>
          </v-tooltip>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sideNavIndex: this.$route.meta.sideNavIndex,
      subSideNavIndex: this.$route.meta.subSideNavIndex,
      users: [
        "rohit kumar",
        "daya singh",
        "sharmila arora",
        "priya barnwal",
        "abhishek yadav",
        "shivam pandey",
      ],
      itemList: [
        {
          name: "Home",
          showSubItem: false,
          subItems: [],
          icon: "mdi-home-outline",
          iconText: "Dashboard",
          link: "/dashboard",
          isActive: true,
        },
        {
          name: "Pre Enrollment",
          showSubItem: false,
          subItems: [],
          icon: "mdi-archive-outline",
          iconText: "Pre Enrollment List",
          isActive: false,
          expansionModel: null,
          subItems: [
            {
              itemId: 1,
              name: "Extracted",
              icon: "mdi-file-cog-outline",
              iconText: "Extracted",
              link: "/pre-enrollment-list",
              isActive: false,
            },
            {
              itemId: 2,
              name: "Unextracted",
              icon: "mdi-file-document-alert-outline",
              iconText: "Unextracted",
              link: "/pre-enrollment-list/unextracted",
              isActive: false,
            },
          ],
        },
        {
          name: "Enrollment",
          showSubItem: false,
          subItems: [],
          icon: "mdi-plus-circle-outline",
          iconText: "Patient List",
          link: "/enrollments",
          isActive: false,
        },
        {
          name: "Settings",
          showSubItem: false,
          icon: "mdi-cog-outline",
          iconText: "Settings",
          isActive: false,
          expansionModel: null,
          subItems: [
            {
              itemId: 1,
              name: "Logs",
              icon: "mdi-file-document-multiple-outline",
              iconText: "Get Logs",
              link: "/logs",
              isActive: false,
            },
            {
              itemId: 2,
              name: "Users",
              icon: "mdi-account-circle-outline",
              iconText: "Users",
              link: "/Users",
              isActive: false,
            },
          ],
        },
      ],
    };
  },
  computed:{
    isAdmin(){
      return this.$store.state.isAdmin ? true: false;
    },
    isAgent(){
      return this.$store.state.isAgent ? true: false;
    },
    isOrderEntry(){
      return this.$store.state.isOrderEntry ? true: false;
    }
  },

  methods: {
    showPage(item) {
      this.$router.push(item.link).catch(() => {});
      if (item.subItems?.length) item.showSubItem = true;
    },
    getRoute() {
      if (this.sideNavIndex > -1) {
        this.itemList.forEach((i) => {
          i.isActive = false;
          if (i.subItems) {
            i.expansionModel = null;
            i.subItems.forEach((item) => (item.isActive = false));
          }
        });
        this.itemList[this.sideNavIndex].isActive = true;
        if (this.subSideNavIndex != null && this.subSideNavIndex > -1) {
          this.itemList[this.sideNavIndex].expansionModel = 0;
          this.itemList[this.sideNavIndex].subItems[
            this.subSideNavIndex
          ].isActive = true;
        }
      }
    },
    showElements(index) {
      this.itemList[index].vDividerShow = !this.itemList[index].vDividerShow;
    },
  },
  watch: {
    $route(to, from) {
      if (from.path != to.path) {
        this.sideNavIndex = this.$route.meta.sideNavIndex;
        this.subSideNavIndex = this.$route.meta.subSideNavIndex;
        this.getRoute();
      }
    },
  },

  created() {
    this.getRoute();
  },
};
</script>

<style lang="scss" scoped>
.sidenav-container {
  width: 254px;
  background: linear-gradient(181.48deg, #0057ed 8.25%, #0c0c33 23.35%);
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0px;
  z-index: 10;
}
.logo-container {
  display: block;
  margin: 0% 0%;
  padding: 29px;
}
.logo {
  display: block;
  margin: auto;
  background: white;
}
.left-nav-item {
  align-items: center;
}
.left-nav-item:hover {
  cursor: pointer;
  background-color: #0057ed;
  color: white !important;
}
.left-nav-item:hover .v-icon {
  color: white !important;
}
.active-nav {
  cursor: pointer;
  background-color: #0057ed;
  color: white !important;
}
.active-nav .v-icon,
.white {
  color: white !important;
}
.width--auto {
  width: auto !important;
  top: 50px !important;
  background: linear-gradient(181.48deg, #0057ed 0%, #0c0c33 0%) !important;
}

.v-expansion-panel {
  background-color: transparent !important;
}
.v-expansion-panel i.v-icon.notranslate.mdi.mdi-cog-outline.theme--light {
  justify-content: flex-start;
  max-width: fit-content;
}
.sublist-item {
  padding: 0px !important;
}
.sublist-header {
  padding: 0 24px !important;
  margin-bottom: 0px !important;
}
.sidenav-sublist-content {
  padding: 12px 24px 12px 36px !important;
}
.sublist-item-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pre-enrollment-icon {
  margin-left: -20px;
  margin-right: -20px;
}
.pa--36 {
  padding: 12px 24px !important;
}
</style>
