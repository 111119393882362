<template>
  <v-app>
    <div class="d-flex flex-nowrap">
      <div v-for="index in items.length" :key="index - 1">
        <v-checkbox
          class="mr--3 checkbox"
          v-model="checkbox"
          :label="items[index - 1]"
          :value="items[index - 1]"
          :disabled="disabled"
          :hide-details="hideDetails"
          @click="getCheckboxData"
        ></v-checkbox>
      </div>
      <!-- <v-checkbox
        class="mr--3"
        v-model="checkbox"
        label="Yes"
        value="true"
        @click="getCheckboxData"
      ></v-checkbox>
      <v-checkbox
        class="mr--3"
        v-model="checkbox"
        label="No"
        value="false"
        @click="getCheckboxData"
      ></v-checkbox> -->
    </div>
  </v-app>
</template>

<script>
export default {
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      default: false,
    },
  },
  data() {
    return {
      checkbox: null,
    };
  },
  methods: {
    getCheckboxData() {
      this.$emit("on-change", this.checkbox);
      this.$emit("input", this.checkbox);
    },
  },
  watch: {
    defaultValue(data) {
      this.checkbox = data;
    },
  },
  created() {
    if (this.items.length > 0 && this.defaultValue) {
      if (!this.items.includes(this.defaultValue)) {
        this.$emit("on-change", "");
        this.$emit("input", "");
      }
    }
    this.checkbox = this.defaultValue;
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
