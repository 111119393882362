<template>
  <div>
    <div class="patient-list-container" :class="{'pointer-events-none': ($store.state.isAdmin && !$store.state.isAgent && !$store.state.isOrderEntry)}">
      <loader v-if="isLoading" />
      <div class="page-tile">Assist Dashboard</div>
      <span class="underline-grey"></span>
      <v-card class="card-padding pa--3">
        <div class="d-flex align-center">
            <h3 class="select-program barlow-bold font--size--22">Select Program</h3>
            <dropdown-program
              class="input-field box--shadow--none"
              :items="programsName"
              :item="selectedProgram"
              :hideDetails="true"
              v-model="selectedProgram"
            />
        </div>
        <div class="d-flex align-center mt--6">
          <v-col>
            <h3 style="color: #0057ed" class="barlow-bold font--size--22">Patient Pre-Enrollment</h3>
          </v-col>
        </div>
        <div class="d-flex align-center">
          <v-row>
            <v-col class="chip--dimension">
              <v-card
                class="pa--4"
                style="background: #ecf4ff"
                @click="$router.push('/pre-enrollment-list')"
              >
                <div class="d-flex align-center justify-center">
                  <v-btn
                    class="ml-2 mr-2"
                    icon
                    style="
                      padding: 20px;
                      font-size: 16px;
                      color: white;
                      background-color: #0057ed;
                      text-transform: capitalize;
                      border-radius: 10px;
                    "
                    elevation="0"
                  >
                    <v-icon medium>mdi-file-cog-outline</v-icon>
                  </v-btn>
                  <h4 class="ml-3 font--size--18">{{ preEnrollmentCount }}</h4>
                </div>
              </v-card>
              <h4 class="d-flex align-center justify-center mt-1 font--size--18">Extracted</h4>
            </v-col>
            <v-col class="chip--dimension">
              <v-card
                class="pa--4"
                style="background: #ecf4ff"
                @click="$router.push('/pre-enrollment-list/unextracted')"
              >
                <div class="d-flex align-center justify-center">
                  <v-btn
                    class="ml-2 mr-2"
                    icon
                    style="
                      padding: 20px;
                      font-size: 16px;
                      color: white;
                      background-color: #0057ed;
                      text-transform: capitalize;
                      border-radius: 10px;
                    "
                    elevation="0"
                  >
                  <v-icon medium>mdi-file-document-alert-outline</v-icon>
                  </v-btn>
                  <h4 class="ml-3 font--size--18">{{ unextractedCount }}</h4>
                </div>
              </v-card>
              <h4 class="d-flex align-center justify-center mt-1 font--size--18">Unextracted</h4>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex align-center mt--10">
          <v-col style="padding: 12px;">
            <h3 style="color: #0057ed" class="barlow-bold font--size--22">Patient Enrollments</h3>
          </v-col>
        </div>
        <div class="d-flex align-center mb--5">
          <v-row>
            <v-col
              class="chip--dimension"
              v-for="(card, index) in cards"
              :key="index"
            >
              <v-card
                class="pa--4 mt-2"
                :style="`background:` + card.color"
                @click="goToPatientList(card.ApiKey)"
              >
                <div class="d-flex justify-center align-center">
                  <img :src="card.image" width="44" height="44" />
                  <h4 class="ml--3 font--size--18">{{ templateObj[card.ApiKey] || "0" }}</h4>
                </div>
              </v-card>
                <h4 style="color: #000000" class="d-flex align-center justify-center mt-1 font--size--18">
                  {{ card.text }}
                </h4>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import DropdownProgram from "../components/DropDown.vue";
export default {
  components: {
    Loader,
    DropdownProgram,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      preEnrollmentCount: 0,
      unextractedCount: 0,
      isLoading: false,
      openDialog: false,
      templateObj: "",
      selectedProgram: "",
      cards: [
        {
          ApiKey: "Incomplete",
          image: require("@/assets/images/incomplete.png"),
          color: "#FFF6D8",
          text: "Incomplete",
          id: 1,
        },
        {
          ApiKey: "Eligible",
          image: require("@/assets/images/eligible.png"),
          color: "#E0ECFD",
          text: "Eligible",
          id: 2,
        },
        {
          ApiKey: "NotEligible",
          image: require("@/assets/images/noteligible.png"),
          color: "#FFE2E2",
          text: "Not Eligible",
          id: 3,
        },
        {
          ApiKey: "Approved",
          image: require("@/assets/images/denial.png"),
          color: "#F8DCF2",
          text: "Approved",
          id: 4,
        },
        {
          ApiKey: "Denied",
          image: require("@/assets/images/draft.png"),
          color: "#FFF2DD",
          text: "Denied",
          id: 5,
        },
        {
          ApiKey: "Total",
          image: require("@/assets/images/allenrollment.png"),
          color: "#F9E8FF",
          text: "All Enrollments",
          id: 6,
        },
      ],
      programObj: {},
      programsName: [],
    };
  },

  watch: {
    selectedProgram(item) {
      if (sessionStorage.getItem("programId") !== this.programObj[item]) {
        sessionStorage.setItem("programId", this.programObj[item]);
        this.isLoading = true;
        this.axios
          .get(
            `Program/getProgramFields?programId=${sessionStorage.getItem(
              "programId"
            )}`
          )
          .then((res) => {
            this.isLoading = false;
            this.$store.commit("setForm", {
              papFormTitle: JSON.parse(
                JSON.stringify(res.data.data[0].programImageUrl)
              ),
              papForm: JSON.parse(JSON.stringify(res.data.data[0].papForm)),
              papFormFax: JSON.parse(
                JSON.stringify(res.data.data[0].papFormFax)
              ),
            });
            this.selectedProgram = this.programsName[0];
          });
      }
    },
  },

  methods: {
    goToPatientList(items) {
      this.$router.push({ name: "patientList", params: { id: items } });
    },
    async getData() {
      this.isLoading = true;
      await this.axios.get(`Program/getProgramFields`).then((res) => {
        res.data.data.forEach((i) => {
          this.programObj[i.programName] = i.id;
          this.programsName.push(i.programName);
        });
        sessionStorage.setItem(
          "programId",
          this.programObj[this.programsName[0]]
        );
        this.$store.commit("setForm", {
          papFormTitle: JSON.parse(
            JSON.stringify(res.data.data[0].programImageUrl)
          ),
          papForm: JSON.parse(JSON.stringify(res.data.data[0].papForm)),
          papFormFax: JSON.parse(JSON.stringify(res.data.data[0].papFormFax)),
        });
        this.selectedProgram = this.programsName[0];
      });
      let data = {};
      data.programId = sessionStorage.getItem("programId");
      this.axios.post(`/Enrollment/getDashboardAnalytics`, data).then((res) => {
        this.isLoading = false;
        this.templateObj = res.data.data;
        if (this.templateObj.preEnrollmentCount)
          this.preEnrollmentCount = this.templateObj.preEnrollmentCount;
        if (this.templateObj.unextractedCount)
          this.unextractedCount = this.templateObj.unextractedCount;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.underline {
  text-decoration-line: underline;
  text-decoration-color: #00cea2;
  text-decoration-thickness: 10%;
}
.card-padding {
  margin-top: 1rem;
  border: 2px solid #ceced6;
  border-radius: 0.25rem;
}
.no-underline {
  text-decoration: none;
}
.chip--dimension {
  min-width: 164px !important;
  min-height: 80px;
  max-width: fit-content;
  padding: 0 12px !important;
  margin-right: 35px;
}
.select-program {
  white-space: nowrap;
  margin: 12px 2rem 12px 0.7rem;
}
.pointer-events-none{
  pointer-events: none !important;
}
</style>
