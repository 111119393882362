<template>
  <div style="height: 700px">
    <Loader v-if="isLoading" />
    <div>
      <h1 class="header-css">{{ getTitle }}</h1>
      <editor
        v-if="template"
        api-key="no-api-key"
        :initialValue="template"
        :init="{
          height: 700,
          menubar: false,
          statusbar: false,
          content_style: `div { font-size: 16px !important; }`,
          setup(ed) {
            ed.on('change', function () {
              template = ed.getContent();
            });
          },
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
        }"
      />
    </div>
    <div class="d-flex justify-end mt--5">
      <v-btn depressed class="btn--color--white btn-css" @click="$router.go(-1)"
        >Close</v-btn
      >
      <v-btn
        depressed
        class="btn--color--blue btn-css ml--3"
        @click="sendLetter"
        >Send</v-btn
      >
    </div>
    <custom-alert
      v-if="openAlert"
      status="success"
      statusText="Fax successfully sent!"
      @confirm="onConfirm"
      @close="onConfirm"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import Loader from "../components/Loader.vue";
import CustomAlert from "../components/CustomAlert.vue";
import moment from "moment";
export default {
  components: {
    Loader,
    Editor,
    CustomAlert,
  },
  data: () => ({
    template: ``,
    isLoading: false,
    enrollmentDetails: [],
    openAlert: false,
  }),

  computed: {
    getTitle() {
      let letter = sessionStorage.getItem("letter");
      if (letter == "incomplete") return "Missing Information Letter";
      else if (letter == "approval")
        return "Enrollment Approved, Send Approval Letter";
      else if (letter == "denial")
        return "Enrollment Denied, Send Denial Letter";
    },
  },

  methods: {
    getTemplate() {
      let payload = {
        fileName: sessionStorage.getItem("letter") + ".html",
      };
      if (!this.isLoading) this.isLoading = true;
      this.axios
        .post(`Enrollment/getTemplateByName`, payload)
        .then((res) => {
          this.template = atob(res.data.data);
          this.replaceTemplateName(this.template);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    replaceTemplateName(data) {
      data = data.replace(
        /{Physician Name}/g,
        "<strong>" +
          ((Object.keys(this.enrollmentDetails.physicianInformation).length !=
            0 &&
            this.enrollmentDetails &&
            this.enrollmentDetails.physicianInformation &&
            this.enrollmentDetails.physicianInformation.firstName +
              " " +
              this.enrollmentDetails.physicianInformation.lastName) ||
            "") +
          "</strong>"
      );
      data = data.replace(
        /{Fax Number}/g,
        "<strong>" +
          ((this.enrollmentDetails &&
            this.enrollmentDetails.physicianInformation &&
            this.enrollmentDetails.physicianInformation.officeFax) ||
            "") +
          "</strong>"
      );
      data = data.replace(
        /{Phone Number}/g,
        "<strong>" +
          ((this.enrollmentDetails &&
            this.enrollmentDetails.physicianInformation &&
            this.enrollmentDetails.physicianInformation.officePhone) ||
            "") +
          "</strong>"
      );
      data = data.replace(
        /{Program Name}/g,
        "<strong>" + "PAP Assist" + "</strong>"
      );
      data = data.replace(
        /{Program Fax}/g,
        "<strong>" + "801-272-5989" + "</strong>"
      );
      data = data.replace(
        /{Program Phone}/g,
        "<strong>" + "801-272-5989" + "</strong>"
      );
      data = data.replace(
        /{Enrollment ID}/g,
        "<strong>" + this.$route.params.id + "</strong>"
      );
      data = data.replace(
        /{Current Date}/g,
        "<strong>" + moment().format("MM/DD/YYYY") + "</strong>"
      );
      data = data.replace(
        /{Current Time}/g,
        "<strong>" + moment().format("HH:mm") + "</strong>"
      );
      data = data.replace(/{Page}/g, "<strong>" + "2" + "</strong>");
      data = data.replace(
        /{Patient Name}/g,
        "<strong>" +
          ((this.enrollmentDetails &&
            this.enrollmentDetails.patientInformation &&
            this.enrollmentDetails.patientInformation.firstName +
              " " +
              this.enrollmentDetails.patientInformation.lastName) ||
            "") +
          "</strong>"
      );
      data = data.replace(
        /{Patient DOB}/g,
        "<strong>" +
          ((this.enrollmentDetails &&
            this.enrollmentDetails.patientInformation &&
            this.enrollmentDetails.patientInformation.dateOfBirth) ||
            "") +
          "</strong>"
      );
      data = data.replace(/{Notes}/g, this.$store.state.letterNotes);
      data = data.replace(
        /{Program Department}/g,
        "<strong>" + "Customer Service" + "</strong>"
      );
      data = data.replace(
        /{Program Company}/g,
        "<strong>" + "Veersa" + "</strong>"
      );
      var str = "<ul>";
      if (
        this.enrollmentDetails &&
        !this.enrollmentDetails.isComplete &&
        this.enrollmentDetails.missingStatus &&
        this.enrollmentDetails.missingStatus.missingSteppers
      ) {
        this.enrollmentDetails.missingStatus.missingSteppers.forEach((item) => {
          let stepperName = "";
          if (item == "eligibilityInformation")
            stepperName = "Eligibility Information";
          if (item == "physicianInformation")
            stepperName = "Physician Information";
          if (item == "patientInformation") stepperName = "Patient Information";
          if (item == "prescriptionAndDiagnosis")
            stepperName = "Prescription And Diagnosis";
          if (item == "insuranceAndSignatures")
            stepperName = "Insurance And Signatures";
          if (item == "insurance") stepperName = "Insurance";
          if (item == "signatures") stepperName = "Signatures";
          str += "<li><strong>" + stepperName + "</strong></li>";
        });
      }else if(this.enrollmentDetails && 
      this.enrollmentDetails.isComplete && 
      this.enrollmentDetails.eligibilityInformation &&
      this.enrollmentDetails.eligibilityInformation.eligibilityCheck && 
      this.enrollmentDetails.eligibilityInformation.eligibilityCheck.eligibilityFields
      ){
        let eligibilityFields = this.enrollmentDetails.eligibilityInformation.eligibilityCheck.eligibilityFields.filter((i)=> i.status == "No");
        eligibilityFields.forEach((i) => {
          delete i.status;
          let item = Object.keys(i);
          let stepperName = "";
          if (item == "usResident") stepperName = "Patient is not United States Resident";
          if (item == "patientUninsured") stepperName = "Patient does not meet insurance requirements";
          if (item == "incomeValidation") stepperName = "Patient household income exceeds program criteria";
          if (item == "age") stepperName = "Patient does not meet age requirement";
          str += "<li><strong>" + stepperName + "</strong></li>";
        });
      }
      str += "</ul>";
      data = data.replace(/{Missing Steppers}/g, str);
      this.template = data;
      this.isLoading = false;
    },

    sendLetter() {
      let payload = {
        enrollmentId: this.$route.params.id,
        base64Data: this.template,
        fileType: sessionStorage.getItem("letter"),
        userId: this.$store.state.currentUser._id,
        to: "1" + this.enrollmentDetails.physicianInformation.officeFax,
      };
      this.isLoading = true;
      this.axios
        .post(`Enrollment/communicationFollowUp`, payload)
        .then(() => {
          this.isLoading = false;
          this.openAlert = true;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },

    onConfirm() {
      this.$router.push("/enrollments").catch(() => {});
      this.openAlert = false;
    },

    onClose() {
      this.openAlert = false;
    },

    getEnrollmentData() {
      this.isLoading = true;
      this.axios
        .post(`Enrollment/searchEnrollmentById/`, {
          id: `${this.$route.params.id}`,
        })
        .then((res) => {
          this.enrollmentDetails = res.data.data;
          this.getTemplate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    if (!this.$route.params.id) this.$router.push("/enrollments");
    if (Object.keys(this.$store.state.papLetterData).length > 0) {
      this.enrollmentDetails = this.$store.state.papLetterData;
      this.getTemplate();
    } else if (this.$route.params.id) {
      this.getEnrollmentData();
    }
  },
};
</script>

<style lang="scss" scoped>
.header-css {
  color: #0057ed;
  margin-bottom: 16px;
}
.btn-css {
  width: 9rem;
  height: 2.7rem !important;
}
</style>
