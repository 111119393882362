var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"papform"},[(_vm.isLoading)?_c('loader'):_vm._e(),(_vm.loadingCount === 0)?_c('stepper',{attrs:{"stepperArr":_vm.stepperArr,"stepperActiveIndex":_vm.stepperActiveIndex,"statusCode":_vm.statusCode,"missingFlag":_vm.missingFlag},on:{"changeActiveIndex":function($event){return _vm.updateStepperActiveIndex($event)},"clickMissingInfo":function($event){_vm.missingPopupDisplay = true}}}):_vm._e(),(_vm.loadingCount === 0)?_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card',{staticClass:"card--spacing form--card"},[(
          _vm.stepperArr[_vm.stepperActiveIndex] &&
          _vm.stepperArr[_vm.stepperActiveIndex].name
        )?_c('div',{staticClass:"d-flex justify-space-between align-center mb--3"},[_c('span',{staticClass:"papFormStepName barlow-bold"},[_vm._v(" "+_vm._s(_vm.stepperArr[_vm.stepperActiveIndex].name)+" ")]),_c('span',{staticClass:"asterix-required-field"},[_vm._v("*Required fields")])]):_vm._e(),_vm._l((_vm.papForm),function(item,i){return _c('div',{key:i},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepperActiveIndex === i),expression:"stepperActiveIndex === i"}]},[(_vm.stepperArr[i] && _vm.stepperArr[i].leftColFields)?_c('v-col',{staticClass:"left-col",class:{ 'pb--0': _vm.breakpoint === 12 },attrs:{"sm":_vm.breakpoint}},_vm._l((_vm.range(0, _vm.stepperArr[i].leftColFields)),function(index){return _c('v-row',{key:index,staticClass:"pb--3 flex-nowrap align-center"},[(item[index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(item[index].label)+" ")]):_c('div',{staticClass:"field-label pr--3"},[_c('span',[_vm._v(" "+_vm._s(item[index].label)+" "),(item[index].required)?_c('span',{staticClass:"asterix-required-field"},[_vm._v("*")]):_vm._e()])]),(
                  item[index].type === 'text' ||
                  item[index].type === 'phone' ||
                  item[index].type === 'date' ||
                  item[index].type === 'partialPassword'
                )?_c('InputField',{staticClass:"input-field",attrs:{"placeholder":item[index].placeholder,"reGex":item[index].reGex,"errorMsg":item[index].error,"type":item[index].type,"defaultValue":item[index].value,"disabled":item[index].disabled ? true : false || !item[index].isAccess,"maxlength":item[index].maxlength,"hide-details":'auto'},on:{"on-input":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e(),(item[index].type === 'dropdown')?_c('DropDown',{staticClass:"input-field box--shadow--none",attrs:{"items":item[index].key === 'state' ? _vm.states : item[index].items,"item":item[index].value,"disabled":item[index].disabled ? true : false || !item[index].isAccess,"placeholder":item[index].placeholder,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e(),(item[index].type === 'radio')?_c('Checkbox',{attrs:{"items":item[index].items,"defaultValue":item[index].value,"disabled":item[index].disabled ? true : false || !item[index].isAccess,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e(),(item[index].type === 'radioWithDropdown')?_c('CheckboxWithDropdown',{attrs:{"items":item[index].items,"defaultValue":item[index].value,"dropdownItem":item[index].state,"dropdownPlaceholder":item[index].dropdownPlaceholder},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e()],1)}),1):_vm._e(),(_vm.stepperArr[i] && _vm.stepperArr[i].rightColFields)?_c('v-col',{staticClass:"right-col",class:{ 'pt--0': _vm.breakpoint === 12 },attrs:{"sm":_vm.breakpoint}},_vm._l((_vm.range(
                _vm.stepperArr[i].leftColFields,
                _vm.stepperArr[i].leftColFields + _vm.stepperArr[i].rightColFields
              )),function(index){return _c('v-row',{key:index,staticClass:"pb--3 flex-nowrap align-center"},[(item[index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(item[index].label)+" ")]):_vm._e(),(item[index].type !== 'heading')?_c('div',{staticClass:"field-label pr--3"},[_c('span',[_vm._v(" "+_vm._s(item[index].label)+" "),(item[index].required)?_c('span',{staticClass:"asterix-required-field"},[_vm._v("*")]):_vm._e()])]):_vm._e(),(
                  item[index].type === 'text' ||
                  item[index].type === 'phone' ||
                  item[index].type === 'date' ||
                  item[index].type === 'partialPassword'
                )?_c('InputField',{staticClass:"input-field",attrs:{"placeholder":item[index].placeholder,"reGex":item[index].reGex,"errorMsg":item[index].error,"type":item[index].type,"defaultValue":item[index].value,"disabled":item[index].disabled ? true : false || !item[index].isAccess,"maxlength":item[index].maxlength,"hide-details":'auto'},on:{"on-input":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e(),(item[index].type === 'dropdown')?_c('DropDown',{staticClass:"input-field box--shadow--none",attrs:{"items":item[index].key === 'state' ? _vm.states : item[index].items,"item":item[index].value,"placeholder":item[index].placeholder,"disabled":item[index].disabled ? true : false || !item[index].isAccess,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e(),(item[index].type === 'radio')?_c('Checkbox',{attrs:{"items":item[index].items,"defaultValue":item[index].value,"disabled":item[index].disabled ? true : false || !item[index].isAccess,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e(),(item[index].type === 'radioWithDropdown')?_c('CheckboxWithDropdown',{attrs:{"items":_vm.states,"defaultValue":item[index].value,"dropdownItems":item[index].dropdownItems,"dropdownItem":item[index].state,"dropdownPlaceholder":item[index].dropdownPlaceholder},on:{"on-change":function($event){return _vm.onInput($event, i, item[index].fieldIndex)}}}):_vm._e()],1)}),1):_vm._e(),(
              _vm.stepperArr[_vm.stepperActiveIndex].id === 'prescriptionAndDiagnosis'
            )?_c('v-col',{staticClass:"pl--0",attrs:{"sm":12}},[_c('div',{staticClass:"asterix-required-field"},[_vm._v(" Note: \"Enter Allergies and Concurrent medications in your pharmacy system\". ")])]):_vm._e()],1)],1)})],2),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.stepperArr[_vm.stepperActiveIndex] &&
        _vm.stepperArr[_vm.stepperActiveIndex].subLeftColFields > 0
      ),expression:"\n        stepperArr[stepperActiveIndex] &&\n        stepperArr[stepperActiveIndex].subLeftColFields > 0\n      "}],staticClass:"card--spacing form--card"},[_c('v-row',[(
            _vm.stepperArr[_vm.stepperActiveIndex] &&
            _vm.stepperArr[_vm.stepperActiveIndex].subLeftColFields >= 0
          )?_c('v-col',{staticClass:"left-col",class:{ 'pb--0': _vm.breakpoint === 12 },attrs:{"sm":_vm.breakpoint}},_vm._l((_vm.range(
              _vm.stepperArr[_vm.stepperActiveIndex].leftColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].rightColFields,
              _vm.stepperArr[_vm.stepperActiveIndex].leftColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].rightColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].subLeftColFields
            )),function(index){return _c('v-row',{key:index - 1,staticClass:"pb--3 flex-nowrap align-center"},[(_vm.papForm[_vm.stepperActiveIndex][index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(_vm.papForm[_vm.stepperActiveIndex][index].label)+" ")]):_c('div',{staticClass:"field-label pr--3"},[_c('span',[_vm._v(" "+_vm._s(_vm.papForm[_vm.stepperActiveIndex][index].label)+" "),(_vm.papForm[_vm.stepperActiveIndex][index].required)?_c('span',{staticClass:"asterix-required-field"},[_vm._v("*")]):_vm._e()])]),(
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'text' ||
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'phone' ||
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'date' ||
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'partialPassword'
              )?_c('InputField',{staticClass:"input-field",attrs:{"placeholder":_vm.papForm[_vm.stepperActiveIndex][index].placeholder,"reGex":_vm.papForm[_vm.stepperActiveIndex][index].reGex,"errorMsg":_vm.papForm[_vm.stepperActiveIndex][index].error,"type":_vm.papForm[_vm.stepperActiveIndex][index].type,"defaultValue":_vm.papForm[_vm.stepperActiveIndex][index].value,"disabled":_vm.papForm[_vm.stepperActiveIndex][index].disabled ? true : false || !_vm.papForm[_vm.stepperActiveIndex][index].isAccess,"maxlength":_vm.papForm[_vm.stepperActiveIndex][index].maxlength,"hide-details":'auto'},on:{"on-input":function($event){return _vm.onInput(
                  $event,
                  _vm.stepperActiveIndex,
                  _vm.papForm[_vm.stepperActiveIndex][index].fieldIndex
                )}}}):_vm._e(),(_vm.papForm[_vm.stepperActiveIndex][index].type === 'dropdown')?_c('DropDown',{staticClass:"input-field box--shadow--none",attrs:{"items":_vm.papForm[_vm.stepperActiveIndex][index].key === 'state'
                  ? _vm.states
                  : _vm.papForm[_vm.stepperActiveIndex][index].items,"item":_vm.papForm[_vm.stepperActiveIndex][index].value,"disabled":_vm.papForm[_vm.stepperActiveIndex][index].disabled ? true : false || !_vm.papForm[_vm.stepperActiveIndex][index].isAccess,"placeholder":_vm.papForm[_vm.stepperActiveIndex][index].placeholder,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput(
                  $event,
                  _vm.stepperActiveIndex,
                  _vm.papForm[_vm.stepperActiveIndex][index].fieldIndex
                )}}}):_vm._e()],1)}),1):_vm._e(),(
            _vm.stepperArr[_vm.stepperActiveIndex] &&
            _vm.stepperArr[_vm.stepperActiveIndex].rightColFields >= 0
          )?_c('v-col',{staticClass:"right-col",class:{ 'pt--0': _vm.breakpoint === 12 },attrs:{"sm":_vm.breakpoint}},_vm._l((_vm.range(
              _vm.stepperArr[_vm.stepperActiveIndex].leftColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].rightColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].subLeftColFields,
              _vm.stepperArr[_vm.stepperActiveIndex].leftColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].rightColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].subLeftColFields +
                _vm.stepperArr[_vm.stepperActiveIndex].subRightColFields
            )),function(index){return _c('v-row',{key:index - 1,staticClass:"pb--3 flex-nowrap align-center"},[(_vm.papForm[_vm.stepperActiveIndex][index].type === 'heading')?_c('h3',{staticClass:"mb--3"},[_vm._v(" "+_vm._s(_vm.papForm[_vm.stepperActiveIndex][index].label)+" ")]):_c('div',{staticClass:"field-label pr--3"},[_c('span',[_vm._v(" "+_vm._s(_vm.papForm[_vm.stepperActiveIndex][index].label)+" "),(_vm.papForm[_vm.stepperActiveIndex][index].required)?_c('span',{staticClass:"asterix-required-field"},[_vm._v("*")]):_vm._e()])]),(
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'text' ||
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'phone' ||
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'date' ||
                _vm.papForm[_vm.stepperActiveIndex][index].type === 'partialPassword'
              )?_c('InputField',{staticClass:"input-field",attrs:{"placeholder":_vm.papForm[_vm.stepperActiveIndex][index].placeholder,"reGex":_vm.papForm[_vm.stepperActiveIndex][index].reGex,"errorMsg":_vm.papForm[_vm.stepperActiveIndex][index].error,"type":_vm.papForm[_vm.stepperActiveIndex][index].type,"defaultValue":_vm.papForm[_vm.stepperActiveIndex][index].value,"disabled":_vm.papForm[_vm.stepperActiveIndex][index].disabled ? true : false || !_vm.papForm[_vm.stepperActiveIndex][index].isAccess,"maxlength":_vm.papForm[_vm.stepperActiveIndex][index].maxlength,"hide-details":'auto'},on:{"on-input":function($event){return _vm.onInput(
                  $event,
                  _vm.stepperActiveIndex,
                  _vm.papForm[_vm.stepperActiveIndex][index].fieldIndex
                )}}}):_vm._e(),(_vm.papForm[_vm.stepperActiveIndex][index].type === 'radio')?_c('Checkbox',{attrs:{"items":_vm.papForm[_vm.stepperActiveIndex][index].items,"defaultValue":_vm.papForm[_vm.stepperActiveIndex][index].value,"disabled":_vm.papForm[_vm.stepperActiveIndex][index].disabled ? true : false || !_vm.papForm[_vm.stepperActiveIndex][index].isAccess,"hide-details":'auto'},on:{"on-change":function($event){return _vm.onInput(
                  $event,
                  _vm.stepperActiveIndex,
                  _vm.papForm[_vm.stepperActiveIndex][index].fieldIndex
                )}}}):_vm._e()],1)}),1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pl--0"},[_c('v-btn',{staticClass:"btn--color--blue cursor--pointer",attrs:{"depressed":""},on:{"click":_vm.onFileChange,"close":_vm.closeUploadPopup}},[_vm._v("Upload Documents "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-upload ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl--0",attrs:{"md":"6","xl":"6","sm":"6"}},[_c('data-table',{key:_vm.render,staticClass:"data-table",attrs:{"headers":_vm.headers,"tableData":_vm.tableData},scopedSlots:_vm._u([{key:"columns",fn:function(props){return [_c('td',[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.fileName)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.fileType)+" ")]),_c('td',[_c('v-icon',{staticStyle:{"font-size":"18px","cursor":"pointer !important"},attrs:{"color":"#0057ED"},on:{"click":function($event){return _vm.getPreview(props.item.fileName)}}},[_vm._v("mdi-eye")]),(_vm.$store.state.isOrderEntry)?_c('v-icon',{staticStyle:{"font-size":"18px","cursor":"pointer !important","margin-left":"12px"},attrs:{"color":"#0057ED"},on:{"click":function($event){return _vm.deleteDocuments(props)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}}],null,false,539664762)})],1)],1)],1)],1):_vm._e(),(_vm.loadingCount === 0)?_c('v-card',{staticClass:"card--spacing d-flex justify-space-between button-v-card"},[_c('div',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepperActiveIndex > 0),expression:"stepperActiveIndex > 0"}],staticClass:"cursor--pointer",attrs:{"src":require("../assets/images/previousIcon.svg"),"alt":""},on:{"click":function($event){return _vm.priviousStepper()}}})]),_c('div',[_c('v-btn',{staticClass:"btn--color--white ml--3",attrs:{"depressed":""},on:{"click":_vm.closeAction}},[_vm._v(" Cancel ")]),(
          _vm.stepperArr[_vm.stepperActiveIndex] &&
          !_vm.stepperArr[_vm.stepperActiveIndex].last
        )?_c('v-btn',{staticClass:"btn--color--white ml--3",attrs:{"depressed":""},on:{"click":function($event){return _vm.saveClose()}}},[_vm._v(" Save & Close ")]):_vm._e(),(
          _vm.stepperArr[_vm.stepperActiveIndex] &&
          _vm.stepperArr[_vm.stepperActiveIndex].last
        )?_c('v-btn',{staticClass:"btn--color--blue ml--3",attrs:{"depressed":""},on:{"click":_vm.saveFormData}},[_vm._v(" Submit ")]):_c('v-btn',{staticClass:"btn--color--blue ml--3",attrs:{"depressed":""},on:{"click":function($event){return _vm.nextStepper()}}},[_vm._v(" Continue ")])],1)]):_vm._e(),_c('v-dialog',{key:_vm.rerender,attrs:{"persistent":"","width":"auto"},on:{"click:outside":_vm.closePreview},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.fileType == 'pdf')?_c('div',{attrs:{"id":"pdf-viewer"}}):_c('img',{attrs:{"src":_vm.imageSrc,"alt":""}})])],1),(_vm.missingPopupDisplay)?_c('MissingPopup',{attrs:{"missingFieldArr":_vm.missingFieldArr,"stepperArr":_vm.stepperArr},on:{"closeAction":function($event){_vm.missingPopupDisplay = false}}}):_vm._e(),(_vm.openUploadPopup)?_c('upload-popup',{attrs:{"templateData":_vm.templateData},on:{"close":_vm.closeUploadPopup,"uploadedDocument":_vm.getDocuments}}):_vm._e(),(_vm.openAlert)?_c('custom-alert',{attrs:{"headerText":_vm.alert.headerText,"status":_vm.alert.status,"statusText":_vm.alert.statusText,"showCancelButton":_vm.alert.showCancelButton},on:{"confirm":function($event){return _vm.onConfirm(_vm.alert.key)},"close":function($event){return _vm.onClose(_vm.alert.key)}}}):_vm._e(),(_vm.openAlertList)?_c('custom-alert',{attrs:{"headerText":_vm.alertList.headerText,"alertType":_vm.alertList.type,"status":_vm.alertList.status,"statusText":_vm.alertList.statusText,"listArr":_vm.alertList.listArr},on:{"confirm":function($event){return _vm.onConfirmList(_vm.alertList.key)},"close":function($event){return _vm.onCloseList(_vm.alertList.key)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }