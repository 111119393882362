<template>
  <div class="text-center missing-popup-container">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="lighten-2 justify-space-between align-center">
          <span class="modal--heading">Missing Details</span>
          <v-btn icon @click="closeAction()">
            <v-icon class="close--icon">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <hr />
        <div class="missing-block">
          <div class="mb-4" v-for="(item, i) in missingFieldArr" :key="i">
            <div v-if="item.length > 0">
              <span class="sub--heading">{{ stepperArr[i].name }}</span>
              <ul>
                <li v-for="(ele, index) in item" :key="index">{{ ele }}</li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-space-around padding-print">
          <!-- <div
            class="print-container d-flex justify-center align-center cursor--pointer"
            @click="getPrint"
          >
            <img src="../assets/images/Print.svg" alt="" />
            <span class="color-white ml--2">Print</span>
          </div> -->
          <!-- <div
            class="print-container d-flex justify-center align-center cursor--pointer btn--color--blue"
            @click="closeAction()"
          >
            <span class="color-white">Close</span>
            <v-btn depressed> Close </v-btn>
          </div> -->
          <v-btn
            depressed
            class="btn--color--blue btn-css"
            @click="closeAction()"
          >
            Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    stepperArr: {
      type: Array,
      required: true,
    },
    missingFieldArr: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    getPrint() {
      window.open("/print-file", "_blank");
      this.dialog = false;
    },
    closeAction() {
      this.dialog = false;
    },
  },
  watch: {
    dialog(data) {
      if (data === false) {
        this.$emit("closeAction");
      }
    },
  },
};
</script>

<style scoped>
.color-white {
  color: #ffffff;
}
.print-container {
  width: 98px;
  height: 43px;
}
.padding-print {
  padding: 16px;
}
.missing-block {
  max-height: 350px !important;
  min-height: 150px !important;
  overflow-y: auto !important;
  padding: 0px 24px;
}
.sub--heading {
  font-weight: bold;
}
.modal--heading {
  font-weight: bolder;
}
.close--icon {
  color: black !important;
}
.btn-css {
  width: 8rem !important;
}
</style>
