<template>
  <v-card
    class="pap-title-container d-flex justify-space-between card--spacing"
  >
    <!-- <div>
      <v-tooltip :bottom="true"> -->
    <img
      v-if="missingFlag"
      class="missing-info cursor--pointer"
      src="../assets/images/missingInfo.svg"
      alt=""
      @click="$emit('clickMissingInfo')"
    />
    <!-- <span>Missing Details</span>
      </v-tooltip>
    </div> -->
    <div v-if="!hideLeftHeading" class="stepper-left-side d-flex flex-column">
      <!-- <img :src="$store.state.papFormTitle" alt="" class="stepper-left-title" /> -->
      <img src="../assets/images/papFormTitle.jpeg" alt="" class="stepper-left-title" />
      <h1 v-if="statusCode != null" class="stepper-enrollment-status">
        Enrollment Status:
        <span class="enrollment-status-code">{{ enrollmentStatus }}</span>
      </h1>
    </div>
    <div class="stepper-right-container">
      <div class="d-flex">
        <div
          class="stepper stepper2 w--full d-flex flex-column"
          v-for="(item, index) in stepperArr"
          :key="index"
        >
          <div class="d-flex flex-row w--full">
            <div class="d-flex align-center w--full">
              <div
                v-if="index"
                :class="[isLineActive(index - 1) ? 'active-line' : 'line']"
              ></div>
            </div>
            <div
              class="rounded-circle d-flex justify-center align-center"
              :class="{
                'last-circle': item.last,
                active: item.active,
              }"
            >
              <div class="font--weight--500">
                {{ item.stateId }}
              </div>
            </div>
            <div class="d-flex align-center w--full">
              <div
                :class="[isLineActive(index) ? 'active-line' : 'line']"
                v-if="!item.last"
              ></div>
            </div>
          </div>
          <div
            class="stepper-name"
            :class="{
              stepper2: !item.last,
              'active-color': item.active,
            }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- <div class="d-flex">
        <span
          class="mb-2 text-color w--full"
          v-for="(item, index) of stepperArr"
          :key="index"
          :class="{
            stepper2: !item.last,
            'active-color': item.active,
          }"
          >{{ item.name }}</span
        >
      </div> -->
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    stepperArr: {
      type: Array,
      required: true,
    },
    stepperActiveIndex: {
      type: Number,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
    statusCode: {
      type: Number,
      default: null,
    },
    missingFlag: {
      type: Boolean,
      default: false,
    },
    hideLeftHeading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      enrollmentStatus: "",
    };
  },

  computed: mapState(["configStatus"]),

  watch: {
    statusCode: {
      immediate: true,
      handler(data) {
        this.getEnrollmentStatus(data);
      },
    },
    configStatus: {
      deep: true,
      handler() {
        this.getEnrollmentStatus(this.statusCode);
      },
    },
  },

  methods: {
    isLineActive(index) {
      return this.stepperArr[index + 1].active;
    },

    getEnrollmentStatus(data) {
      let allStatus = this.$store.state.configStatus;
      let index = allStatus.findIndex((i) => i.statusCode == data);
      if (index > -1) this.enrollmentStatus = allStatus[index].statusType;
    },

    // Make stepper active on click
    // stepperActive(index) {
    //   if (this.isFormValid) {
    //     this.$emit("changeActiveIndex", index);
    //     for (let i = 0; i < this.stepperArr.length; i++) {
    //       this.stepperArr[i].active = i <= index ;
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.pap-title-container {
  align-items: center !important;
  flex-wrap: wrap;
  background: #f1f1f1;
}

.stepper {
  display: flex;
  align-items: center;
  position: relative;
  .rounded-circle {
    border-radius: 50%;
    width: 85px;
    height: 35px;
    background-color: #0c0c33;
    color: white;
    div {
      font-size: 1.3rem;
    }
  }
  .last-circle {
    width: 85px !important;
  }
  .active {
    background: #1a73e9;
  }
  .line {
    width: 100%;
    border: 1px solid #636363;
  }
  .active-line {
    width: 100%;
    border: 1px solid #1a73e9;
  }
}
.active-color {
  color: #1a73e9 !important;
}
.stepper-left-title {
  margin-right: 4rem;
  width: 12rem;
  margin-bottom: 4px;
}
.stepper-right-container {
  flex-grow: 9;
  font-size: 1rem;
}
.stepper-enrollment-status {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 1rem;
}
.stepper-left-side {
  flex-grow: 1;
  margin-bottom: 1rem;
  flex-direction: column;
}
.enrollment-status-code {
  color: #00cea2;
}
.missing-info {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}
.stepper-name {
  margin: 0.5rem auto;
  text-align: center;
  color: #0c0c33;
  font-weight: 600;
}
.stepper-upper-container {
  height: 0px;
}
</style>
