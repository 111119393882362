<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :page.sync="pageNo2"
    :items="tableItems"
    :no-data-text="noDataText"
    :items-per-page="itemsPerPage2"
    @update:page="changeInPage"
    @update:items-per-page="changeInItems"
    :hide-default-footer="hideDefaultFooter"
    :server-items-length="totalCount"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [10, 25, 50, 75, 100],
    }"
  >
    <template slot="item" slot-scope="props">
      <tr class="table-row">
        <slot name="columns" v-bind="props"></slot>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "DataTable",
  props: {
    headers: { type: Array, required: true },
    noDataText: { type: String, default: "No data available." },
    tableData: { type: Array, default: () => [] },
    itemsPerPage: { type: Number },
    pageNo: { type: Number, default: 1 },
    totalCount: { type: Number },
    hideDefaultFooter: { type: Boolean, default: false },
  },
  data() {
    return {
      tableItems: [],
      itemsPerPage2: this.itemsPerPage,
      pageNo2: this.pageNo,
    };
  },

  watch: {
    tableData(data) {
      this.tableItems = data;
    },
  },

  watch:{
    itemsPerPage(data){
      this.itemsPerPage2 = data;
    },
    pageNo(data){
      this.pageNo2 = data;
    }
  },

  methods: {
    changeInItems(event) {
      this.itemsPerPage2 = event;
      this.$emit("item-change", this.itemsPerPage2);
    },
    changeInPage(event) {
      if (event) {
        this.pageNo2 = event;
      }
      this.$emit("page-change", this.pageNo2);
    },
  },

  mounted() {
    this.tableItems = this.tableData;
  },
};
</script>
